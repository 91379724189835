import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import backerrow from "../../../images/backerrow.png";
import dashForm16 from "../../../images/dash_form16.png";
import tdsSalaryCal from "../../../images/tds_salary.png";

import Pansearchgif from "../../../images/pan_search.gif";

import StickyNotes from './StickyNotes';
import { BASE_PATH } from '../../../app/constants';
import TracesConnector from '../../../components/kdkConnector/TracesConnector';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import SinglePanVerify from '../../../components/verifyPAN/SinglePanVerify';
import ShowAlert from '../../../components/ShowAlert';


import { getTracesCaptchaFromConnector } from '../../../api/localConnectorAPI';
import { Button } from 'react-bootstrap';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import InsightsConnectorWithCaptcha from '../../../components/kdkConnector/InsightsConnectorWithCaptcha';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isDIY } from '../../../utils/PlanValidator';


const PanStatus = (props) => {

    const { deductorId, financialYear, stickyNotesReturnsData, stickyNotesStatsData, defaultData, successProcedSumm, indiviualQuarterSync, skeletonList } = props;

    const DIY = isDIY();
    const history = useHistory();
    const [verifySinglePan, setVerifySinglePan] = useState(false);
    const [verifyPan, setVerifyPan] = useState(false);
    const [data, setData] = useState({});
    const [captchaRef, setCaptchaRef] = useState("");
    const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
    const [port, setPort] = useState("");
    const [startConnector, setStartConnector] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tracesPanData, setTracesPanData] = useState({});
    const [errMsg, setErrMsg] = useState("")
    const [startInsightsProcess, setStartInsightProcess] = useState(false);

    const showTDSSalaryYear = 2024;

    const AADHAR_LINK_STATUS = {
        'LK': 'Linked',
        'NL': 'Not-Linked',
        'NA': 'Not-Applicable',
        'EX': 'Exempted',
    }

    const isForm16Applicable = (DIY && parseInt(financialYear) >= 2024)

    const validateData = (validData) => {

        const pattPAN = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        const errObj = {};

        if (validData?.pan && !pattPAN.test(validData?.pan)) errObj.pan = "PAN is invalid.";
        if (!validData?.pan) errObj.pan = "PAN is required";
        if (!validData?.captcha) errObj.captcha = "Captcha is required";

        if (Object.keys(errObj).length > 0) {
            data.errObj = errObj;
            setData({ ...data });
            return false;
        }
        return true;
    }

    const startVerifyPanProcess = () => {
        const status = validateData(data);
        if (status) {
            setCheckTracesCredentials(true);
        }
    }

    const getCaptcha = (port, isLoading, DATA) => {
        async function fetchEverything() {
            try {
                const result = await getTracesCaptchaFromConnector(port);
                let dataList = DATA ? DATA : data;
                dataList.captchaImg = result?.data?.captcha_image ?? "";
                setCaptchaRef(result?.data?.captcha_ref ?? "");
                dataList.captcha = "";
                setData({ ...dataList });
            } catch (err) {
                console.error('error: ', err);
                // if (err.message) setError(err.message);
                // else setError(err);
                setError("Please ensure that your Internet connection is stable and that the TRACES website is accessible.");

            } finally {
                if (isLoading) setLoading(false);
            }
        }
        if (isLoading) setLoading(true);
        fetchEverything();
    };

    const handleChange = (name, value) => {
        data[name] = value;
        if (data?.errObj?.[name]) data.errObj[name] = "";
        setData({ ...data });

    }

    const updatePanDataWithInsightsData = (resultData) => {
        if (resultData?.data.length > 0) {
            const AsOnDate = resultData?.lastUpdateDate
            const otherData = resultData?.data ?? [];
            otherData.forEach((item) => {
                tracesPanData.pan_allotment_date = item?.panAlotmentDateString;
                tracesPanData.pan_aadhar_link_status = AADHAR_LINK_STATUS?.[item?.panAadhaarLinkStatus] ?? item.panAadhaarLinkStatus;
                tracesPanData.pan_compliance_status = (item?.aplicableFlag === 'N') ? 'Regular Filer' : 'Non Filer';
            });
            tracesPanData.pan_as_on_date = AsOnDate
            setTracesPanData((prev) => {
                prev = tracesPanData
                return prev
            });
        }
        setStartInsightProcess(false);
    };

    const toggleFront = (showFront) => {
        const front = document.querySelector(".front-panstatus");
        const back = document.querySelector(".back-panstatus");
        if (showFront) {
            front.style.transform = "rotateY(180deg)";
            back.style.transform = "rotateY(0deg)";
        } else {
            front.style.transform = "rotateY(0deg)";
            back.style.transform = "rotateY(-180deg)";
        }
    }
    const panCompenentLength = data?.errObj?.captcha && data?.errObj?.pan ? "200px" : ((data?.errObj?.captcha || data?.errObj?.pan) || (!data?.errObj?.captcha && errMsg)) ? "200px" : "200px"

    return (
        <>
            {startConnector
                ? (<ConnectorAdapter
                    show={startConnector}
                    handleClose={() => setStartConnector(false)}
                    setPort={setPort}
                    handleProceed={(connectorport) => {
                        // setVerifySinglePan("SINGLE_PAN_VERIFICATION");
                        toggleFront(true)
                        getCaptcha(connectorport);
                    }}
                />)
                : null}

            {checkTracesCredentials
                ? <TracesConnector
                    deductorId={deductorId}
                    returnId={null}
                    setCaptchaRef={setCaptchaRef}
                    handleProceed={(res) => {
                        setCheckTracesCredentials(false);

                    }}
                    port={port}
                    setVerifyCreds={() => setVerifyPan(true)}
                    creds={null}
                    captchaRef={captchaRef}
                    captchaAlready={true}
                    captcha_text={data?.captcha}
                    getCaptchaPortal={() => {
                        getCaptcha(port)
                        setCheckTracesCredentials(false);
                    }}
                    setCaptchaError={setErrMsg}
                    setCaptchaLoading={setLoading}
                />
                : null
            }

            {verifyPan && captchaRef && port
                ? <SinglePanVerify
                    deductorId={deductorId}
                    port={port}
                    captchaRef={captchaRef}
                    setError={setError}
                    setVerifyPANResult={() => {

                    }}
                    pan={data.pan}
                    setLoading={setLoading}
                    setTracesPanData={(res) => {
                        setVerifySinglePan("VERIFY_PAN_STATUS");

                        if (res) {
                            if (res?.status === 'Invalid') {
                                setErrMsg('PAN Data not available on TRACES portal');
                            } else {
                                setTracesPanData((prev) => {
                                    if (!prev) prev = {};
                                    prev.name = res?.name ?? "";
                                    prev.status = res?.status ?? "";
                                    prev.pan = data?.pan ?? "";
                                    return { ...prev }
                                })
                            }
                        }
                        setVerifyPan(false);
                        setCaptchaRef(null);
                    }}
                    handleProceed={() => null}
                />
                : null
            }

            {
                startInsightsProcess && port
                    ?
                    <InsightsConnectorWithCaptcha
                        deductorId={deductorId}
                        returnId={null}
                        port={port}
                        pan={data.pan}
                        handleProceed={(res) => {
                            updatePanDataWithInsightsData(res);
                        }}
                        setParentLoading={setLoading}
                        handleClose={() => {
                            setStartInsightProcess(false);
                        }}
                        setStartInsightProcess={setStartInsightProcess}
                    />
                    : null
            }
            <ShowAlert
                success={success}
                error={error}
                onClose={() => { setSuccess(null); setError(null); }}
            />

            {loading && <LoadingIndicator />}


            <div className="col-xl-5 col-lg-12 col-md-12">

                {/* Pan status vo2 */}

                <div className="row">

                    <div className="col-md-12 mb-1" style={{ height: panCompenentLength }}>

                        <div className='switch-panstatus'>

                            <div className="row panInfobtncomp pt-0 ">
                                <div className="col-md-12 " >
                                    <div className="row">
                                        <div className={`${(DIY && financialYear >= showTDSSalaryYear) ? "col-md-4" : "col-md-6"} pr-2`}>
                                            <fieldset className={`gen_emailtdscreate_fs ${!DIY ? "tds_tcs_ceritfacte" : ""}`}>
                                                <legend className='gen_emailtdscreate_leg'>
                                                    <h4 className='gen_emailtdscreate_titlefs'>
                                                        TDS Certificate
                                                    </h4>
                                                </legend>
                                                <div className="dash_panstatus_comp_bordertdscert">
                                                    
                                                </div>
                                                <div
                                                    className={`${isForm16Applicable ? '' : 'd-flex'} justify-content-center cursor_pointer`}
                                                    onClick={() => {
                                                        history.push(`${BASE_PATH}issue-tds-tcs-certificates/${deductorId}?financial_year=${financialYear}`)
                                                    }}
                                                >
                                                    {isForm16Applicable && <div className='form_12ba_heading'>Form 16/16A & 12BA</div>}
                                                    <span className='panstatus_imgbox text-center'>
                                                        
                                                        <img src={dashForm16}
                                                            alt="pan status"
                                                            width="100%"
                                                            style={{ paddingTop: '24px', height: `${isForm16Applicable ? '138px' : '156px'}` }}
                                                        />
                                                    </span>
                                                </div>
                                            </fieldset>

                                        </div>

                                        {(DIY && financialYear >= showTDSSalaryYear) && 
                                        <div className="col-md-4 px-2" style={{ cursor: 'pointer' }}
                                         onClick={() => {
                                            history.push(`${BASE_PATH}imported-salary-details?deductor_id=${deductorId}&financial_year=${financialYear}`)
                                         }}>

                                            <fieldset className='gen_emailtdscreate_fs tds_tcs_ceritfacte'>
                                                <legend className='gen_emailtdscreate_leg'>
                                                    <h4 className='gen_emailtdscreate_titlefs'>
                                                    Salary TDS Calc...
                                                    </h4>
                                                </legend>
                                                <div class="dash_panstatus_comp_bordertdscert"></div>
                                                <div className='d-flex justify-content-center'>
                                                    <span className='panstatus_imgbox text-center'>
                                                        <img
                                                            src={tdsSalaryCal}
                                                            alt="tds salary"
                                                            width="100%"
                                                            style={{ paddingTop: '24px', height: "156px" }}
                                                        />
                                                    </span>
                                                </div>
                                            </fieldset>

                                        </div>}

                                        <div className={`${(DIY && financialYear >= showTDSSalaryYear) ? "col-md-4" : "col-md-6"} pl-2`}>                                            <fieldset
                                                className='dash_panstatus_comp_fs singlepanverfication '
                                                style={{ height: "200px" }}
                                            >
                                                <legend className="dash_panstatus_comp_leg">
                                                    <h4 className="pancomp_titlefixed_screens">
                                                        PAN Compliances
                                                    </h4>
                                                </legend>
                                                <div className="dash_panstatus_comp_border"></div>
                                                <div className='cardpan_statusflip front-panstatus'>
                                                    <div className="dash_panstatus_comp_rightboxleft" style={{display:'block', textAlign:'center'}}>
                                                        <p className="panverific_textfs mb-2">
                                                        PAN Verification and Compliance check u/s 206AB & 206CCA
                                                        </p>
                                                        <span className='pansearchgif_box'>
                                                            <img src={Pansearchgif} alt="pan status" width="70" />
                                                        </span>
                                                    </div>
                                                    <div className="dash_panstatus_comp_rightboxright d-flex justify-content-center pt-2">
                                                        <button className="btn btn-default dash_panstatus_lefsibgl_btnbgclr mb-2 mr-3 planexpiredalert"
                                                            onClick={() => {
                                                                setStartConnector(true);
                                                            }}
                                                        >
                                                            Single
                                                        </button>

                                                        <Link className='planexpiredalert' to={`${BASE_PATH}deductor/bulk-pan-verification?deductor_id=${deductorId}&financial_year=${financialYear}`}>
                                                            <button className="btn btn-default dash_panstatus_lefsibgl_btnbgclrbluk mb-2">
                                                                Bulk
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <>
                                                    <div className={` back-panstatus ${verifySinglePan === "VERIFY_PAN_STATUS" ? "w-100" : ""}`}>
                                                        <div className='row'>
                                                            <div className="col-md-12">



                                                                {verifySinglePan !== "VERIFY_PAN_STATUS" &&
                                                                    <>
                                                                        <p className='panst_backerrowbox mb-0 '>
                                                                            <img src={backerrow} alt="pan slider" className='planexpiredalert' onClick={() => {
                                                                                if (verifySinglePan === "VERIFY_PAN_STATUS") {
                                                                                    setVerifySinglePan(null)
                                                                                } else {
                                                                                    setVerifySinglePan(null); toggleFront(false)
                                                                                }
                                                                            }} width="18" />
                                                                        </p>
                                                                        <div className="row pt-2">



                                                                            <div className="col-md-12">
                                                                                <div className="row dash_panstatus_comp_rightboxright">
                                                                                    <div className="form-group dash_single_rightsidebox_grp text-left col-sm-12">
                                                                                        <input type="text" className="form-control" maxLength={10} placeholder="Enter PAN" onChange={(e) => handleChange("pan", e.target.value?.toLocaleUpperCase())} value={data?.pan ?? ""} />
                                                                                        <span className="error_message">{data?.errObj?.pan ? data?.errObj?.pan : ""}</span>
                                                                                    </div>
                                                                                    <div className="form-group dash_single_rightsidebox_grp text-left col-sm-4 pr-0">
                                                                                        <input type="text" className="form-control panst_catchinputbox" 
                                                                                            placeholder="Captcha"
                                                                                            onChange={(e) => {
                                                                                                handleChange("captcha", e.target.value)
                                                                                                setErrMsg("");
                                                                                            }}
                                                                                            value={data?.captcha ?? ""} />



                                                                                    </div>
                                                                                    <div className="form-group dash_single_rightsidebox_grp col-sm-8 pl-1">

                                                                                        <div className={`${DIY ? "dash_captchincrimage" : "dash_Captchaimgbox"} text-left`}>
                                                                                            <img src={`data:image/png;base64, ${data.captchaImg}`} alt="captcha" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12" style={{ color: "red", fontSize: "9px", paddingLeft: "10px", paddingRight: "5px" }}>
                                                                                        {!data?.errObj?.captcha && errMsg ? errMsg : ""}
                                                                                    </div>
                                                                                    <div className="error_message col-md-12 pl-3">
                                                                                        {data?.errObj?.captcha ? data?.errObj?.captcha : ""}
                                                                                    </div>

                                                                                    <div className="col-md-12">
                                                                                        <p style={{ fontSize: "10px", marginBottom: '5px' }}>
                                                                                            <small>
                                                                                                <Button variant="link" className='planexpiredalert captchamsgbtn' onClick={() => getCaptcha(port, true)}>Click here</Button>
                                                                                                to refresh captcha
                                                                                            </small>
                                                                                        </p>

                                                                                    </div>

                                                                                    <div className='col-md-12 text-center'>
                                                                                        <button className="btn btn-default dash_panstatus_lefsibgl_btnbgclrbluk panstatus_verify planexpiredalert"
                                                                                            onClick={() => startVerifyPanProcess()}
                                                                                        >
                                                                                            Verify
                                                                                        </button>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>}
                                                                {verifySinglePan === "VERIFY_PAN_STATUS" &&
                                                                    <>
                                                                        <p className='panst_backerrowbox mb-0 '>
                                                                            <img src={backerrow} alt="pan slider" className='planexpiredalert' onClick={() => {
                                                                                if (verifySinglePan === "VERIFY_PAN_STATUS") {
                                                                                    setVerifySinglePan(null)
                                                                                    setData({})
                                                                                    getCaptcha(port, true, {})
                                                                                    setTracesPanData({});
                                                                                }
                                                                            }} width="18" />
                                                                        </p>
                                                                        <div class="row" style={{ minHeight: "146px" }}>

                                                                            <div class="col-md-12 text-center">
                                                                                <div class="dash_panstatus_comp_rightboxright">
                                                                                    <div class="panstatus_verifybox_dashborder">
                                                                                        <p class="panstatus_verifybox_txetfs panverify_textbalck mb-1">
                                                                                            {tracesPanData?.pan ?? ""}
                                                                                        </p>
                                                                                        {tracesPanData?.name && <p class="panstatus_verifybox_txetfs panverify_textgreen mb-1">
                                                                                            {tracesPanData?.name ?? ""}
                                                                                        </p>}
                                                                                        {tracesPanData?.status && <p
                                                                                            class={`panstatus_verifybox_txetfs ${tracesPanData?.status === "Valid and InOperative" ? "panverify_textred" : ""} mb-1`}
                                                                                        >
                                                                                            {tracesPanData?.status ?? ""}
                                                                                        </p>}

                                                                                        {errMsg && <p class="panstatus_verifybox_txetfs panverify_textred mb-1">
                                                                                            {errMsg ?? ""}
                                                                                        </p>}
                                                                                        {tracesPanData?.pan_compliance_status && <p
                                                                                            class={`panstatus_verifybox_txetfs ${tracesPanData?.pan_compliance_status === "Non Filer" ? "panverify_textred" : ""} mb-1`}
                                                                                        >
                                                                                            {tracesPanData?.pan_compliance_status ?? ""}
                                                                                        </p>}
                                                                                        {tracesPanData?.pan_compliance_status && <p class="panstatus_verifybox_txetfs mb-1">
                                                                                            As on Date: {tracesPanData?.pan_as_on_date ?? ''}
                                                                                        </p>}
                                                                                        {!tracesPanData.pan_compliance_status && <p class="text-right panstatus_verifybox_checkinsightport_textfs mb-1 planexpiredalert" style={{ cursor: "pointer" }} onClick={() => setStartInsightProcess(true)}>
                                                                                            Check with Insight Portal
                                                                                        </p>}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </>



                                            </fieldset>




                                        </div>



                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div >

                {/*end Pan status vo2 */}

                < div className='row mt-2' >
                    <StickyNotes
                        deductorId={deductorId}
                        returnsData={stickyNotesReturnsData}
                        statsData={stickyNotesStatsData}
                        financialYear={financialYear}
                        defaultData={defaultData}
                        successProcedSumm={successProcedSumm}
                        indiviualQuarterSync={indiviualQuarterSync}
                        skeletonList={skeletonList}
                    />
                </div >

            </div >
        </>
    )
}

export default PanStatus
