import axios from 'axios';
import { BASE_URL, HEADER_FILE_NAME } from '../app/constants';
import { handleSuccess, handleError, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';

export async function getEmployeeSalaryList(data) {
    const url = `${BASE_URL}/api/v1/salary/list`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleError(err);
    }
}

export async function getEmployeeSalarySummary(data) {
    const url = `${BASE_URL}/api/v1/salary/summary`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleError(err);
    }
}

export async function getCorrectionEmployeeSalaryList(data) {
    const url = `${BASE_URL}/api/v1/correction/salary/list`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleError(err);
    }
}

export async function getCorrectionEmployeeSalarySummary(data) {
    const url = `${BASE_URL}/api/v1/correction/salary/summary`;
    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleError(err);
    }
}

export async function getEmployeeSalaryDetail(salary_id, deductor_id, returnId) {
    const url = `${BASE_URL}/api/v1/salary?deductor_id=${deductor_id}&salary_id=${salary_id}&return_id=${returnId}`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        handleSuccess(result.data);
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}

export async function getCorrectionEmployeeSalaryDetail(salary_id, deductor_id, returnId) {
    const url = `${BASE_URL}/api/v1/correction/salary/details?deductor_id=${deductor_id}&salary_id=${salary_id}&return_id=${returnId}`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}

export async function addEmployeeSalaryDetail(data) {
    const url = `${BASE_URL}/api/v1/salary`;

    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw result.data.errors;
        }
        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function addCorrectionEmployeeSalaryDetail(data) {
    const url = `${BASE_URL}/api/v1/correction/salary`;

    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw result.data.errors;
        }
        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function updateEmployeeSalaryDetail(data) {
    const url = `${BASE_URL}/api/v1/salary`;

    try {
        const result = await axios.put(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw result.data.errors;
        }

        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function updateCorrectionEmployeeSalaryDetail(data) {
    const url = `${BASE_URL}/api/v1/correction/salary`;

    try {
        const result = await axios.put(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw result.data.errors;
        }

        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function downloadExcelSalaryTemplate(params, headerDataDetails) {
    try {
        const config = { ...getConfig(), responseType: 'blob' };
        const result = await axios.get(`${BASE_URL}/api/v1/return/regular/download-template${params}`, config);
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ExpressTDS_' + headerDataDetails.form_type + '_Template.xlsx');
        document.body.appendChild(link);
        link.click();
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function deleteEmployeeSalary(deductorId, salaryId) {
    const url = `${BASE_URL}/api/v1/salary?deductor_id=${deductorId}&salary_id=${salaryId}`;
    try {
        const result = await axios.delete(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }

        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function deleteCorrectionEmployeeSalary(deductorId, salaryId, returnId) {
    const url = `${BASE_URL}/api/v1/correction/salary/delete?deductor_id=${deductorId}&salary_id=${salaryId}&return_id=${returnId}`;
    try {
        const result = await axios.delete(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }

        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function getAllowances(financialYear) {
    const url = `${BASE_URL}/api/v1/salary/allowance-types?financial_year=${financialYear}`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}


export async function getOtherIncomeParticulars() {
    const url = `${BASE_URL}/api/v1/salary/other-income-particulars`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}

export async function getDeductionVIAParticulars(financialYear, deductorCategory) {
    const url = `${BASE_URL}/api/v1/salary/deduction-vi-a-particulars?financial_year=${financialYear}&deductor_category=${deductorCategory}`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}

export async function getDeducteeTotalTDSAmount(deductor_id, deductee_id, financial_year) {
    const url = `${BASE_URL}/api/v1/salary/deductee-total-tds?deductor_id=${deductor_id}&deductee_id=${deductee_id}&financial_year=${financial_year}`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}

export async function updateEmployeeSalaryDetailV2(data) {
    const url = `${BASE_URL}/api/v2/salary`;

    try {
        const result = await axios.put(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw result.data.errors;
        }
        handleSuccess(result.data);
        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function getEmployeeSalaryDetailV2(salary_id, deductor_id, return_id) {
    const url = `${BASE_URL}/api/v2/salary?deductor_id=${deductor_id}&return_id=${return_id}&salary_id=${salary_id}`;
    try {
        const result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        handleSuccess(result.data);
        return result.data;
    } catch (err) {
        throw handleError(err);
    }
}

export async function addEmployeeSalaryDetailV2(data) {
    const url = `${BASE_URL}/api/v2/salary`;

    try {
        const result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw result.data.errors;
        }
        return result.data;
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function downloadPDFSalary(params) {
    try {
        const config = { ...getConfig(), responseType: 'blob' };
        const result = await axios.get(`${BASE_URL}/api/v2/salary/download-computation${params}`, config);
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'SalaryPDFList.pdf');
        document.body.appendChild(link);
        link.click();
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}