import moment from "moment";

const newPattPAN = new RegExp(/^([A-Z]){3}([ABCFGHJLPT])([A-Z])([0-9]){4}([A-Z])$/);

export const SALARY_17_1 = {
    Details: {
        BASIC: { particular_code: "BASIC", amount_amtp: 0 },
    },
    Lite: {
        BASIC: { particular_code: "BASIC", amount_amtp: 0 },
    }
}

export const SALARY_17_2 = {
    Details: {
        ANY_OTHER_PERQU: { particular_code: "ANY_OTHER_PERQU", amount_amtp: 0 },

    },
    Lite: {
        ANY_OTHER_PERQU: { particular_code: "ANY_OTHER_PERQU", amount_amtp: 0 },
    }
}

export const SALARY_17_3 = {
    Details: {
        ANY_OTHER: { particular_code: "ANY_OTHER", amount_amtp: 0 },
    },
    Lite: {
        ANY_OTHER: { particular_code: "ANY_OTHER", amount_amtp: 0 },
    }
}

export const OTHERINCOME_OFFERED = {
    Details: {
        INTEREST_SAVINGS_ACCOUNT: { particular_code: "INTEREST_SAVINGS_ACCOUNT", amount_amtp: 0 },
    },
    Lite: {
        INTEREST_SAVINGS_ACCOUNT: { particular_code: "INTEREST_SAVINGS_ACCOUNT", amount_amtp: 0 },
    }
}
export const defaultMonthList = () => {
    const monthCodes = ["4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3"];
    const defaultValues = {
        whether_metro_city: false,
        hra_received_amtp: 0,
        rent_paid_amtp: 0,
        salary_amtp: 0,
        exempt_amtp: 0,
    };

    const monthList = {};

    monthCodes.forEach((code) => {
        monthList[code] = { ...defaultValues };
    });
    return monthList;
};

export const mthListCodeWise = { "4": "April", "5": "May", "6": "June", "7": "July", "8": "August", "9": "September", "10": "October", "11": "November", "12": "December", "1": "January", "2": "February", "3": "March" };


export const SALARY_17_1DROPDOWN = [
    {
        "particular_code": "BASIC",
        "description": "Basic"
    },
    {
        "particular_code": "DA",
        "description": "Dearness Allowance"
    },
    {
        "particular_code": "BONUS",
        "description": "Bonus"
    },
    {
        "particular_code": "ADV_SALARY",
        "description": "Advance Salary"
    },
    {
        "particular_code": "COMMISSION",
        "description": "Commission"
    },
    {
        "particular_code": "VARIABLE",
        "description": "Variable"
    },

];

export const perquisites_17_2DROPDOWN = [
    {
        "particular_code": "ANY_OTHER_PERQU",
        "description": "Any other Perquisite"
    },
    {
        "particular_code": "RENT_FREE",
        "description": "Rent Free accomodation"
    },
    {
        "particular_code": "CAR",
        "description": "Motor Car"
    },
    {
        "particular_code": "LTC_EXP",
        "description": "LTC/Holiday Expenses"
    },
    {
        "particular_code": "FREE_CONC_SUPPLY",
        "description": "Free Concessional Supply of Gas/Electricity/Water"
    },
    {
        "particular_code": "SERVANT",
        "description": "Domestic Servant"
    },
    {
        "particular_code": "FREE_EDU",
        "description": "Free Education facility"
    },
    {
        "particular_code": "TRNS_MOV_ASSEST",
        "description": "Transfer of Movable Assests to employee"
    },
    {
        "particular_code": "MEALS",
        "description": "Free meals"
    },
    {
        "particular_code": "CREDIT_CRD_EXP",
        "description": "Credit Card Expenses"
    },
    {
        "particular_code": "INT_FREE_CON_LOANS",
        "description": "Interest Free or concessional  Loans"
    },
    {
        "particular_code": "USE_MOV_ASSEST",
        "description": "Use of Movable Assets by employee"
    },
    {
        "particular_code": "GFT_VOUCHER",
        "description": "Gifts, Vouhers Etc"
    },
    {
        "particular_code": "CLUB",
        "description": "Club Facility"
    },
    {
        "particular_code": "MEDICAL",
        "description": "Medical Facility"
    },
    {
        "particular_code": "OBL_EMP_MET_EMPLOYER",
        "description": "Obligation of employee met by employer"
    },
    {
        "particular_code": "CON_SUP_ANNU_FUND",
        "description": "Employer's contribution to Superannuation Fund"
    },
    {
        "particular_code": "STOCK_OPT_ALLOTTED",
        "description": "Stock options allotted or transferred by employer being an eligible start-up referred to in section 80-IAC"
    }, {
        "particular_code": "STOCK_OPT_OTHER_THAN_ESOP",
        "description": "Stock options (non-qualified options) other than ESOP"
    },
];

export const profit_17_3DROPDOWN = [
    {
        "particular_code": "ANY_OTHER",
        "description": "Any other Amount received"
    },
    {
        "particular_code": "VRS",
        "description": "Compensation to VRS"
    },
    {
        "particular_code": "RETR",
        "description": "Retrenchment Compensation"
    },
    {
        "particular_code": "PF",
        "description": "PF Interest /PF Payment"
    }
];




export const RETIRMENTBENFIT_DROPDOWN = [
    {
        "allowance_type_id": 6,
        "description": "Leave Encashment [10(10AA)]",
        "calc_id": "leaveSalaryEncashPopup"
    },
    {
        "allowance_type_id": 4,
        "description": "Gratuity[ 10(10)]",
        "calc_id": "gratuityPopup"
    },
    {
        "allowance_type_id": 5,
        "description": "Commuted Pension [ 10(10A)]",
        "calc_id": "computedPensionPopup"
    },
];

export const ALLOWANCEEXEMPT_DROPDOWN = {
    "Details": [
        {
            "allowance_type_id": 1,
            "description": "Travel concession or assistance [ 10(5)]",
            "calc_id": "travelConcessionPopup"
        },
        {
            "allowance_type_id": 7,
            "description": "Other Special 10(14)",
        },
        {
            "allowance_type_id": 3,
            "description": "Any other Exemptions Under 10"
        },
        {
            "allowance_type_id": 2,
            "description": "House Rent Allowance 10(13A)",
            "calc_id": "houseRentAllowPopup"
        },
        {
            "allowance_type_id": 8,
            "description": "Children Education Allowance",
        },
        {
            "allowance_type_id": 9,
            "description": "Children Hostel Allowance",
        },
        {
            "allowance_type_id": 10,
            "description": "Conveyance Allowance"
        },
        {
            "allowance_type_id": 11,
            "description": "Helper Allowance",
        },
        {
            "allowance_type_id": 12,
            "description": "Uniform Allowance",
        },
        {
            "allowance_type_id": 13,
            "description": "Compensatory Field Allowance",
        },
        {
            "allowance_type_id": 14,
            "description": "Transfer Allowance"
        },
        {
            "allowance_type_id": 15,
            "description": "Transport Allowance",
        },
    ],
    "Lite": [
        {
            "allowance_type_id": 1,
            "description": "Travel concession or assistance [ 10(5)]",
            "calc_id": "travelConcessionPopup"
        },
        {
            "allowance_type_id": 2,
            "description": "House Rent Allowance 10(13A)",
            "calc_id": "houseRentAllowPopup"
        },
        {
            "allowance_type_id": 3,
            "description": "Any other Exemptions Under 10"
        },
        {
            "allowance_type_id": 7,
            "description": "Other Special 10(14)",
        },
    ]
}


export const OTHERINCOMEOFFERED_DROPDOWN = [
    {
        "particular_code": "DIVIDEND_INCOME",
        "description": "Dividend Income"
    },
    {
        "particular_code": "INTEREST_SAVINGS_ACCOUNT",
        "description": "Interest on Savings Account"
    },
    {
        "particular_code": "INTEREST_FDR",
        "description": "Interest on F.D.R"
    },
    {
        "particular_code": "OTHER_INCOME",
        "description": "Other Income"
    }
];

// Parameters:
// arrayObj: An array of objects that you want to transform into a single object.
// particular_code: A string that represents the key in each object that will be used as the key in the resulting object.
export const converArrayObj_to_map = (arrayObj, particular_code) => {
    const result = new Map();

    for (let i = 0; i < arrayObj.length; i++) {
        const item = arrayObj[i];
        result.set(item[particular_code], item);
    }

    return result;
};

/**
 * Filters out items from the dropdown list that are not present in the salary breakup array based on a specified key.
 *
 * @param {Array} salary_breakup - The array of objects representing salary breakup details.
 * @param {Array} dropdownlist - The array of objects representing the dropdown options.
 * @param {string} breakupKeys - The key used to identify and match items between the salary_breakup and dropdownlist.
 *
 * @returns {Array} - The filtered dropdown list containing only items that are not present in the salary_breakup.
 */
export const transformDropdown = (salary_breakup, dropdownlist, breakupKeys) => {
    // Convert the salary_breakup array into a Map using the converArrayObj_to_map function
    const Obj = converArrayObj_to_map(salary_breakup, breakupKeys);

    // Filter out dropdownlist items that do not have a corresponding entry in the Obj Map
    const nonUsableDropdownList = dropdownlist.filter((item) => !Obj.has(item?.[breakupKeys]));

    return nonUsableDropdownList;
};


export const defaultdata = {
    "deductor_id": "",
    "deductee_id": "",
    "financial_year": null,
    "employment_period_from": "",
    "employment_period_to": "",
    "tax_regime": "NEW",
    "salary_amtp": 0,
    "perquisites_amtp": 0,
    "profit_in_lieu_amtp": 0,
    "salary_breakup_amtp": {
        "details": [{ "amount_amtp": "", "particular_code": "BASIC" }]
    },
    "perquisite_breakup_amtp": [
        { "particular_code": "ANY_OTHER_PERQU", "amount_amtp": "" },
    ],
    "profit_in_lieu_breakup_amtp": [
        { "particular_code": "ANY_OTHER", "amount_amtp": "" }
    ],
    "allowances": [],
    "standard_deduction_amtp": 0,
    "ent_allowance_amtp": 0,
    "professional_tax_amtp": 0,
    "previous_employer": false,
    "previous_employer_amount_received_amtp": 0,
    "previous_employer_tds_amtp": 0,
    "let_out_amtp": 0,
    "let_out_breakup": null,
    "self_occupied_amtp": 0,
    "self_occupied_breakup": null,
    "other_income_offered_details": [
        {
            "particular_code": "",
            "amount_amtp": 0
        }
    ],
    "deduction_vi_a_details": [
        {
            "code": "",
            "gross_amount_amtp": 0,
            "deductible_amount_amtp": 0
        }
    ],
    "net_taxable_income": {
        "total_net_taxable_income_amtp": "0.00",
        "relief_u_s_89_amtp": 0,
        "tds_ded_by_current_emp_amtp": 0,
        "tds_tcs_192_2b_amtp": 0,
        "total_tax_computed_amtp": "0.00",
        "excess_short_tds_amtp": "0.00",
        "tax_computed": {
            "income_tax_amtp": "0.00",
            "rebate_us_87_a_amtp": "0.00",
            "surcharge_amtp": "0.00",
            "health_edu_cess_amtp": "0.00",
            "relief_us_89_amtp": "0.00",
            "tax_payable_amtp": "0.00"
        }
    },
    "other_details": {
        "whether_total_rent_greater_onelakh": 0,
        "landlord_details": [
            {
                "name_of_landlord": "",
                "pan_of_landlord": "",
                "rent_amount_amtp": 0
            }
        ],
        "whether_interest_paid_to_lender": 0,
        "lender_details": [
            {
                "name_of_lender": "",
                "pan_of_lender": "",
                "interest_amount_amtp": 0
            }
        ],
        "whether_amtrec_from_superannuation_fund": 0,
        "fund_name": "",
        "fund_from": "",
        "fund_to": "",
        "fund_received_amtp": 0,
        "tax_rate_b100": 0,
        "tds_deducted_amtp": 0
    }
}


export const intialData = {
    "deductor_id": "",
    "deductee_id": "",
    "financial_year": "",
    "employment_period_from": "12/2/2024",
    "employment_period_to": "15/5/2024",
    "tax_regime": "NEW",
    "salary_amtp": 12500,
    "perquisites_amtp": 5000,
    "profit_in_lieu_amtp": 60000,
    "salary_breakup_amtp": {
        details: [
            { particular_code: "BASIC", amount_amtp: 2000 },
            { particular_code: "DA", amount_amtp: 2600 },
            { particular_code: "BONUS", amount_amtp: 2500 },
            { particular_code: "ADV_SALARY", amount_amtp: 3000 },
            { particular_code: "COMMISSION", amount_amtp: 1500 },
            { particular_code: "VARIABLE", amount_amtp: 1000 },
        ]
    },
    "allowances": [
        { allowance_type_id: 1, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 7, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 3, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 2, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 8, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 9, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 10, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 11, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 12, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 13, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 14, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 15, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 6, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 4, received_amtp: 2000, exempt_amtp: 2000 },
        { allowance_type_id: 5, received_amtp: 2000, exempt_amtp: 2000 },
    ],
    perquisite_breakup_amtp: [
        { particular_code: "ANY_OTHER_PERQU", amount_amtp: 2000 },
        { particular_code: "RENT_FREE", amount_amtp: 2600 },
        { particular_code: "CAR", amount_amtp: 2500 },
        { particular_code: "LTC_EXP", amount_amtp: 3000 },
        { particular_code: "FREE_CONC_SUPPLY", amount_amtp: 1500 },
        { particular_code: "SERVANT", amount_amtp: 1000 },
        { particular_code: "FREE_EDU", amount_amtp: 2000 },
        { particular_code: "TRNS_MOV_ASSEST", amount_amtp: 2600 },
        { particular_code: "MEALS", amount_amtp: 2500 },
        { particular_code: "CREDIT_CRD_EXP", amount_amtp: 3000 },
        { particular_code: "INT_FREE_CON_LOANS", amount_amtp: 1500 },
        { particular_code: "USE_MOV_ASSEST", amount_amtp: 1000 },
    ],
    profit_in_lieu_breakup_amtp: [
        { particular_code: "ANY_OTHER", amount_amtp: 2000 },
        { particular_code: "VRS", amount_amtp: 2600 },
        { particular_code: "RETR", amount_amtp: 2500 },
        { particular_code: "PF", amount_amtp: 3000 },
    ],
    "standard_deduction_amtp": 1500,
    "ent_allowance_amtp": 1200,
    "professional_tax_amtp": 1300,
    "previous_employer": true,
    "previous_employer_amount_received_amtp": 12000,
    "previous_employer_tds_amtp": 15000,
    "let_out_amtp": 14000,
    "let_out_breakup": {
        "gross_amtp": 500000,
        "loan_interest_amtp": 4000,
        "net_annual_amtp": 495000,
        "property_tax_amtp": 5000,
        "standard_deduction_amtp": 148500
    },
    "self_occupied_amtp": 50000,
    "self_occupied_breakup": {
        "construction_amtp": 78578,
        "repair_renewal_extension_amtp": 7578
    },
    "other_income_offered_details": [
        { particular_code: "DIVIDEND_INCOME", amount_amtp: 2000 },
        { particular_code: "INTEREST_SAVINGS_ACCOUNT", amount_amtp: 2600 },
        { particular_code: "INTEREST_FDR", amount_amtp: 2500 },
        { particular_code: "OTHER_INCOME", amount_amtp: 3000 },
    ],
    "deduction_vi_a_details": [
        {
            "code": "80CCD_2",
            "gross_amount_amtp": "55.00",
            "deductible_amount_amtp": 55
        }
    ],
    "net_taxable_income": {
        "total_net_taxable_income_amtp": "0.00",
        "relief_u_s_89_amtp": 0,
        "tds_ded_by_current_emp_amtp": 0,
        "tds_tcs_192_2b_amtp": 0,
        "total_tax_computed_amtp": "0.00",
        "excess_short_tds_amtp": "0.00",
        "tax_computed": {
            "income_tax_amtp": "0.00",
            "rebate_us_87_a_amtp": "0.00",
            "surcharge_amtp": "0.00",
            "health_edu_cess_amtp": "0.00",
            "relief_us_89_amtp": "0.00",
            "tax_payable_amtp": "0.00"
        }
    },
    total_gross_income_amtp: 12000,
    "other_details": {
        "whether_total_rent_greater_onelakh": 1,
        "landlord_details": [
            {
                "name_of_landlord": "twetw",
                "pan_of_landlord": "NONRESDENT",
                "rent_amount_amtp": "3450.00"
            },
            {
                "name_of_landlord": "3535",
                "pan_of_landlord": "NONRESDENT",
                "rent_amount_amtp": "34530.00"
            }
        ],
        "whether_interest_paid_to_lender": 1,
        "lender_details":
            [
                {
                    "name_of_lender": "3453",
                    "pan_of_lender": "OTHERVALUE",
                    "interest_amount_amtp": "3530.00"
                },
                {
                    "name_of_lender": "35345",
                    "pan_of_lender": "NONRESDENT",
                    "interest_amount_amtp": "3530.00"
                }
            ],
        "whether_amtrec_from_superannuation_fund": 1,
        "fund_name": "16/08/2024",
        "fund_from": "16/08/2024",
        "fund_to": "12/09/2024",
        "fund_received_amtp": 12000,
        "tax_rate_b100": 15000,
        "tds_deducted_amtp": 14000
    }
}

const defaultSalary17onebreakup = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0 }

// **Explanation:**
// 1. **Add Initial Keys to the Top**: When integrating data received from the API, 
// the function should first ensure that the initial keys are added at the top of the list.
// 2. **Update Values from the API**: If the API provides values for these initial keys, 
// update the values with the data received. This means any existing values from the API should replace the corresponding initial values.
// 3. **Add Initial Keys with Zero Amounts if No Data**: If the API does not provide values for these initial keys, 
// they should still be added to the list, but with a default value of zero (0) for the amount.
// In summary, the function ensures that initial keys are always present at the top of the list, updates them if new data is available from the API,
// and assigns a default value of zero if no data is provided for those keys.
const SALARY_transform = (salarydata, fixedKeysObj, code) => {
    if (!salarydata) salarydata = {};

    const userSpecificData = fixedKeysObj;
    if (salarydata?.length > 0) {
        const detailsMap = new Map(Object.entries(userSpecificData)); // Initialize with user type data
        salarydata.forEach((item) => {
            detailsMap.set(item?.[code], item);
        })
        salarydata = Array.from(detailsMap.values()); // Convert the Map back to an array
    } else {
        salarydata = Object.values(userSpecificData);
    }
    return salarydata;
};

// Same Explanation 
const SALARY_17_1transform = (salarydata, usertype) => {
    if (!salarydata) {
        salarydata = defaultSalary17onebreakup;
    }

    const userSpecificData = SALARY_17_1[usertype];
    if (salarydata?.details?.length > 0) {
        const detailsMap = new Map(Object.entries(userSpecificData)); // Initialize with user type data
        salarydata.details.forEach((item) => {
            detailsMap.set(item.particular_code, item); // Update the Map with the new details
        });

        salarydata.details = Array.from(detailsMap.values()); // Convert the Map back to an array

    } else {
        salarydata.details = Object.values(userSpecificData);
    }

    return salarydata;
};

const DEDUCTION_VI_A = (ded_data = []) => {
    const defaultArray = [{ "code": "", "deductible_amount_amtp": "", "gross_amount_amtp": "", "qualifying_amtp": null }];
    if (ded_data?.length === 0) return defaultArray;
    else return ded_data;
}



export const transformApiData = (data, usertype) => {

    data.salary_breakup_amtp = SALARY_17_1transform(data?.salary_breakup_amtp, usertype);
    data.perquisite_breakup_amtp = SALARY_transform(data?.perquisite_breakup_amtp, SALARY_17_2[usertype] ?? {}, "particular_code");
    data.profit_in_lieu_breakup_amtp = SALARY_transform(data?.profit_in_lieu_breakup_amtp, SALARY_17_3[usertype] ?? {}, "particular_code");
    data.other_income_offered_details = SALARY_transform(data?.other_income_offered_details, OTHERINCOME_OFFERED[usertype] ?? {}, "particular_code");
    data.deduction_vi_a_details = DEDUCTION_VI_A(data.deduction_vi_a_details ?? [])
    if (data.other_details && !data?.other_details?.landlord_details) data.other_details.landlord_details = [{}];
    if (data.other_details && !data?.other_details?.lender_details) data.other_details.lender_details = [{}];
    return data;
}

// Explanation of the updateDropdownTypeData function:

// 1. **Synchronize Data with API Response**: This function updates the current data with the new data received from the API,
// ensuring that the latest information is reflected in the application.

// 2. **Preserve Incomplete User-Added Items**: If the user has added an item from the dropdown to the data, but it couldn't be saved
// (likely due to incomplete or missing information), the function ensures that this incomplete item remains visible in the UI.
// This allows the user to see and continue working on the incomplete data without losing it.

// 3. **Prevent Premature Removal of Incomplete Data**: By keeping the incomplete data in the UI, the function prevents it from being
// accidentally removed before it's fully completed. 

const updateDropdownTypeData = (apiData, uiData, updateKey, code) => {
    if (uiData?.[updateKey]?.length > 0 && apiData?.[updateKey]?.length > 0) {
        apiData[updateKey].forEach((item) => {
            const newIndex = uiData[updateKey].findIndex((dataItem) => dataItem[code] === item[code]);
            if (newIndex !== -1) {
                uiData[updateKey][newIndex] = item;
            }
        })
    }
    return uiData[updateKey];
}

export const updateUIData = (apiData, uiData) => {
    apiData.allowances = updateDropdownTypeData(apiData, uiData, "allowances", "allowance_type_id");
    apiData.other_income_offered_details = updateDropdownTypeData(apiData, uiData, "other_income_offered_details", "particular_code");
    apiData.deduction_vi_a_details = updateDropdownTypeData(apiData, uiData, "deduction_vi_a_details", "code");
    if (apiData.other_details && !apiData?.other_details?.landlord_details) apiData.other_details.landlord_details = [{}];
    if (apiData.other_details && !apiData?.other_details?.lender_details) apiData.other_details.lender_details = [{}];
    return apiData;
}

export const VALIIDATION_SCHEMA = {
    travelConcessionValidationSchema: (data) => {
        const returnObj = { validation: true, errObj: {} }
        if (!data.air_route_amtp && !data.rail_connect_route_amtp && !data.other_route_amtp) {
            if (data.air_route_amtp === 0) returnObj.errObj.air_route_amtp = 'Air Route should be greater than 0';
            else returnObj.errObj.air_route_amtp = 'Air Route is required';
        }

        if (!data.rail_connect_route_amtp && data.rail_connect_route_amtp !== 0) {
            returnObj.errObj.rail_connect_route_amtp = 'Rail Connect Route is required';
        }

        if (!data.other_route_amtp && data.other_route_amtp !== 0) {
            returnObj.errObj.other_route_amtp = 'Other Route is required';
        }

        if (!data.actual_exp_paid_amtp) {
            if (data.actual_exp_paid_amtp === 0) returnObj.errObj.actual_exp_paid_amtp = 'Actual Exp. Paid should be greater than 0';
            else returnObj.errObj.actual_exp_paid_amtp = 'Actual Exp. Paid is required';
        }

        if (!data.family_person && !data.whether_cent_gov_emp && data.whether_cash_allowance) {
            if (data.family_person === 0) returnObj.errObj.family_person = 'No of family person should be greater Than to 0'
            else returnObj.errObj.family_person = 'No of family person is required';
        }

        if (!data.exempt_amtp && data.exempt_amtp !== 0) {
            returnObj.errObj.exempt_amtp = 'Exempt Amt is required';
        }

        if (Object.keys(returnObj.errObj).length > 0) returnObj.validation = false;
        return returnObj;

    },
    commutedPensionValidationSchema: (data) => {
        const retObj = { validation: true, errObj: {} };

        if (!data.commuted_pension_amtp) {
            if (data.commuted_pension_amtp === 0) retObj.errObj.commuted_pension_amtp = "Number should be greater Than to 0";
            else retObj.errObj.commuted_pension_amtp = "Commuted Pension is required";
        }

        if (!data?.commuted_pension_percent_b100) {
            if (data.commuted_pension_amtp === 0) retObj.errObj.commuted_pension_amtp = "Number should be greater Than to 0";
            else retObj.errObj.commuted_pension_amtp = "Commuted Pension % is required";
        } else if (data.commuted_pension_percent_b100 && data.commuted_pension_percent_b100 > 100) {
            retObj.errObj.commuted_pension_amtp = "Number should be less Than or Equal to 100";
        }

        if (!data.exempt_amtp) {
            if (data.exempt_amtp === 0) retObj.errObj.exempt_amtp = "Number should be greater Than to 0";
            else retObj.errObj.exempt_amtp = "Exempt Amt is required";
        }

        if (Object.keys(retObj.errObj).length > 0) retObj.validation = false;
        return retObj;
    },

    gratuityValidationSchema: (data) => {
        const retObj = { validation: true, errObj: {} };

        if (!data.gratuity_received_amtp) {
            if (data.gratuity_received_amtp) retObj.errObj.gratuity_received_amtp = "Number should be greater Than to 0";
            else retObj.errObj.gratuity_received_amtp = "Gratuity received is required"
        }

        if (!data.service_period_year && !data.whether_gov_emp && !data.whether_received_after_death) {
            if (data.service_period_year === 0) retObj.errObj.service_period_year = "Number should be greater Than to 0";
            else retObj.errObj.service_period_year = "Service period is required";
        }

        if (!data.service_period_month && data.service_period_month !== 0) retObj.errObj.service_period_month = "Service period is required";

        if (!data.last_month_salary_with_DA_amtp && !data.whether_gov_emp && !data.whether_received_after_death && data.whether_gratuity_act_app) {
            if (data.last_month_salary_with_DA_amtp === 0) retObj.errObj.last_month_salary_with_DA_amtp = "Number should be greater Than to 0";
            else retObj.errObj.last_month_salary_with_DA_amtp = "Last month salary with DA is required";
        }

        if (!data.avg_salary_amtp && !data.whether_gov_emp && !data.whether_received_after_death && data.whether_gratuity_act_app) {
            if (data.avg_salary_amtp === 0) retObj.errObj.avg_salary_amtp = "Number should be greater Than to 0";
            else retObj.errObj.avg_salary_amtp = "Avg salary is required";
        }

        if (!data.exempt_amtp && data.exempt_amtp !== 0) retObj.errObj.exempt_amtp = "Exempt amount is required";
        if (Object.keys(retObj.errObj).length > 0) retObj.validation = false;

        return retObj;
    },

    houseRentMonthlyDataValidationSchema: (data, type) => {
        const retObj = { validation: true, errObj: {} };

        if (Object.keys(data)?.length > 0) {

            Object.keys(data).forEach((key) => {
                retObj.errObj[key] = {};

                if (!data?.[key]?.hra_received_amtp && data?.[key]?.hra_received_amtp !== 0) {
                    retObj.errObj[key].hra_received_amtp = "HRA received is required";
                    retObj.validation = false;
                }

                if (!data?.[key]?.rent_paid_amtp && data?.[key]?.rent_paid_amtp !== 0) {
                    retObj.errObj[key].rent_paid_amtp = "Rent paid is required";
                    retObj.validation = false;
                }

                if (!data?.[key]?.salary_amtp && data?.[key]?.salary_amtp !== 0) {
                    retObj.errObj[key].salary_amtp = "Salary is required";
                    retObj.validation = false;
                }

                if (!data?.[key]?.exempt_amtp && data?.[key]?.exempt_amtp !== 0) {
                    retObj.errObj[key].exempt_amtp = "Exempt is required";
                    retObj.validation = false;
                }

            });

            return retObj;

        } else {
            return { validation: false, errObj: {} };
        }
    },

    houseRentYearlyDataValidationSchema: (data) => {
        const retObj = { validation: true, errObj: {} };

        if (!data.hra_received_amtp) {
            if (data.hra_received_amtp === 0) retObj.errObj.hra_received_amtp = "Number should be greater Than to 0";
            else retObj.errObj.hra_received_amtp = "HRA received is required";
        }

        if (!data?.rent_paid_amtp) {
            if (data.rent_paid_amtp === 0) retObj.errObj.rent_paid_amtp = "Number should be greater Than to 0";
            else retObj.errObj.rent_paid_amtp = "Rent paid is required";
        }

        if (!data?.annual_salary_amtp) {
            if (data.annual_salary_amtp === 0) retObj.errObj.annual_salary_amtp = "Number should be greater Than to 0";
            else retObj.errObj.annual_salary_amtp = "Annual Salary is required";
        }

        if (!data?.exempt_amtp && data.exempt_amtp !== 0) {
            retObj.errObj.exempt_amtp = "Exempt is required";
        }

        if (Object.keys(retObj.errObj).length > 0) retObj.validation = false;
        return retObj;
    },

    leaveSalaryEncashmentValidationSchema: (data) => {
        const retObj = { validation: true, errObj: {} };

        if (!data.leaves_encas_rec_amtp) {
            if (data.leaves_encas_rec_amtp === 0) retObj.errObj.leaves_encas_rec_amtp = "Number should be greater Than to 0";
            else retObj.errObj.leaves_encas_rec_amtp = "Leave Encashment Rec is required";
        }

        if (!data?.avg_10_month_salary_amtp) {
            if (data.avg_10_month_salary_amtp === 0) retObj.errObj.avg_10_month_salary_amtp = "Number should be greater Than to 0";
            else retObj.errObj.avg_10_month_salary_amtp = "salary is required";
        }

        if (!data?.service_period_year) {
            if (data.service_period_year === 0) retObj.errObj.service_period_year = "Number should be greater Than to 0";
            else retObj.errObj.service_period_year = "Year is required";
        }

        if (!data?.exempt_amtp && data.exempt_amtp !== 0) {
            retObj.errObj.exempt_amtp = "Exempt amount is required";
        }

        if (Object.keys(retObj.errObj).length > 0) retObj.validation = false;
        return retObj;
    }

}


export const validateOtherDetailSchema = (errosState = {}, data, financialYear) => {
    const landlordWarningMessage = "For rent paid exceeding Rs. 1 Lakh, atleast one valid landlord detail is required.";
    const lenderWarningMessage = "For interest paid to lender, atleast one valid lender detail is required.";
    const supperannuatiowarningMessage = "Name of Superannuation Fund: A valid fund name is required. The name cannot be more than 75 characters long.\nSupperannuation Fund From Date: The Supperannuation Fund From Date is not valid date.\nSuperannuation Fund To Date: The Superannuation Fund To Date is not valid date.\nSuperannuation Fund Amount Received: The amount should be greater than zero."

    if ((data?.other_details?.whether_total_rent_greater_onelakh
        || data?.other_details?.whether_interest_paid_to_lender
        || data?.other_details?.whether_amtrec_from_superannuation_fund) && data.other_details) {
        
            let errorObj = {validation: true};

        for (const key in data.other_details) {
            if (key === "landlord_details" && data?.other_details?.whether_total_rent_greater_onelakh) {
                if (data?.other_details?.landlord_details?.length > 0) {
                    const errArray = [];
                    let validation = true;
                    data.other_details.landlord_details.forEach((element, index) => {
                        let errObj = {};
                        if (!element.name_of_landlord) {
                            errObj.name_of_landlord = "Name of Landlord is required";
                            validation = false;
                        }
                        if (!element.pan_of_landlord) {
                            errObj.pan_of_landlord = "Pan of Landlord is required";
                            validation = false;
                        } else if (
                            element.pan_of_landlord
                            && !['GOVERNMENT', 'NONRESDENT', 'OTHERVALUE'].includes(element.pan_of_landlord)
                            && (!newPattPAN.test(element.pan_of_landlord) || element.pan_of_landlord.length !== 10)) {
                            errObj.pan_of_landlord = "Invalid PAN format."
                            validation = false;
                        }
                        errArray.push(errObj);
                    });

                    errosState.landlord_details = errArray;
                    if(!validation){
                        errorObj = { validation: validation, updatedErrors: errosState, warningMess: !validation ? landlordWarningMessage : "" }
                        break;
                    }
                } else if (data?.other_details?.landlord_details?.length === 0) {

                    errosState.landlord_details = [];
                    errorObj = { validation: false, updatedErrors: errosState, warningMess: landlordWarningMessage };
                    break;

                }
            } else if (key === "lender_details" && data?.other_details?.whether_interest_paid_to_lender) {
                if (data?.other_details?.lender_details?.length > 0) {
                    const errArray = [];
                    let validation = true;
                    data.other_details.lender_details.forEach((element, index) => {
                        let errObj = {};
                        if (!element.name_of_lender) {
                            errObj.name_of_lender = "Name of Lender is required";
                            validation = false;
                        }
                        if (!element.pan_of_lender) {
                            errObj.pan_of_lender = "Pan of Lender is required";
                            validation = false;
                        } else if (
                            element.pan_of_lender
                            && !['GOVERNMENT', 'NONRESDENT', 'OTHERVALUE'].includes(element.pan_of_lender)
                            && (!newPattPAN.test(element.pan_of_lender) || element.pan_of_lender.length !== 10)) {
                            errObj.pan_of_lender = "Invalid PAN format."
                            validation = false;
                        }
                        errArray.push(errObj);
                    });

                    errosState.lender_details = errArray;
                    if(!validation){
                        errorObj = { validation: validation, updatedErrors: errosState, warningMess: !validation ? lenderWarningMessage : "" }
                        break;
                    }

                } else if (data?.other_details?.lender_details?.length === 0) {

                    errosState.lender_details = [];
                    errorObj = { validation: false, updatedErrors: errosState, warningMess: lenderWarningMessage };
                    break;

                }
            } else if (data?.other_details?.whether_amtrec_from_superannuation_fund) {
                const err_obj = {};
                let validation = true;
                const minDate = moment(`01/04/${financialYear}`, "DD/MM/YYYY").unix();
                const maxDate = moment(`31/03/${financialYear + 1}`, "DD/MM/YYYY").unix();
                const from_date = data?.other_details?.fund_from ? moment(data?.other_details?.fund_from, "DD/MM/YYYY").unix() : null;
                const to_date = data?.other_details?.fund_to ? moment(data?.other_details?.fund_to, "DD/MM/YYYY").unix() : null;

                if (!data?.other_details?.fund_name) {
                    err_obj.fund_name = "Name of Fund is required";
                    validation = false;
                }
                if (!data?.other_details?.fund_received_amtp) {
                    err_obj.fund_received_amtp = "Amt Received is required";
                    validation = false;
                }

                if (!from_date) {
                    err_obj.fund_from = "Invalid from date";
                    validation = false;
                } else if (from_date && from_date < minDate) {
                    err_obj.fund_from = `From date must be greater than or equal to 01/04/${financialYear}`;
                    validation = false;
                } else if (from_date && from_date > maxDate) {
                    err_obj.fund_from = `From date must be less than or equal to 31/03/${financialYear + 1}`;
                    validation = false;
                } else if (to_date && from_date && from_date > to_date) {
                    err_obj.fund_from = `From date must be less than or equal to ${data?.other_details?.fund_to ?? ""}`;
                    validation = false;
                }

                if (!to_date) {
                    err_obj.fund_to = "Invalid to date";
                    validation = false;
                } else if (to_date && to_date > maxDate) {
                    err_obj.fund_to = `To date must be less than or equal to 31/03/${financialYear + 1}`
                    validation = false;
                } else if (to_date && to_date < minDate) {
                    err_obj.fund_to = `To date must be greater than or equal to 01/04/${financialYear}`;
                    validation = false;
                } else if (to_date && from_date && to_date < from_date) {
                    err_obj.fund_to = `To date must be greater than or equal to ${data?.other_details?.fund_from ?? ""}`
                    validation = false;
                }
                errosState.from_superannuation_fund = err_obj;

                errorObj = { validation: validation, updatedErrors: errosState, warningMess: !validation ? supperannuatiowarningMessage : "" }
                break;
            }
        }
        return errorObj;

    } else {
        return { validation: true };
    }
}

// Function to round numbers to two decimal places
const roundUPTwoDecimal = (value) => {
    return parseFloat(value).toFixed(2); // Ensures two decimal places
}

// The recursive function to convert certain values to two decimal points
export const convertTwoDecimalFormatted = (data) => {
    if (Array.isArray(data)) {
        data.forEach(convertTwoDecimalFormatted); // Recursively process arrays
    } else if (typeof data === 'object' && data !== null) {
        Object.keys(data).forEach((key) => {
            const value = data[key];
            if (key.endsWith('amtp') && typeof value === 'number') {
                data[key] = roundUPTwoDecimal(value); // Round numbers with key ending in 'amtp'
            } else if (typeof value === 'object' && value !== null) {
                convertTwoDecimalFormatted(value); // Recursively process objects
            }
        });
    }
    return JSON.stringify(data, null, 2);
};
