import React, { useEffect, useRef, useState } from 'react'
import { CONNECTOR_ERRORS_MAPPING } from '../../app/constants';
import { getPdfGenStatusFromConnector } from '../../api/issueCertificateAPI';
import DraggableContent from '../../components/grid/DraggableContent';

const ProgressBarGenPdfPopup = (props) => {
    const { localStorgePdfs, setVerifyLogin, setError, setReSign, setIsConnectorRunning, callRequestStatus,
        port, requestStatusList, requestStatusData, clientTan, localId, deductorId, setCurrentStep,
        setRunningPdfStatus, bottomMargin, parentIndex, callEmailStatusApi } = props;

    const timer = useRef(null);
    const timerStatus = useRef(null);
    const [workDone, setWorkDone] = useState({})
    const [pdfProgress, setPdfProgress] = useState(false)
    const [finalRequestStatus, setFinalRequestStatus] = useState({})
    const [connectorWorkComplete, setConnectorWorkComplete] = useState(0)

    const FORM16PART = {
        FORM16_PARTA: "Part-A",
        FORM16_PARTB: "Part-B"
    }

    useEffect(() => {
        if (JSON.stringify(requestStatusList) !== JSON.stringify(finalRequestStatus)) {
            setFinalRequestStatus(() => {
                return { ...requestStatusList }
            })
        }
    }, [requestStatusList, finalRequestStatus])

    const currentRequestComplete = (localStorgePdfs) => {
        const requestList = localStorgePdfs.filter((item) => {
            if (item.fy === requestStatusData?.financial_year
                && item.ft === requestStatusData?.form_type
                && item.qt === requestStatusData?.quarter
                && item.tan === clientTan) {
                return true;
            } else {
                return false;
            }
        })
        return (requestList?.length === 0);
    }

    useEffect(() => {
        if (connectorWorkComplete > 0) {
            const checkNewStatus = async () => {
                await callRequestStatus(deductorId);
                if (localStorage.getItem('tds-status') !== localStorage.getItem('tds-status-change')) {                                        
                    clearTimeout(timerStatus.current)

                    setReSign(false);
                    setPdfProgress(false);
                    setCurrentStep(null);
                    setRunningPdfStatus(false);
                    
                    if(callEmailStatusApi){
                        callEmailStatusApi();
                    }
                    
                    setConnectorWorkComplete(0);

                    return;
                }
                timerStatus.current = setTimeout(() => checkNewStatus(), 5000);
            }
            checkNewStatus();
        } else if (localStorgePdfs && localStorgePdfs?.length > 0 && finalRequestStatus && finalRequestStatus?.request_no?.length > 0 && finalRequestStatus?.request_no?.[parentIndex]) {            
            const tid = finalRequestStatus?.request_no?.[parentIndex];
            const index = localStorgePdfs?.findIndex(item => tid === item?.trackId && item.fy === requestStatusData?.financial_year && item.ft === requestStatusData?.form_type && item.qt === requestStatusData?.quarter && item.tan === clientTan);            
            if (index !== -1 && localStorgePdfs[index]?.processId && ['AVAILABLE', 'AVAILABLE_UNSIGNED_PDF', 'AVAILABLE_SIGNED_PDF', 'AVAILABLE_ALL_PDF'].includes(finalRequestStatus?.request_status)) {
                const checkPdfProgress = async (port) => {
                    const currentlocalStorgePdfs = JSON.parse(localStorage.getItem(localId));
                    const indexNew = currentlocalStorgePdfs?.findIndex(item => tid === item?.trackId && item.fy === requestStatusData?.financial_year && item.ft === requestStatusData?.form_type && item.qt === requestStatusData?.quarter && item.tan === clientTan);
                    if(indexNew === -1) return null;

                    if (!currentlocalStorgePdfs[indexNew]?.processId) return null
                    let ids = JSON.parse(localStorage.getItem('complete-process-ids'))
                    const completeProcess = ids?.includes(currentlocalStorgePdfs[indexNew]?.processId);
                    if (completeProcess) return null;
                    
                    try {
                        const statusResult = await getPdfGenStatusFromConnector(port, `?process_id=${currentlocalStorgePdfs[indexNew]?.processId}`);
                        if (parseInt(statusResult.status) === 1) {
                            if ((statusResult?.data?.process_status ?? null) === 'IN_PROGRESS') {
                                setWorkDone(statusResult?.data ?? {})
                                timer.current = setTimeout(() => checkPdfProgress(port), 5000);
                                return;
                            } else if ((statusResult?.data?.process_status ?? null) === 'COMPLETE') {
                                let processId = statusResult?.data?.process_id;
                                let completeIds = JSON.parse(localStorage.getItem('complete-process-ids'))
                                completeIds.push(processId);
                                localStorage.setItem('complete-process-ids', JSON.stringify(completeIds))
                                setWorkDone(statusResult?.data ?? {})
                               
                                currentlocalStorgePdfs.splice(indexNew, 1);
                                localStorage.setItem(localId, JSON.stringify(currentlocalStorgePdfs));

                                setPdfProgress(true);                                 
                                   
                                if (currentRequestComplete(currentlocalStorgePdfs)) {
                                    setConnectorWorkComplete(1);
                                }

                            } else if ((statusResult?.data?.process_status ?? null) === 'FAILURE') {
                                setError(CONNECTOR_ERRORS_MAPPING['default']);
                                
                                currentlocalStorgePdfs.splice(indexNew, 1)
                                localStorage.setItem(localId, JSON.stringify(currentlocalStorgePdfs));
                                 
                                if (currentRequestComplete(currentlocalStorgePdfs)) {
                                    setConnectorWorkComplete(2);
                                }
                            }
                        }
                    } catch (err) {
                        currentlocalStorgePdfs.splice(indexNew, 1)
                        localStorage.setItem(localId, JSON.stringify(currentlocalStorgePdfs));

                        if (currentRequestComplete(currentlocalStorgePdfs)) {
                            setConnectorWorkComplete(2);
                        }                    
                    } finally {

                    }
                }
                if (port) {
                    localStorgePdfs[index]?.processId && checkPdfProgress(port);
                    setVerifyLogin(true)

                } else {
                    setIsConnectorRunning(true);
                }
                // Clear timeout on unmount
                return () => { clearTimeout(timer.current); clearTimeout(timerStatus.current) };

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorgePdfs, connectorWorkComplete, requestStatusData, clientTan, finalRequestStatus, port, localId, setVerifyLogin, setIsConnectorRunning, callRequestStatus, deductorId]);

    return (

        <>
           {parentIndex === 0 && <div className="k-overlay"></div>}

            <DraggableContent id={parentIndex === 1 ? "DraggableContainersecond" : ""} style={{ position: 'fixed', zIndex: 100, bottom: `${bottomMargin ? bottomMargin : "10%"}`, left: '20%', maxHeight: 'fit-content' }}>

                <div className={`card tds_form16_certif_progressmodal`}>
                    <div className="card ">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="" style={{ fontSize: "12px" }}>
                                    Generating PDFs might take couple of mins, meanwhile your system should be
                                    up and running. Please ensure you do not shut down your system in the mean
                                    time.
                                </p>
                            </div>
                            {pdfProgress ?
                                <>
                                    <div class="col-md-12">
                                        <span class="progress form16_certificate_progressbar ">
                                            <span class="progress-bar progress-bar-striped progress-bar-animated"
                                                style={{ width: `100%` }}>
                                                {`100% Completed ${FORM16PART?.[finalRequestStatus?.request_for?.[parentIndex]] ?? ""}`}
                                            </span>
                                        </span>
                                    </div>

                                    {workDone?.total_pdf_cnt &&<div class="col-md-6 pt-2">
                                        <p class="form16_generatest_textno">
                                            {`${workDone?.proc_pdf_cnt ?? 0}/${workDone?.total_pdf_cnt ?? 0} certificates generated`}
                                        </p>
                                    </div>}
                                    <div class="col-md-6 text_right pt-2">
                                        <p class="form16_generatest_textno text_right">
                                            {`Estimated Time remaining: ${workDone?.rem_tm_mins ? workDone?.rem_tm_mins : "Calculating..."} `}
                                        </p>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="col-md-12">
                                        <span class="progress form16_certificate_progressbar ">
                                            <span class="progress-bar progress-bar-striped progress-bar-animated"
                                                style={{ width: `${workDone?.pb_val ?? 0}%` }}>
                                                {`${workDone.pb_val ?? 0}% Completed ${FORM16PART?.[finalRequestStatus?.request_for?.[parentIndex]] ?? ""}`}
                                            </span>
                                        </span>
                                    </div>

                                    <div class="col-md-6 pt-2">
                                    {workDone?.total_pdf_cnt && <p class="form16_generatest_textno ">
                                            {`${workDone?.proc_pdf_cnt ?? 0}/${workDone?.total_pdf_cnt ?? 0} certificates generated`}
                                        </p>}
                                    </div>
                                    <div class="col-md-6 text-right pt-2">
                                        <p class="form16_generatest_textno text_right">
                                            {`Estimated Time remaining: ${workDone?.rem_tm_mins ? workDone?.rem_tm_mins : "Calculating..."} `}
                                        </p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </DraggableContent>

        </>

    )
}

export default ProgressBarGenPdfPopup