/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL, HEADER_FILE_NAME } from '../app/constants';
import { handleSuccess, handleErrorWithCode, handleAllSuccess } from './responseHandler';
import { getConfig } from './common';
import { updateAuthToken } from '../utils/AuthToken';

export async function updateUserPassword(data) {
  const url = `${BASE_URL}/api/v2/user/password`;

  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateUserProfile(data) {
  const url = `${BASE_URL}/api/v1/user`;

  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getUserProfile(searchStr = '') {
  const url = `${BASE_URL}/api/v1/user${searchStr}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function inviteUser(data) {
  const url = `${BASE_URL}/api/v1/user/organization/invite`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function addCallMe(data) {
  const url = `${BASE_URL}/api/v1/support/click-to-call`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getTenantCurrentPlan(searchStr = '') {

  const url = `${BASE_URL}/api/v1/sd/tenant/current-plan${searchStr}`;
  try {
    let result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSharedPlandataOnServer(searchStr = '') {

  const url = `${BASE_URL}/api/v1/sd/tenant/billing-plan-detail?spi=${searchStr}`;
  try {
    let result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getTenantPlanHistory(searchStr = '') {

  const url = `${BASE_URL}/api/v1/sd/tenant/plan-history${searchStr}`;
  try {
    let result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function updateUserVerifiedMobileEmail(data) {
  const url = `${BASE_URL}/api/v1/user/update-mobile-email`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    // Update JWT token in local storage, if found in the API response
    if (result.data?.data?.token) {
      updateAuthToken(result.data.data.token)
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function sendOtpToVerifyUserMobileEmail(data) {
  const url = `${BASE_URL}/api/v1/user/send-otp`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getUsersOfOrganization() {
  const url = `${BASE_URL}/api/v1/user/organization/all-users`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getOrganizationProfile() {
  const url = `${BASE_URL}/api/v1/user/organization`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
export async function updateOrganizationProfile(data) {
  const url = `${BASE_URL}/api/v1/user/organization`;

  try {
    const result = await axios.put(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleAllSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function submitTentativePayment(data) {

  const url = `${BASE_URL}/api/v1/sd/tentative-payment`;
  try {
    let result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getAllActivePlans(change_plan) {

  const url = `${BASE_URL}/api/v1/sd/plan/all-active?change_plan=${parseInt(change_plan)}`;
  try {
    let result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function submitPlanEnquiry(data) {

  const url = `${BASE_URL}/api/v1/sd/enquiry`;
  try {
    let result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadInvoiceFile(data) {
  const urlPath = `${BASE_URL}/api/v1/sd/download-invoice`;
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.post(urlPath, data, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result.headers[HEADER_FILE_NAME] ?? 'Invoice.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getTanInfo(deductor_id) {
  const url = `${BASE_URL}/api/v1/report/deductor/tan-insights?deductor_id=${deductor_id}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}