import moment from 'moment';
import {
  BASE_PATH, CONNECTOR_LOCAL_PORTS,  
  LATE_DEDUCTION_INTEREST_RATE, initialHeaderData,
  PASSWORD_ENCRYPTION_KEY,
  PASSWORD_ENCRYPTION_IV
} from '../app/constants';
import { getConnectionStatusFromConnector } from '../api/localConnectorAPI';
import { LOGIN_PATHS } from '../App';
import { matchPath } from 'react-router-dom';
import { getAuthToken, updateAuthToken } from './AuthToken';
import history from '../app/history';
import store from '../app/store';
import { setHeader } from '../features/header/headerSlice';
import { isDIY } from './PlanValidator';

/**
 * Function to get financial years from given minimum FY to current FY
 * Second param can be used to specify whether the list is needed in descending or ascending order
 */
export function getFinancialYears(minFY = 2020, descending = true) {
  const FYs = [];
  const maxFY = parseInt(getCurrentFinancialYear());

  if (descending) {
    let fy = maxFY;
    while (fy >= minFY) {
      FYs.push({ label: `${fy}-${String(fy + 1).substr(2, 2)}`, value: fy });
      fy -= 1;
    }
  } else {
    let fy = parseInt(minFY);
    while (fy <= maxFY) {
      FYs.push({ label: `${fy}-${String(fy + 1).substr(2, 2)}`, value: fy });
      fy += 1;
    }
  }

  return FYs;
}



export function getFirstAndLastDateForYearAndMonth(formattedYear, month) {
  let year = Number(formattedYear.slice(0, 4));
  if (month < 4) {
    year = year + 1
  }
  let lastDay = new Date(year, month, 1);
  let firstDay = new Date(year, month - 1, 1);
  return { firstDay: firstDay, lastDay: lastDay }
}



export function getFinancialYearsList(year = null) {
  const startYear = 2020;
  const years = [];
  let endYear = parseInt(getCurrentFinancialYear());
  while (endYear >= startYear) {
    let next_year = endYear + 1;
    years.push({ text: `${endYear}-${next_year.toString().substr(2, 2)}`, value: `${endYear}` });
    endYear = endYear - 1;
  }
  return years;
}

/**
 * Function to get assessment years from given minimum AY to current AY
 * Second param can be used to specify whether the list is needed in descending or ascending order
 */
export function getAssessmentYears(minAY = 2020, descending = true) {
  const AYs = [];
  const maxAY = parseInt(getCurrentAssessmentYear());

  if (descending) {
    let ay = maxAY;
    while (ay >= minAY) {
      AYs.push({ label: `${ay}-${String(ay + 1).substr(2, 2)}`, value: ay });
      ay -= 1;
    }
  } else {
    let ay = parseInt(minAY);
    while (ay <= maxAY) {
      AYs.push({ label: `${ay}-${String(ay + 1).substr(2, 2)}`, value: ay });
      ay += 1;
    }
  }

  return AYs;
}

export function getCurrentFinancialYearByQuarter() {
  const currentYear = parseInt(moment().format('YYYY'), 10);
  const currentMonth = parseInt(moment().format('M'));
  if (currentMonth >= 1 && currentMonth <= 6) return currentYear - 1;

  return currentYear;
}

export function getCurrentFinancialYear() {
  const currentYear = moment().format('YYYY');
  const currentMonth = moment().format('M');
  if (currentMonth > 3) return parseInt(currentYear, 10);

  return parseInt(currentYear, 10) - 1;
}

export function getCurrentAssessmentYear() {
  return parseInt(getCurrentFinancialYear(), 10) + 1;
}

export function getCurrentQuarter() {
  const currentMonth = moment().format('M');
  if (currentMonth > 3 && currentMonth <= 6) return 'Q1';
  if (currentMonth > 6 && currentMonth <= 9) return 'Q2';
  if (currentMonth > 9 && currentMonth <= 12) return 'Q3';
  return 'Q4';
}

export function getFormattedCurrentFinancialYear() {
  const currFY = parseInt(getCurrentFinancialYear(), 10);
  const nextFY = currFY + 1;

  return { value: `${currFY}`, label: `${currFY}-${String(nextFY).substr(2, 2)}` };
}



export function checkForAutoLogin() {
  if (window.location.hash) {
    let token = window.location.hash.substring(1);
    updateAuthToken(token);
    let url = window.location.pathname + window.location.search;
    window.history.pushState({}, "", url);
  }
}

export function isTokenValid() {
  checkForAutoLogin()

  // Get auth token - dont fail if invalid
  if (!getAuthToken(false)) {
    saveRedirectUrl();
    store.dispatch(setHeader(initialHeaderData))
    return false;
  }

  return true;
}

export function redirectToLoginPage() {
  history.push(`${BASE_PATH}login`)
}

export function saveRedirectUrl() {

  if (!LOGIN_PATHS.includes(window.location.pathname)) {
    let data = { path: window.location.pathname + window.location.search, time: moment().add(1, 'hours').unix() };
    localStorage.setItem('redirect-url', JSON.stringify(data));
  }
}

export function getRedirectUrl() {
  let redirect_url = localStorage.getItem('redirect-url');
  if (!redirect_url) return false;

  redirect_url = JSON.parse(redirect_url);

  if (redirect_url.time > moment.unix()) return false;
  return redirect_url.path;

}

export function removeRedirectUrl() {
  localStorage.removeItem('redirect-url');
}

export function formatNumber(num) {
  let n = (Math.round(num * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString('en-IN');
  // eslint-disable-next-line eqeqeq
  if (n == parseInt(n)) ls = ls + ".00";

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) { ls = ls + "0"; }
  return ls;
}

export function manageSalaryData(type, tan, year, data = {}) {
  if (type === 'get') {
    data = localStorage.getItem(`salay-data-${tan}-${year}`);
    if (!data) return false;
    return JSON.parse(data);
  }
  else if (type === 'remove') {
    localStorage.removeItem(`salay-data-${tan}-${year}`);
  }
  else {
    localStorage.setItem(`salay-data-${tan}-${year}`, JSON.stringify(data));
  }

}


export function capitalizeFirstLetter(s) {
  return s?.charAt(0)?.toUpperCase() + s?.slice(1)?.toLowerCase()
}

export function capitalizeEachWordFirstLetter(str) {
  return str.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}


export function setLocalPort() {
  return;
  // if (sessionStorage.getItem('connector_port')) {
  //   return;
  // }

  // let foundPort = false;

  // LOCAL_PORTS_OLD.forEach(async (port) => {
  //   try {
  //     const result = await getConnectionStatusFromConnector(port);
  //     if (!foundPort && result.status === 'ON') {
  //       sessionStorage.setItem('connector_port', port);
  //       foundPort = true;
  //       return;
  //     }

  //   } catch (error) {
  //     console.error("error in connecting local port: ", error);
  //   }
  // });
}

export function getClientOS() {
  if (navigator.userAgent.indexOf("Win") !== -1) return "WINDOWS";
  if (navigator.userAgent.indexOf("Mac") !== -1) return "MACOS";
  if (navigator.userAgent.indexOf("Linux") !== -1) return "LINUX";
  if (navigator.userAgent.indexOf("Android") !== -1) return "ANDROID";
  if (navigator.userAgent.indexOf("like Mac") !== -1) return "IOS";

  if (navigator.appVersion.indexOf("Win") !== -1) return "WINDOWS";
  if (navigator.appVersion.indexOf("Mac") !== -1) return "MACOS";
  if (navigator.appVersion.indexOf("X11") !== -1) return "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) return "LINUX";

  return "UNKNOWN";
}

// Private method - Use getRunningConnectorUsability from external files
async function getRunningConnectorDetails() {

  for (let i = 0; i < CONNECTOR_LOCAL_PORTS.length; i++) {

    try {
      const result = await getConnectionStatusFromConnector(CONNECTOR_LOCAL_PORTS[i]);
      if (parseInt(result.status ?? 0) === 1) {
        return {
          port: CONNECTOR_LOCAL_PORTS[i],
          version: parseInt((JSON.parse(atob(result.data))).version ?? 0)
        };
      }
    } catch (error) {
    }

  }

  return { port: 0, version: 0 };
}

export async function getRunningConnectorUsability(latestConnectorVersion) {
 
  // Get Running Connector Details
  const runningConnectorDetails = await getRunningConnectorDetails();
 
  // No connector Running. It is mandatory to download Connector installer
  if (runningConnectorDetails.port === 0) {
    return {
      port: 0,
      install: 1,
      update: 0,
    };
  }

  // If Running version is less than the Current Version, then we need to Download Installer
  if (runningConnectorDetails.version < latestConnectorVersion) {
    return {
      port: runningConnectorDetails.port,
      install: 1,
      update: 0,
    };
  }

  // Stable Version concept is not supported now
  /*
  // Running version >= Stable version
  // However, if it is less than the Latest version, then we can proceed. However, user can update also (if needed)
  if (runningConnectorDetails.version < cv) {
    return {
      port: runningConnectorDetails.port,
      install: 0,
      update: 1,
    };
  }
  */

  // Else, all good.
  return {
    port: runningConnectorDetails.port,
    install: 0,
    update: 0
  };

}

export function formatAccountsNumber(num, out_ifnan = false, negative_as_red = true) {
  if (isNaN(num)) {
    if (out_ifnan !== false) return out_ifnan; else return num;
  }

  if (!num) num = 0;
  let n = (Math.round(num * 100) / 100).toFixed(2);
  let ls = Number(n).toLocaleString('en-IN');
  // eslint-disable-next-line eqeqeq
  if (n == parseInt(n)) ls = ls + ".00";

  let check_decimal = ls.split(".");
  if (check_decimal[1] && check_decimal[1].length === 1) { ls = ls + "0"; }
  if (n < 0) {
    ls = ls.substring(1);

    if (negative_as_red) {
      return <span className="redtextclr">({ls})</span>;
    } else {
      return <span>({ls})</span>;
    }
  }
  return ls;
}


const GSTIN_REGEX = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z1-9A-J]{1}[0-9A-Z]{1}/,
  UIN_REGEX = /[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][0-9A-Z]{1}/,
  NRID_REGEX = /[0-9]{4}[A-Z]{3}[0-9]{5}[N][R][0-9A-Z]{1}/,
  OIDAR_REGEX = /[9][9][0-9]{2}[A-Z]{3}[0-9]{5}[O][S][0-9A-Z]{1}/,
  TDS_REGEX = /[0-9]{2}[A-Z]{4}[A-Z0-9]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[D]{1}[0-9A-Z]{1}/,
  TCS_REGEX = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[C]{1}[0-9A-Z]{1}/,
  ARID_REGEX = /[0-9]{12}[A][R][0-9A-Z]{1}/,
  ALL_REGEXES = [GSTIN_REGEX, UIN_REGEX, NRID_REGEX, OIDAR_REGEX, TDS_REGEX, TCS_REGEX, ARID_REGEX];

export function validateGSTNumber(gstNumber) {
  // Convert to Uppercase for simplifying Regex match
  gstNumber = String(gstNumber).toUpperCase();

  // First perform Regex matching. There are various Regex applicable. Anyone of them can match.
  // If none of the Regex matches, then return false
  let regex_match_found = false;
  for (let r = 0; r < ALL_REGEXES.length; r++) {
    regex_match_found = ALL_REGEXES[r].test(gstNumber);
    if (regex_match_found === true) {
      break;
    }
  }

  if (regex_match_found === false) {
    return false;
  }


  // Regex Matches. Do Checksum Validation
  const CHECKSUM_WEIGHT_CHARACTERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  const CHECKSUM_FACTOR_EVEN_PLACE = 1;
  const CHECKSUM_FACTOR_ODD_PLACE = 2;

  let gst_number_last_letter = gstNumber[gstNumber.length - 1];
  let sum = 0;

  let factor = CHECKSUM_FACTOR_EVEN_PLACE;

  let checksum_mod = CHECKSUM_WEIGHT_CHARACTERS.length;
  for (let i = 0; i < gstNumber.length - 1; i++) {
    let current_letter_weight = CHECKSUM_WEIGHT_CHARACTERS.indexOf(gstNumber[i]);

    if (current_letter_weight !== -1) {
      let current_checksum_digit = current_letter_weight * factor;
      current_checksum_digit = parseInt(current_checksum_digit / checksum_mod) + (current_checksum_digit % checksum_mod);
      sum += current_checksum_digit;
    }
    factor = (factor === CHECKSUM_FACTOR_EVEN_PLACE) ? CHECKSUM_FACTOR_ODD_PLACE : CHECKSUM_FACTOR_EVEN_PLACE;
  }

  let calculated_checksum_weight = (checksum_mod - (sum % checksum_mod)) % checksum_mod;
  let calculated_checksum_letter = CHECKSUM_WEIGHT_CHARACTERS[calculated_checksum_weight] ?? '';
  if (calculated_checksum_letter !== gst_number_last_letter) {
    return false;
  }

  return true;
}

export function getFinancialMonths(year, registration_date) {
  let registraion_month_first_date = moment(registration_date).format("YYYY") + "-" + moment(registration_date).format("MM") + "-01";
  let next_year = parseInt(year, 10) + 1;
  let display_year = moment(year + "-01-01").format("YY");
  let display_next_year = moment(next_year + "-01-01").format("YY");
  let current_year = moment().format("YY");
  let current_month = moment().format("M");

  let monthList = [{ text: `Apr ${display_year}`, month: 'Apr', month_fullname: 'April', year: year, id: 'APR', value: 4, disable: true },
  { text: `May ${display_year}`, month: 'May', month_fullname: 'May', year: year, id: 'MAY', value: 5, disable: true },
  { text: `Jun ${display_year}`, month: 'Jun', month_fullname: 'June', year: year, id: 'JUN', value: 6, disable: true },
  { text: `Jul ${display_year}`, month: 'Jul', month_fullname: 'July', year: year, id: 'JUL', value: 7, disable: true },
  { text: `Aug ${display_year}`, month: 'Aug', month_fullname: 'August', year: year, id: 'AUG', value: 8, disable: true },
  { text: `Sep ${display_year}`, month: 'Sep', month_fullname: 'September', year: year, id: 'SEP', value: 9, disable: true },
  { text: `Oct ${display_year}`, month: 'Oct', month_fullname: 'October', year: year, id: 'OCT', value: 10, disable: true },
  { text: `Nov ${display_year}`, month: 'Nov', month_fullname: 'November', year: year, id: 'NOV', value: 11, disable: true },
  { text: `Dec ${display_year}`, month: 'Dec', month_fullname: 'December', year: year, id: 'DEC', value: 12, disable: true },
  { text: `Jan ${display_next_year}`, month: 'Jan', month_fullname: 'January', year: next_year, id: 'JAN', value: 1, disable: true },
  { text: `Feb ${display_next_year}`, month: 'Feb', month_fullname: 'February', year: next_year, id: 'FEB', value: 2, disable: true },
  { text: `Mar ${display_next_year}`, month: 'Mar', month_fullname: 'March', year: next_year, id: 'MAR', value: 3, disable: true }];

  monthList.map((data, index) => {
    if (moment(data.year + '-' + data.value + '-01').unix() >= moment(registraion_month_first_date).unix()) {
      if (parseInt(current_year) === parseInt(display_year) && parseInt(current_month) < parseInt(data.value)) {
        monthList[index].disable = true;
      } else if (parseInt(current_year) === parseInt(display_year) && parseInt(data.value) < 4) {
        monthList[index].disable = true;
      } else {
        monthList[index].disable = false;
      }
    }
    return true
  })
  return monthList;
}

export function getFinancialQuartersMulti(year) {
  const currentYear = getCurrentFinancialYearByQuarter(); //moment().format('YYYY');
  const currentMonth = moment().format('M');
  let next_year = parseInt(year, 10) + 1;
  let quarterList = [];
  if (parseInt(year, 10) === 2017) {
    quarterList = [{ label: `Jul - Sep ${year}`, value: 'Q2', future_month: false },
    { label: `Oct - Dec ${year}`, value: 'Q3', future_month: currentMonth < 10 && currentYear === year ? true : false },
    { label: `Jan - Mar ${next_year}`, value: 'Q4', future_month: currentMonth > 3 && currentYear === year ? true : false }];
  }
  else {
    quarterList = [{ label: `Apr - Jun ${year}`, value: 'Q1', future_month: false },
    { label: `Jul - Sep ${year}`, value: 'Q2', future_month: currentMonth < 7 && currentYear === year ? true : false },
    { label: `Oct - Dec ${year}`, value: 'Q3', future_month: currentMonth < 10 && currentYear === year ? true : false },
    { label: `Jan - Mar ${next_year}`, value: 'Q4', future_month: currentMonth > 3 && currentYear === year ? true : false }];
  }
  return quarterList;
}

export function getPriviousQuarter() {
  const currentQuarter = getCurrentQuarter();
  if (currentQuarter === 'Q4') return 'Q3';
  if (currentQuarter === 'Q3') return 'Q2';
  if (currentQuarter === 'Q2') return 'Q1';
  if (currentQuarter === 'Q1') return 'Q4';
}
export const FormatNumberIntValue = (val, maxDigit = 14, negative = false) => {
  let value = String(val).replace(/[^\d.-]/g, "");
  value = value.substring(0, maxDigit);
  if (isNaN(value)) return "";
  return Number(value);
};

export const FormatNumberDecValue = (val, maxDigit = 14, decimal = 2, negative = false, decimalAllow = true) => {
  if (val === null || val.length === 0) {
    return "";
  }
  if (val === "-" && negative === false) {
    return "";
  }
  if (/^[^0-9-.]*$/.test(val)) {
    return "";
  }


  val = String(val).replace(/-+/g, '-');
  val = val[0] + val.substr(1,).replace(/[^0-9. ]/g, '')
  let value = String(val);
  if (negative === true && String(val[0]) === "-") {
    value = value.slice(1);
    if (value.length === 0) {
      return "-";
    }
  }

  let tmp = String(value).split(".");
  let newValue = 0;
  if (tmp.length === 1 || !decimalAllow) {
    if (!negative) {
      value = value.replace(/[^\d]/g, "");
    }
    newValue = Number(FormatNumberIntValue(value, maxDigit));
  } else if (tmp.length === 2) {
    let value1 = FormatNumberIntValue(tmp[0], maxDigit);
    let value2 = tmp[1];
    newValue = value1 + "." + String(value2).slice(0, decimal);
    if (Math.pow(10, maxDigit) <= Number(value1) + 1) {
      newValue = value1;
    }
  }



  return newValue;
};

export const FormatNumberDecValue1 = (
  val,
  maxDigit = 14,
  decimal = 2,
  negative = false,
  decimalAllow = true,
  e = null
) => {
  let countComma = String(val).split(".").length - 1;
  if (countComma >= 2) {
    val = val.slice(0, -1);
  }
  if (val === null || val.length === 0) {
    return "";
  }
  if (val === "-" && negative === false) {
    return "";
  }
  if (/^[^0-9-.]*$/.test(val)) {
    return "";
  }

  val = String(val).replace(/-+/g, "-");
  val = val[0] + val.substr(1).replace(/[^0-9. ]/g, "");
  let value = String(val);
  if (negative === true && String(val[0]) === "-") {
    value = value.slice(1);
    if (value.length === 0) {
      return "-";
    }
  }

  let tmp = String(value).split(".");
  let newValue = 0;
  if (tmp.length === 1 || !decimalAllow) {
    if (!negative) {
      value = value.replace(/[^\d]/g, "");
    }
    newValue = Number(FormatNumberIntValue(value, maxDigit));
  } else if (tmp.length === 2) {
    let value1 = FormatNumberIntValue(tmp[0], maxDigit);
    let value2 = tmp[1];
    newValue = value1 + "." + String(value2).slice(0, decimal);
    if (Math.pow(10, maxDigit) <= Number(value1) + 1) {
      newValue = value1;
    }
  }

  if (e) {
    const oldValue = e.target.defaultValue;
    let start = e.target.selectionStart;
    const showValue = FormatNumberDecimal(newValue, ...Array(2), true);
    start = start + String(showValue).length - String(e.target.value).length;

    if (
      oldValue === showValue &&
      e?.nativeEvent?.inputType === "deleteContentBackward"
    ) {
      start = start - 1;
    }
    if (
      negative === true &&
      String(val[0]) === "-" &&
      e?.nativeEvent?.inputType !== "deleteContentBackward"
    ) {
      start = start + 1;
    }
    if (start < 0) start = 0;
    setTimeout(() => {
      e.target.setSelectionRange(start, start);
    }, 0);
  }

  if (negative === true && String(val[0]) === "-") {
    if (!Number.isNaN(newValue)) return "-" + newValue;
  }
  if (String(newValue).substr(String(newValue).length - 1) !== ".")
    return Number(newValue);
  return newValue;
};

export const createImportRegularReturnLocalStorageKey = (headerData) => {
  const key = `import-reg-return-${headerData?.mobile_number}-${headerData?.tan}-${headerData?.financial_year_formatted}-${headerData?.quarter}-${headerData?.form_type}`;
  return key;
}

export const createImportRegularDeducteeReturnLocalStorageKey = (headerData) => {
  const key = `import-reg-deductee-return-${headerData?.mobile_number}-${headerData?.tan}`;
  return key;
}

export const createImportCorrectionReturnConosoLocalStorageKey = (headerData) => {
  const key = `import-corr-conso-return-${headerData?.mobile_number}-${headerData?.tan}-${headerData?.financial_year_formatted}-${headerData?.quarter}-${headerData?.form_type}`;
  return key;
}
export const createImportCorrectionReturnLocalStorageKey = (headerData) => {
  const key = `import-cor-return-${headerData?.mobile_number}-${headerData?.tan}-${headerData?.financial_year_formatted}-${headerData?.quarter}-${headerData?.form_type}`;
  return key;
}

export const formatBytes = (bytes) => {
  let decimal = 2;
  let kiloBytes = 1024;
  let megaBytes = 1024 * 1024;
  if (bytes < kiloBytes) return bytes + " Bytes";
  else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
  else return (bytes / megaBytes).toFixed(decimal) + " MB";
}


export const tdsReturnValidNumber = (number, negative = false) => {
  //if ((Math.ceil(Number(number)) > 999999999999) || (negative && Math.ceil(Number(number)) < -999999999999)) return false
  if ((Math.ceil(Number(number)) > 9999999999) || (negative && Math.ceil(Number(number)) < -9999999999)) return false
  return true

}

export function convertNum(value) {
  if (isNaN(value)) return 0;
  return Number(value);
}
export const getInterestLateDeductionRate = () => {

  return LATE_DEDUCTION_INTEREST_RATE;
}

export const isLateDeductionInterestApplicabel = (paymentDate, deductionDate) => {

  return moment(deductionDate, 'DD/MM/YYYY').unix() > moment(paymentDate, 'DD/MM/YYYY').unix();

}

export const getMonthDifference = (startDate, endDate) => {

  const monthsDiff = endDate.getMonth() - startDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear()));

  return (monthsDiff + 1);
}

export const getDaysDifference = (paymentDate, deductionDate) => {
  const dt1 = new Date(paymentDate);
  const dt2 = new Date(deductionDate);
  const daysDiff = Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    (1000 * 60 * 60 * 24)
  );
  return daysDiff;
}

export const interestCalculationOnLateDeduction = (paymentDate, deductionDate, tdsAmount) => {

  if (paymentDate === null || paymentDate === undefined || paymentDate === '') return 0;

  if (deductionDate === null || deductionDate === undefined || deductionDate === '') return 0;

  if (tdsAmount === null || tdsAmount === undefined || tdsAmount === '' || parseFloat(tdsAmount) <= 0) return 0;

  const isLateDeductionApplicable = isLateDeductionInterestApplicabel(paymentDate, deductionDate);

  if (isLateDeductionApplicable === false) return 0;

  const startDate = moment(paymentDate, 'DD/MM/YYYY').format("YYYY-MM-DD");

  const endDate = moment(deductionDate, 'DD/MM/YYYY').format("YYYY-MM-DD");

  //const daysDiff = getDaysDifference(startDate, endDate);

  const monthsDiff = getMonthDifference(new Date(startDate), new Date(endDate));

  return parseFloat(parseFloat(tdsAmount) * monthsDiff * (getInterestLateDeductionRate() / 100));

}

export const titleForRegime = (financialYear) => {
  if (financialYear >= 2023) {
    return "115BAC(1A)"
  }
  return "115BAC"

}

export const getDeductorId = (pathName) => {
  let deductorParam = null
  const deductorIdPath = {
    dashboard: `${BASE_PATH}deductor/:deductorId`,
    editDeductor: `${BASE_PATH}edit-deductor/:deductorId`,
    deducteeList: `${BASE_PATH}deductor/:deductorId/deductees`,
    challans: `${BASE_PATH}deductor/:deductorId/challans`,
    returnDashboard: `${BASE_PATH}deductor/:deductorId/return/:returnId`,
    rrbf: `${BASE_PATH}return-raise/:deductorId/return/:returnId`,
    raisedRequestList: `${BASE_PATH}raised-requests-list/:deductorId`,
    tds_tcsceritficate: `${BASE_PATH}issue-tds-tcs-certificates/:deductorId`,
    dwnloadtcscertficate: `${BASE_PATH}download-tds-tcs-certificates/:deductorId`,
    deductorCorrectionDashboard: `${BASE_PATH}correction-return-dashboard/:deductorId/return/:returnId`,
  }

  for (let key in deductorIdPath) {
    const matchUrlPath = matchPath(pathName, { path: deductorIdPath[key], exact: true, strict: false })

    if (matchUrlPath) {
      const { params } = matchUrlPath;
      deductorParam = params;
      break;

    }
  }
  return deductorParam
}

//This ensures that the original data structure is not modified during the transformation.
export const deepClone = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const syncNowNotShow = (pathName) => {
  let isSynshow = true;
  const browserUrl = {
    editDeductor: `${BASE_PATH}edit-deductor/:deductorId`,
    importedSalary:  `${BASE_PATH}imported-salary-details`,
    monthwiseSalaryDetails: `${BASE_PATH}monthwise-salary-details`,
    emailSalaryDeails: `${BASE_PATH}email-salary-details`,
  }
  for (let key in browserUrl) {
    const matchUrlPath = matchPath(pathName, { path: browserUrl[key], exact: true, strict: false })

    if (matchUrlPath) {
      isSynshow = false;
      break;
    }
  }
  return isSynshow;
}
export const FormatNumberDecimal = (
  getValue,
  dotBeforeDigit = 14,
  dotAfterDigit = 2,
  negative = true
) => {
  let val = String(getValue);
  let value = val;
  value = value.replace(/[^\d.-]/g, "");
  if (isNaN(value) && value !== ".") {
    value = value.slice(0, -1);
  }
  let tmp = String(value).split(".");
  if (tmp.length === 1) {
    value = value.replace(/[\D\s_-]+/g, "");
    value = value.slice(0, dotBeforeDigit);

    value = value ? parseFloat(value, 10) : "";
    if (negative === true && String(val)[0] === "-") {
      return "-" + value.toLocaleString("en-IN");
    }
    return value === 0 ? "0" : value.toLocaleString("en-IN");
  } else if (tmp.length === 2) {
    let value1 = tmp[0].replace(/[\D\s_-]+/g, "");
    let value2 = tmp[1].replace(/[\D\s_-]+/g, "");

    if (dotBeforeDigit && dotBeforeDigit > 0) {
      value1 = value1.slice(0, dotBeforeDigit);
    }
    //  if (dotAfterDigit && dotAfterDigit > 0) {
    value2 = value2.slice(0, dotAfterDigit);
    //  }

    value1 = value1 ? parseFloat(value1, 10) : 0;
    if (value2[0] === "0") {
      if (value2.length > 1) value2 = "0" + parseFloat(value2, 10);
      else value2 = parseFloat(value2, 10);
    } else {
      value2 = value2 ? parseFloat(value2, 10) : "";
    }

    if (negative === true && String(val[0]) === "-") {
      return "-" + value1.toLocaleString("en-IN") + "." + value2;
    }
    return value1.toLocaleString("en-IN") + "." + value2;
  }
};

export const roundUpValue = (value) => {     
  const decimalPart = Number(String(Number(value ?? 0).toFixed(2)).split('.')?.[1] ?? 0);
  if(decimalPart >= 50) {
    return Math.ceil(Number(value ?? 0)).toFixed(2);
  }else{
    return Math.floor(Number(value ?? 0)).toFixed(2);
  }  
}
  //function for get generate random number
 export function genRandNum(length) {
    const characters = '0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getColumnTotalFromArrayOfObject(array, property) {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);

  return total;
}

export function encryptPasswordString(password) {
  var CryptoJS = require("crypto-js");
  let key = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPTION_KEY);
  let iv = CryptoJS.enc.Utf8.parse(PASSWORD_ENCRYPTION_IV);
  var encryptedPassword = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7
  }).toString();

  return encryptedPassword;
}

export function sortChange(data_state, field, dataStateChange){
  let new_state = data_state;

  let dir = 'asc';
  if (data_state && data_state.sort) {
      if (data_state.sort[0] && data_state.sort[0].field === field) {
          dir = data_state.sort[0].dir === 'asc' ? 'desc' : '';
      }
  }
  if (field) {
      new_state.sort = dir ? [{ field: field, dir: dir }] : false;
      dataStateChange(new_state);
  }
}

export const roundUpValueWithoutDecimal = (value) => {     
  const decimalPart = Number(String(Number(value ?? 0).toFixed(2)).split('.')?.[1] ?? 0);
  if(decimalPart >= 50) {
    return Math.ceil(Number(value ?? 0));
  }else{
    return Math.floor(Number(value ?? 0));
  }  
}


export const isForm12BAApplicable = (formType, quarter, fy) => { 
  return (formType === "24Q" && quarter === "Q4" && parseInt(fy) >= 2024 && isDIY());
}