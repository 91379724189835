/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {  BASE_URL, HEADER_FILE_NAME } from '../app/constants';
import {  handleErrorWithCode, getFirstError, handleError } from './responseHandler';
import { getConfig } from './common';


export async function getSyncStatusWithoutSettingHeader(deductor_id) {
  const url = `${BASE_URL}/api/v1/deductor/sync-status?deductor_id=${deductor_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadExcelReturnRegister(params) {
  try {
    const config = { ...getConfig(), responseType: 'blob' };
    const result = await axios.get(`${BASE_URL}/api/v1/return-register/download${params}`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', result?.headers?.[HEADER_FILE_NAME] ?? 'Return Register.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function verfiyEfilingCrediationalOnServer(data) {
  const url = `${BASE_URL}/api/v1/return/efiling/filing-history`;

  try {
    const loginData = await axios.post(url, data, getConfig());
    if (Number(loginData.data.status) !== 1) {
      throw (loginData.data.message);
    }

    return loginData;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadReturnFilingReceipt(params, filepath) { 
  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(`${BASE_URL}/api/v1/return/efiling/download-receipts${params}`, config);
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filepath}${result?.headers?.[HEADER_FILE_NAME]}` ?? `${filepath}_receipt.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    let errorString = ""
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      errorString = getFirstError(JSON.parse(await err.response.data.text()));
    } else {
      errorString = handleError(err);
    }
    throw errorString;
  }
}

export async function verfiyItloginCrediationalOnServer(data) {
  const url = `${BASE_URL}/api/v1/portal/itefl/login${data}`;

  try {
    const loginData = await axios.get(url, getConfig());
    if (Number(loginData.data.status) !== 1) {
      throw (loginData.data.message);
    }

    return loginData;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function verfiyTracesloginCrediationalOnServer(data) {
  const url = `${BASE_URL}/api/v1/portal/traces/login${data}`;

  try {
    const loginData = await axios.get(url, getConfig());
    if (Number(loginData.data.status) !== 1) {
      throw (loginData.data.message);
    }

    return loginData;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getConsoRequestConfigStatus() {
  const url = `${BASE_URL}/api/v1/settings`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateConsoRequestConfigStatus(data) {
  const url = `${BASE_URL}/api/v1/settings`;

  try {
    const loginData = await axios.put(url, data, getConfig());
    if (Number(loginData.data.status) !== 1) {
      throw (loginData.data.message);
    }

    return loginData;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
