import axios from "axios";
import { BASE_URL } from "../app/constants";
import { getFirstError, handleErrorWithCode } from "./responseHandler";
import { getConfig } from "./common";

export async function downloadFileByRefKey(dataKey) {
  const url = `${BASE_URL}/api/v1/files/download-file?file_ref_key=${dataKey}`;

  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    if (err?.response?.status === 404) {
      throw err.response;
    } else {
      if (
        err.request.responseType === "blob" &&
        err.response.data instanceof Blob &&
        err.response.data.type &&
        err.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        throw getFirstError(JSON.parse(await err.response.data.text()));
      } else {
        throw handleErrorWithCode(err);
      }
    }
  }
}
