import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import closeIcon from "../../images/close_icon.png";
import successIcon from "../../images/successicon_icon.png";
import downArrow from '../../images/icons/down_arrow.svg';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import mobile from "../../images/loginicon/mobile.svg";
import mail from "../../images/email_icon.png";
import editIcon from "../../images/edite02.png";
import PasswordUpdate from './PasswordUpdate';
import OrganizationProfile from './OrganizationProfile';
import { updateUserProfile, getUserProfile, updateUserVerifiedMobileEmail, sendOtpToVerifyUserMobileEmail } from '../../api/profileAPI';
import { verifyOTP } from '../../api/authenticationAPI';
import NumericInput from '../NumericInput';
import ShowAlert from '../ShowAlert';
import { BASE_PATH } from '../../app/constants';
import OtpVerifiyPopup from './OtpVerifiyPopup';
import EditUserInfoPopup from './EditUserInfoPopup';
import { useCallback } from 'react';
import './Profile.css';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Profile = ({ history, ...props }) => {
  const { userId } = props;
  const [loading, setLoading] = useState(false);
  const [loadingInd, setLoadingInd] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [userData, setUserData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [otpVerifiyPopup, setOtpVerifiyPopup] = useState(false);
  const [editUerInfo, setEditUerInfo] = useState(false);
  const [header, setHeader] = useState({})

  const updateProfile = async (e) => {
    e.preventDefault();

    const formErr = {};
    if (userData.full_name.trim().length === 0) {
      formErr.full_name = 'Name is required';
    }

    setFormErrors(formErr);

    if (Object.keys(formErr).length > 0) return;

    setLoading(true);
    const data = {
      full_name: userData.full_name.replace(/<\/?[^>]+(>|$)/g, ""),
      employee_no: userData.employee_no ? userData.employee_no.replace(/<\/?[^>]+(>|$)/g, "") : '',
      designation: userData.designation ? userData.designation.replace(/<\/?[^>]+(>|$)/g, "") : '',
      department: userData.department ? userData.department.replace(/<\/?[^>]+(>|$)/g, "") : '',
    };
    if (userId) {
      data.user_id = userId;
    }
    try {
      const result = await updateUserProfile(data);
      setUserData({ ...userData, ...data });
      setSuccess(result.message);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const updateEmailOtpVerification = async (data) => {
    setLoadingInd(true)
    let apiData = {}
    apiData["country_code"] = data?.country_code ?? "";
    apiData["otp_verification_id"] = data?.otp_verification_id ?? "";
    apiData["otp"] = data?.otp ?? ""
    if (isNaN(Number(data.mobile_no_or_email))) apiData["email"] = data.mobile_no_or_email ?? ""
    else {
      apiData["mobile_no"] = data?.mobile_no_or_email ?? ""
    }
    try {
      await updateUserVerifiedMobileEmail(apiData);
      setTimeout(() => {
        getUserData();
      }, 250)
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoadingInd(false)
    }
  }

  const OtpVerification = async (data) => {

    setLoadingInd(true);
    try {
      const otpResult = await verifyOTP(data);

      setSuccess(otpResult.message);
      setOtpVerifiyPopup(false);
      updateEmailOtpVerification(data)

    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingInd(false)
    }
  }

  const updateMobileEmail = async (data, key) => {
    setLoadingInd(true);
    try {
      const result = await sendOtpToVerifyUserMobileEmail(data);
      setEditUerInfo(false);
      if (key === "mobile_no") {
        setTimeout(() => {
          setOtpVerifiyPopup({
            Title: "Mobile Number",
            user: data?.mobile_no ?? "",
            country_code: userData?.country_code ?? "",
            icon: mobile,
            id: result?.data?.otp_verification_id ?? "",
            key: "mobile_no",

          })
        }, 250)
      } else if (key === "email") {
        setTimeout(() => {
          setOtpVerifiyPopup({
            Title: "Email Address",
            user: data?.email ?? "",
            country_code: userData?.country_code ?? "",
            icon: mail,
            id: result?.data?.otp_verification_id ?? "",
            key: "email",

          })
        }, 250)

      }
      setSuccess(result.message);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingInd(false);
    }
  }

  const getUserData = useCallback(async () => {
    let searchStr = '';
    if (userId) {
      searchStr = `?user_id=${userId}`;
    }
    setLoadingInd(true);
    try {
      const result = await getUserProfile(searchStr);
      setUserData(result?.data ?? {});
      setHeader(result?.header ?? {})
      if (result.header?.user_role?.toUpperCase() !== 'OWNER') {
        history.push(BASE_PATH);
      } else {
        setUserRole(result?.header?.user_role?.toUpperCase());
      }
    } catch (err) {
      // console.error('Error in getting company profile: ', err);
    }
    setLoadingInd(false);
  }, [history, userId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(userData.tenant_code);
    setSuccess("Customer ID Copied");
  };

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <div>
      {loadingInd ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      {otpVerifiyPopup && <OtpVerifiyPopup
        data={otpVerifiyPopup}
        handleClose={() => {
          setOtpVerifiyPopup(false);
        }}
        verifiyApi={OtpVerification}
        resendOtP={updateMobileEmail}
        error={error}
        setError={setError}
      />}
      {editUerInfo &&
        <EditUserInfoPopup
          data={editUerInfo}
          api={updateMobileEmail}
          handleClose={() => {
            setEditUerInfo(false);
          }}
          error={error}
          setError={setError}
        />}

      <div className="container mt-2">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-3">
            
              <Link className="backtoclr" to={`${BASE_PATH}user-management`}>
                {'< '}
                Back to User Management
              </Link>
            
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 text-center">
          <h4 className="profiletex_fs">Profile</h4>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={updateProfile} autoComplete="off">
          <div className="card padding_top_manage profilecardpadd">
            <div className="row">
              <div className="grey_bg">
                <div className="row">
                  <div className="col-sm-12">
                    <div

                      data-toggle="collapse"
                      href="#collapseUserManagement"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseUserManagement"
                    >
                      {' '}
                      <span style={{ fontSize: '0.9rem' }}>

                        Personal & Contact Information
                      </span>
                      <img
                        className="create_new_return float-right"
                        src={downArrow}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" collapse show" id="collapseUserManagement">
              <div className="row mt-2">
                <div className="form-group col-sm-3" onClick={copyToClipboard}>
                    <label className='txt-blue'>Customer ID: {userData.tenant_code}</label>
                    <span className='ml-1 mt-2'>
                      <FontAwesomeIcon icon={faCopy}  title='Copy Customer ID' className='copy-icon'/>
                    </span>  
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="form-group col-sm-4">
                      <label htmlFor="inputEmail4">
                        Name
                        <span className="red_text">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        value={userData.full_name}
                        onChange={(e) => setUserData({ ...userData, full_name: e.target.value })}
                        disabled={(userRole !== 'OWNER')}
                      />
                      {formErrors.full_name ? <span className="error_message">{formErrors.full_name}</span> : null}
                    </div>
                    <div className="form-group col-sm-4">
                      <label htmlFor="exampleFormControlSelect1">Mobile Number</label>
                      <span className="red_text">*</span>
                      <span className="forminput_verified" >
                        {userData?.mobile_no_verified ?
                          <span >
                            <img alt="verified Icon" src={successIcon} />
                            <span className="verified" >Verified</span>
                          </span> : String(userData.mobile_no) === String(header.mobile_number) ?
                            <span
                              onClick={() => {
                                let data = {
                                  country_code: userData.country_code,
                                  mobile_no: userData.mobile_no
                                }
                                updateMobileEmail(data, "mobile_no");
                              }}
                            >
                              <img alt="Not Verified icon" src={closeIcon} />
                              <span className="notverified">
                                Verify Now</span>
                            </span> : <span >
                              <img alt="Not Verified icon" src={closeIcon} />
                              <span className="notverified" >Un-Verified</span>
                            </span>
                        }
                      </span>
                      <div className="input-group ">
                        <div className="input-group-prepend inpgpbx profileuseraddmob">
                          <img className="" src={Indiaflagexp} alt="mobile" />
                        </div>
                        <NumericInput
                          type="tel"
                          className="form-control"
                          name="Mobile"
                          placeholder="Enter Mobile Number"
                          maxLength="10"
                          value={userData.mobile_no}
                          onChange={(e) => setUserData({ ...userData, mobile_no: e.target.value })}
                          disabled
                        />
                        {userData.mobile_no === header.mobile_number ?
                          <span className="mobileeditIcon" ><img alt="editIcon" src={editIcon} width="16" onClick={() => {
                            setEditUerInfo({ Title: "Mobile No", length: 10, country_code: userData.country_code, value: userData.mobile_no, key: "mobile_no" })
                          }}
                          />
                          </span> : null}

                      </div>
                    </div>
                    <div className="form-group col-sm-4">
                      <label htmlFor="inputEmail4">
                        Email Address
                        <span className="red_text">*</span>
                      </label>
                      <span className="forminput_verified">
                        {userData?.email_verified ?
                          <span >
                            <img alt="verified Icon" src={successIcon} />
                            <span className="verified" >Verified</span>
                          </span> : userData.email === header.email ?
                            <span
                              onClick={() => {
                                let data = {
                                  country_code: userData.country_code,
                                  email: userData.email
                                }
                                updateMobileEmail(data, "email");
                              }}
                            >
                              <img alt="Not Verified icon" src={closeIcon} />
                              <span className="notverified">
                                Verify Now
                              </span>
                            </span> : <span >
                              <img alt="Not Verified icon" src={closeIcon} />
                              <span className="notverified" >Un-Verified</span>
                            </span>
                        }
                      </span>
                      <div style={{ position: "relative" }}>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          autoComplete="new-password"
                          value={userData.email}
                          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                          disabled
                        />
                        {userData.email === header.email ?
                          <span className="emaileditIcon" ><img alt="editIcon" src={editIcon} width="16"
                            onClick={() => { setEditUerInfo({ Title: "Email Address", country_code: userData.country_code, value: userData.email, key: "email" }) }}
                          />
                          </span> : null}
                      </div>
                    </div>

                    <div className="form-group col-sm-4">
                      <label htmlFor="inputEmail4">Employee No.</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter Employee No."
                        value={userData.employee_no}
                        onChange={(e) => setUserData({ ...userData, employee_no: e.target.value })}
                        disabled={(userRole !== 'OWNER')}
                      />
                    </div>
                    <div className="form-group col-sm-4">
                      <label htmlFor="inputEmail4">Designation</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Designation"
                        value={userData.designation}
                        onChange={(e) => setUserData({ ...userData, designation: e.target.value })}
                        disabled={(userRole !== 'OWNER')}
                      />
                    </div>
                    <div className="form-group col-sm-4">
                      <label htmlFor="inputEmail4">Department</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Department"
                        value={userData.department}
                        onChange={(e) => setUserData({ ...userData, department: e.target.value })}
                        disabled={(userRole !== 'OWNER')}
                      />
                    </div>
                  </div>
                  {(userRole === 'OWNER') && (
                    <div className="col-sm-12">
                      <div className="text-center mb-2">
                        <button className="light_color_bg profilsavebtn" type="submit" disabled={loading}>
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {' '}
                              Loading...
                            </>
                          ) : 'Save'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </form>
      </div >

      {!userId && <OrganizationProfile editable={(userRole === 'OWNER')} />}

      {!userId && <PasswordUpdate />}

    </div >
  );
};

export default Profile;
