/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleSuccess, handleErrorWithCode } from './responseHandler';
import { getConfig } from './common';

export async function getBillingData(searchStr = '') {
    const url = `${BASE_URL}/api/v1/sd/tenant/billing-detail${searchStr}`;
    try {
        let result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}


export async function applyDiscountVoucher(data) {
    const url = `${BASE_URL}/api/v1/sd/apply-discount-voucher`;
    try {
        let result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function generatePaymentOrder(data) {
    const url = `${BASE_URL}/api/v1/sd/generate-payment-order`;
    try {
        let result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function refreshToken() {
    const url = `${BASE_URL}/api/v1/user/token`;
    try {
        let result = await axios.get(url, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}
export async function applyAddOnDiscountVoucher(data) {
    const url = `${BASE_URL}/api/v1/sd/addon/apply-discount-voucher`;
    try {
        let result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}

export async function generateAddOnPaymentOrder(data) {
    const url = `${BASE_URL}/api/v1/sd/addon/generate-payment-order`;
    try {
        let result = await axios.post(url, data, getConfig());
        if (Number(result.data.status) !== 1) {
            throw (result.data.message);
        }
        return handleSuccess(result.data);
    } catch (err) {
        throw handleErrorWithCode(err);
    }
}