import React, { useState, useEffect } from "react";
import WindowWrapper from "../../components/grid/WindowWrapper";
import { getDeducteesTransactionsV2 } from "../../api/deducteeTransactionsAPI";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCell } from "../../components/grid/CustomCell";
import { ColumnMenu } from "../../components/grid/ColumnMenu";
import { formatNumber } from "../../utils/UtilityFunctions";
import PostPagination from "../../components/pagination/PostPagination";
import Skeleton12ColumnGrid from "../../components/Skeleton/Skeleton12ColumnGrid";


const SectionWiseTransactionPopup = (props) => {

    const { deductorId, returnId, uniqueID, data, setError, onClose, totalPopups, index = 0 } = props;

    const [dataState, setDataState] = useState({});
    const [deducteeTransactionsList, setDeducteeTransactionsList] = useState([]);
    const [cursor, setCursor] = useState({ current_page: 1, next_page: null })
    const [skeletonList, setSkeletonList] = useState(true);

    const fetchNextData = (pageNo) => {
        fetchData({ ...dataState, page: pageNo?? 1 });
    }


    const fetchData = async (filterData = dataState) => {
        setSkeletonList(true);

        let request = {
            deductor_id: deductorId,
            return_id: returnId,
            nature_of_payment_id: uniqueID
        };

        if (filterData.filter) {
            request.filters = filterData?.filter?.filters;
        }

        if (filterData?.sort && filterData.sort.length > 0) {
            request.sort_on = filterData.sort[0].field;
            request.sort_by = filterData.sort[0].dir;
        }
        request.page = filterData?.page ?? 1

        try {
            const result = await getDeducteesTransactionsV2(request);;
            if (result?.data) {
                setDeducteeTransactionsList(result?.data?.data ?? []);
            }
            setCursor(result?.data?.cursor ? {...result?.data?.cursor,next_page_url : result?.data?.cursor?.next_page ?? null} : {});


        } catch (err) {
            setError(err.message);
        } finally{
                setSkeletonList(false);
            
        }
    };



    const dataStateChange = (dataState) => {
        setDataState(dataState);
        fetchData(dataState);
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    const rowRender = (trElement, props,) => {

        const index = props.dataIndex;

        return (
            <tr data-index={index} className={index % 2 === 0 ? 'deducteewise_tb_alternetrowbgclr_grey ' : 'deducteewise_tb_alternetrowbgclr_white '} role="row">
                <td className="text-left"> {props?.dataItem?.deductee_name ?? "-"} </td>
                <td className="text-left"> {props?.dataItem?.pan ?? "-"} </td>
                <td className="text-center"> {props?.dataItem?.payment_date ?? "-"} </td>
                <td className="text-center"> {props?.dataItem?.deduction_date ?? 0} </td>
                <td className="text-right"> {formatNumber(parseFloat(props?.dataItem?.paid_amtp).toFixed(2)) ?? 0} </td>
                <td className="text-right"> {formatNumber(parseFloat(props?.dataItem?.total_tds_amtp).toFixed(2)) ?? 0} </td>
                <td className="text-center"> {props?.dataItem?.challan_no ?? "-"} </td>
            </tr>
        )
    };

    useEffect(() => {
        fetchData(dataState);
        // eslint-disable-next-line
    }, [deductorId, returnId, uniqueID]);

    return (

        <WindowWrapper
        initialHeight = {450}
            title={<kendo-dialog-titlebar>
                <h4 class="modal-title">
                    <>
                        <div className="table-responsive">
                            <table className="deducteesHeaderRow">
                                <thead>
                                    <tr className="headerRowTable">
                                        <td width="250">Section</td>
                                        <td width="150">Rate(%)</td>
                                        <td width="150">Amount Paid</td>
                                        <td width="150"> Total Tax Deducted</td>
                                    </tr></thead>
                                <tbody>

                                    <tr className="headerRowTable2">
                                        <th width="250">{data?.display_code ?? ""}</th>
                                        <th width="150">{formatNumber(data?.tds_rate_b100) ?? ""}</th>
                                        <th width="150">{formatNumber(parseFloat(data?.deductee_paid_amtp).toFixed(2)) ?? 0}</th>
                                        <th width="150">{formatNumber(parseFloat(data?.deductee_total_tds_amtp).toFixed(2)) ?? 0}</th>
                                    </tr>
                                </tbody >
                            </table>
                        </div>
                    </>
                </h4>
            </kendo-dialog-titlebar>}
            onClose={onClose}
            totalPopups={totalPopups}
            index={index}
            className="modal-dialog modal-lg"
            defaultWidth="1523"
            style={{ maxHeight: '527px', overflowX: 'hidden' }}

        >

            <div className="card" style={{ paddingTop: "unset" }}>
                <div className="row">
                    <div className="col-md-12">
                   { skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                        :
                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                            <Tooltip anchorElement="pointer" position="right">
                                <Grid
                                    className="table table-striped listofsalarydetailstb1  deducteewisetrns_heightfixed mb-0"
                                    style={{ maxHeight: '320px' }}
                                    data={deducteeTransactionsList}
                                    {...dataState}
                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                    filterOperators={{
                                        text: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                        ],
                                        numeric: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                        ],
                                        date: [
                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                        ],
                                        textWithEmpty: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                        dropdown: [
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ],
                                        pan: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterContainsOperator', operator: 'in' },
                                        ],
                                        challan: [
                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                            { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                        ],
                                    }}
                                    sortable={{
                                        allowUnsort: true,
                                    }}
                                    rowRender={rowRender}
                       
                                >

                                    <Column
                                        field="deductee_name"
                                        className="text-center"
                                        filter="textWithEmpty"
                                        title={<CustomCell title={'Deductee Name'} field="deductee_name" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='100'
                                        headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter />}
                                        sortable={true}
                                    />
                                    <Column
                                        field="pan"
                                        className="text-center"
                                        filter="pan"
                                        title={<CustomCell title={'PAN'} field="pan" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='200'
                                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                    />
                                    <Column
                                        field="payment_date"
                                        className="text-center"
                                        filter="date"
                                        title={<CustomCell title={'Payment Date'} field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='150'
                                        headerClassName={isColumnActive('payment_date',) ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                    <Column
                                        field="deduction_date"
                                        className="text-center"
                                        filter="date"
                                        title={<CustomCell title={'Deduction Date'} field="deduction_date" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='150'
                                        headerClassName={isColumnActive('deduction_date') ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                    <Column
                                        field="paid_amtp"
                                        className="text-center"
                                        filter="numeric"
                                        title={<CustomCell title={'Amount Paid'} field="paid_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='150'
                                        headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                    <Column
                                        field="total_tds_amtp"
                                        className="text-center"
                                        filter="numeric"
                                        title={<CustomCell title={'Tax Deducted'} field="total_tds_amtp" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='150'
                                        headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu {...props} />}
                                    />
                                    <Column
                                        field="challan_no"
                                        className="text-center"
                                        filter="textWithEmpty"
                                        title={<CustomCell title={'Mapped Challan'} field="challan_no" dataStateChange={dataStateChange} dataState={dataState} />}
                                        // width='150'
                                        headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                    />

                                </Grid>

                            </Tooltip>
                            <PostPagination
                                className="paddbpagi"
                                cursor={cursor}
                                fetchData={fetchNextData}
                            />
                        </div> }
                    </div>
                </div>
            </div>
        </WindowWrapper>
    )

};

export default SectionWiseTransactionPopup;