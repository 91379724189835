import React, { useEffect, useState } from 'react'
// import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import EmailPdfRow from './EmailPdfRow';
import { getDeducteeListSendMail, getDeducteeSummarySendMail, sendEmailtoDeductee } from '../../api/issueCertificateAPI';
import { Spinner } from 'react-bootstrap';
import './EmailPdfV1.scss';
import { convertNum, isForm12BAApplicable } from '../../utils/UtilityFunctions.js';
import EmailProgressBarPdf from './EmailProgressBarPdf.jsx';
import SemanticPagination from '../../components/pagination/SemanticPagination.jsx';

const EmailPdfList = (props) => {

    const { formType, deductorId, quarter, year, emailType } = props;

    const location = useLocation();
    const history = useHistory();
    const params = queryString.parse(location.search);
    const [error, setError] = useState(null);
    const [dataState, setDataState] = useState({});
    const [loading, setLoading] = useState(false);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [sendEmailList, setSendEmailList] = useState([]);
    const [port, setPort] = useState(0);
    const [success, setSuccess] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [summary, setSummary] = useState(null);
    const [selectDeducteeList, setSelectDeducteeList] = useState([])
    const [loadingMail, setLoadingMail] = useState(false);
    const [progressBarStart, SetProgessBarStart] = useState(false);

    const isForm12BAAppl = isForm12BAApplicable(formType, quarter, year);    

    const fetchEmailDeducteeList = async (postData) => {
        const data = postData
        data.deductor_id = deductorId;
        data.financial_year = year;
        data.quarter = quarter;
        data.form_type = formType;

        setLoading(true);
        try {
            const result = await getDeducteeListSendMail(data);
            if (result?.data) {
                setSendEmailList(result?.data?.list ?? []);
            }
            setCursor(result.data.cursor);
            setError(null);
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }


    const fetchSummaryTotal = async (postData) => {
        const data = postData
        data.deductor_id = deductorId;
        data.financial_year = year;
        data.quarter = quarter;
        data.form_type = formType;

        try {
            const result = await getDeducteeSummarySendMail(data);
            if (result?.data) {
                setSummary(result?.data ?? {});
            }
            setError(null);
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
        }
    }

    const getParamsHistoryUrl = () => {
        const requestData = {};
        if (params.sort_on) {
            dataState.sort = [{ field: params.sort_on, dir: params.sort_by ?? 'asc' }];
            requestData.sort_on = params.sort_on;
            requestData.order_by = params.sort_by;
        }
        dataState.filter = { filters: [] };
        if (params.filters) {
            let frs = JSON.parse(atob(params.filters));
            dataState.filter.filters = frs;
            requestData.filters = JSON.parse(atob(params.filters));
        }
        if (params.page) {
            requestData.page = Number(params.page);
        }
        requestData.limit = params.limit ?? 15;
        setDataState(dataState);
        return requestData;
    };

    const createHistoryUrl = (dataState = []) => {

        let filterStr = `?page=1`;
        if(dataState.page){
            filterStr = `?page=${dataState.page}`;
        }

        if (params.email_type) {
            filterStr += `&email_type=${params.email_type}`;
        }
        if (params.form_type) {
            filterStr += `&form_type=${params.form_type}`;
        }
        if (params.quarter) {
            filterStr += `&quarter=${params.quarter}`;
        }
        if (params.year) {
            filterStr += `&year=${params.year}`;
        }


        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr += `&sort_on=${dataState.sort?.[0]?.field ?? ''}`;
            filterStr += `&sort_by=${dataState.sort?.[0]?.dir ?? ''}`;
        }
        history.push(filterStr);
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };




    const rowRender = (trElement, props) => {
        const index = trElement._owner.index ?? 0;
        return <EmailPdfRow
            trElement={trElement}
            dataItem={JSON.stringify(props?.dataItem)}
            index={index}
            List={sendEmailList}
            port={port}
            setPort={setPort}
            getParamsHistoryUrl={getParamsHistoryUrl}
            fetchEmailDeducteeList={fetchEmailDeducteeList}
            deductorId={deductorId}
            setSelectDeducteeList={setSelectDeducteeList}
            selectDeducteeList={selectDeducteeList}
            allSelected={allSelected}
            sumCntUpdated={(prevValue, value) => {
                if (!prevValue && value) {
                    summary.email_count += 1;
                } else if (prevValue && !value) {
                    summary.email_count -= 1;
                }
                setSummary({ ...summary });
            }}
            updateParent={(dataItem, index) => {
              sendEmailList[index] = dataItem;
              setSendEmailList([...sendEmailList]);
            }}
        />
    }
    const selectAllfunc = () => {
        if (allSelected) {
            setSelectDeducteeList([])
        }
        setAllSelected((prev) => !prev)
    }


    const sendEmailDeductee = async (deducteeList) => {
        const data = {}
        data.deductor_id = deductorId;
        data.financial_year = year;
        data.quarter = quarter;
        data.form_type = formType;
        data.deductee_id = deducteeList
        data.pdf_type = emailType.toUpperCase();

        if (isForm12BAAppl) {
            data.include_form_12ba = params?.form_12ba === "false" ? false : true;            
        }

        if (deducteeList.length === 0) {
            setError('Please select atleast 1 Deductee to send Email.');
        } else if (deducteeList && deducteeList.length > 0) {
            setLoadingMail(true);
            try {
                 await sendEmailtoDeductee(data);
                    setSelectDeducteeList([])
                    setAllSelected(false)
                    SetProgessBarStart(true); // send mail progress bar start
            } catch (error) {
                setError(error.message);
            } finally {
                setLoadingMail(false);
            }
        }
    }

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        fetchEmailDeducteeList(postData)
        if (!summary) {
            fetchSummaryTotal(postData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])


    return (
        <>
            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

            <EmailProgressBarPdf 
               progressBarStart={progressBarStart}
               SetProgessBarStart={SetProgessBarStart}
               formType={formType}
               deductorId={deductorId}
               quarter={quarter}
               year={year}
               emailType={emailType}
               setSuccess={setSuccess}
               success={success}
            />

            <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                <div class="card email_pdf_deducteeboxbgclr">
                    <p class="emailselected_deductee_subtitle">
                        <span class="btn btn-default form16_selectedemail_sendbtn text-left" onClick={() => sendEmailDeductee(selectDeducteeList)} disabled={false}>
                            {loadingMail ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {' '}
                                    Sending...
                                </>
                            ) : 'Send'}
                        </span>
                        <span class="totaldedcuteebox">
                            {formType === "24Q" ? "Total Employee" : "Total Deductees"} <span class="emailselected_deductee_qboldtext">{`${summary?.deductee_pan_count ?? 0}`}</span>
                            <span class="email_pdf_deductee_vlineclr">|</span>
                            Email available
                            <span class="emailselected_deductee_qboldtext">{summary?.email_count ?? 0}</span>
                            <span class="email_pdf_deductee_vlineclr">|</span>
                            Update for
                            <span class="emailselected_deductee_qboldtext">{convertNum(summary?.deductee_pan_count) - convertNum(summary?.email_count)}</span>
                            <span class="email_pdf_deductee_vlineclr">|</span>
                            Selected to Send Email
                            <span class="emailselected_deductee_qboldtext">{selectDeducteeList?.length ?? 0}</span>
                        </span>


                    </p>
                </div>

            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="card email_pdf_deducteeboxbgclr">
                    <h4 class="exptds_form16_status_wantuptextfs">Want to Update through Excel?</h4>
                </div>
            </div>
            <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                <div class="card pt-2 pb-1 px-0">
                    <div class="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">

                        <Grid
                            data={sendEmailList}
                            className={`table table-striped tds_pendingreq_tb mb-1`}
                            style={{ maxHeight: '485px', overflow: 'auto' }}
                            {...dataState}
                            onDataStateChange={(event) => dataStateChange(event.dataState)}
                            filterOperators={{
                                text: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                ],
                                dropdown: [
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                ],
                                numeric: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                ],
                                date: [
                                    { text: 'grid.filterGteOperator', operator: 'gte' },
                                    { text: 'grid.filterLteOperator', operator: 'lte' },
                                    { text: 'grid.filterLtOperator', operator: 'lt' },
                                    { text: 'grid.filterGtOperator', operator: 'gt' },
                                    { text: 'grid.filterEqOperator', operator: 'eq' },
                                ],
                                textWithEmpty: [
                                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                ],
                            }}
                            scrollable
                            editField="inEdit"
                            rowRender={rowRender}
                            sortable
                        >


                            <Column
                                width="65"
                                filter="text"
                                title=""
                                headerCell={(props) => {
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                            <input style={{ height: '13px !important' }} type="checkbox" checked={allSelected} onChange={() => selectAllfunc()} />
                                        </div>
                                    );
                                }}
                                headerClassName="text-center"
                            />

                            <Column
                                width="250"
                                field="deductee_name"
                                filter="text"
                                headerClassName={isColumnActive('deductee_name') ? 'text-center active' : 'text-center'}
                                title={formType === "24Q" ? "Employee Name" : "Deductee Name"}
                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}

                            />

                            <Column
                                width="150"
                                field="pan"
                                filter="text"
                                title="PAN"
                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                headerClassName={isColumnActive('pan') ? ' active' : ''}

                            />
                            <Column
                                width="250"
                                field="email"
                                filter="text"
                                title="Email Address"
                                columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                headerClassName={isColumnActive('email') ? ' active' : ''}


                            />
                        </Grid>


                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <SemanticPagination 
                               cursor={cursor}
                               summary={summary}
                               pagelimit={15}
                               count={"deductee_pan_count"}
                            />
                           
                        </div>

                    </div>

                </div>
            </div>



        </>
    )
}

export default EmailPdfList