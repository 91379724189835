export const API_BASE_URL = "https://appdev552.indiatds.com";

// Maximum File size that can be imported (in MB)
export const CFG_MAX_IMPORT_FILE_SIZE_MB = 100;

export const SITE_BASE_PATH = "/";
export const IN_MAINTENANCE_MODE = false;
export const MAINTENANCE_MESSAGE = "We are doing important maintenance work on the website and will be back shortly.<br /> We apologise for any inconvenience caused. If you need to get in teuch with us, Please use one of the options belows."
export const CFG_CONNECTOR_DOWNLOAD_LINK = "https://kdk-connector.s3.ap-south-1.amazonaws.com/Win64Self.zip";
export const SITE_LOGO = "EXTDS";
export const CFG_DEFAULT_UTM_SOURCE = "exptdsdirect";

export const PROFESSIONAL_SILVER_PAYMENT = "https://rzp.io/l/nhSOlGe";
export const PROFESSIONAL_GOLD_PAYMENT = "https://rzp.io/l/E4DtznQjv";
export const PROFESSIONAL_PLATINUM_PAYMENT = "https://rzp.io/l/kpyIoCyvD";
export const PROFESSIONAL_DIAMOND_PAYMENT = "https://rzp.io/l/1zQX60AP";


export const CORPORATE_GOLD_PAYMENT = "https://rzp.io/l/m4fSACjK1p";
export const CORPORATE_PLATINUM_PAYMENT = "https://rzp.io/l/bo5pBN1p";
export const CORPORATE_DIAMOND_PAYMENT = "https://rzp.io/l/IYNGI7s";

export const DOMAIN_COBRAND_MAPPING = [
    {
        id: 1,
        domain: "expresstds.com",
        sub_domain: "dev552",
        api_base_url: "https://appdev552.indiatds.com",
        site_name: "Express TDS",
        site_base_path: "/",
        site_logo: "EXTDS",
        utm_source: "exptdsdirect"
    }
]; 

export const CFG_TRACES_CONVERTER_FORM16_PARTA_URL = "https://expresstds.s3.ap-south-1.amazonaws.com/traces-utilities/TRACES-PDF-Converter-Form16PartA.zip";
export const CFG_TRACES_CONVERTER_FORM16_PARTB_URL = "https://expresstds.s3.ap-south-1.amazonaws.com/traces-utilities/TRACES-PDF-Converter-Form16PartB.zip";
export const CFG_TRACES_CONVERTER_FORM16A_URL = "https://expresstds.s3.ap-south-1.amazonaws.com/traces-utilities/TRACES-PDF-Converter-Form16A.zip";
export const CFG_TRACES_CONVERTER_FORM27D_URL = "";

/* Online Challan Payment via Connector */
export const CFG_MODE_OF_PAYMENT = [
  { value: "", label: "Select" },
  { value: "NET_BANKING", label: "Net Banking" },
  { value: "DEBIT_CARD", label: "Debit Card" },
  { value: "PAY_AT_BANK_COUNTER", label: "Pay at Counter" },
  { value: "PAYMENT_GATEWAY", label: "Payment Gateway" },
  { value: "RTGS_NEFT", label: "RTGS/NEFT" },
];

export const CFG_NETBANKING_BANKS = [
  { value: "", label: "Select" },
  { value: "UTIB", label: "Axis Bank" },
  { value: "BARB", label: "Bank of Baroda" },
  { value: "BKID", label: "Bank Of India" },
  { value: "MAHB", label: "Bank Of Maharashtra" },
  { value: "CNRB", label: "Canara Bank" },
  { value: "CBIN", label: "Central Bank Of India" },
  { value: "CIUB", label: "City Union Bank Limited" },
  { value: "DCBL", label: "DCB Bank" },
  { value: "FDRL", label: "Federal Bank" },
  { value: "HDFC", label: "HDFC Bank" },
  { value: "ICIC", label: "ICIC Bank" },
  { value: "IBKL", label: "IBKL bank" },
  { value: "IDIB", label: "Indian Bank" },
  { value: "IOBA", label: "Indian Overseas Bank" },
  { value: "INDB", label: "IndusInd Bank" },
  { value: "JAKA", label: "Jammu and Kashmir Bank Limited" },
  { value: "KVBL", label: "Karur Vysya Bank" },
  { value: "KKBK", label: "Kotak Mahindra Bank" },
  { value: "PSIB", label: "Punjab and Sind Bank" },
  { value: "PUNB", label: "Punjab National Bank" },
  { value: "RATN", label: "RBL Bank Limited" },
  { value: "SIBL", label: "South Indian Bank" },
  { value: "SBIN", label: "State Bank of India" },
  { value: "UCBA", label: "UCO Bank" },
  { value: "UBIN", label: "Union Bank of India" },
];

export const CFG_DEBIT_CARD_BANKS = [
  { value: "", label: "Select" },
  { value: "BKID", label: "Bank Of India" },
  { value: "CNRB", label: "Canara Bank" },
  { value: "HDFC", label: "HDFC Bank" },
  { value: "ICIC", label: "ICICI Bank" },
  { value: "IDIB", label: "Indian Bank" },
  { value: "PUNB", label: "Punjab National Bank" },
  { value: "SBIN", label: "State Bank of India" },
];

export const CFG_PAY_AT_COUNTER_BANKS = [
  { value: "", label: "Select" },
  { value: "CASH", label: "Cash" },
  { value: "CHEQUE", label: "Cheque" },
  { value: "DEMAND_DRAFT", label: "Demand Draft" },
];


export const CFG_PAYMENT_GATEWAY_BANKS = [
  { value: "", label: "Select" },
  { value: "MAHB", label: "Bank Of Maharashtra" },
  { value: "CNRB", label: "Canara Bank" },
  { value: "FDRL", label: "Federal Bank" },
  { value: "HDFC", label: "HDFC Bank" },
  { value: "KKBK", label: "Kotak Mahindra Bank" },
  { value: "SBIN", label: "State Bank of India" },
];

export const CFG_MAJOR_HEAD = [
  { value: "", label: "Select" },
  { value: "COMPANY", label: "(0020) Corporation Tax (Companies)" },
  {
    value: "OTHER_THAN_COMPANY",
    label: "(0021) Income Tax (Other than Companies)",
  },
];

export const FVU_PROCESS_CSI_FILE_TYPE = 'EFILING'; // EFILING | OLTAS

export const RAZORPAY_KEY = 'rzp_test_ZestYvGC5Gz2nv';

export const CFG_PASSWORD_ENCRYPTION_KEY = 'D)TW:83yDo-|QR~z+jzd_u-50!?1ASr6';
export const CFG_PASSWORD_ENCRYPTION_IV = '1234567812345678';

export const CFG_CONNECTOR_VERSION_URL = 'https://uti.kdkupdate.com/express-connector-2/update.json';

export const CFG_SALARY_HEADER_ROW_COLUMNS = {
  'employee': false,
  'name': false,
  'pan': false,
  'salary': false,
  'salaryid': false,
  'regime': false,
  'deduction': false,
  '80c': false,
  'profits in lieu': false,
  'employment': false,
  'employment period': false,
  'dob': false,
}
export const CFG_SALARY_HEADER_ROW_ALLOWED_COLUMNS_COUNT = 3;
export const CFG_MAX_SALARY_ROW_CHECK = 15;

/* Connector local ports */
export const CFG_CONNECTOR_LOCAL_PORTS = [55211, 25511, 23490, 12557, 32557];
