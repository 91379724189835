import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleSuccess, handleError } from './responseHandler';
import { getConfig, getConfigForDeleteHttpVerb } from './common';


export async function getDeducteesTransactionsV2(data) {
  const url = `${BASE_URL}/api/v2/transaction/list`; 
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}

export async function getDeducteesTransactionsV3(data) {
  const url = `${BASE_URL}/api/v2/transaction/deductee-wise`; 
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}


export async function getDeducteesSectionV3Summary(data) {
  const url = `${BASE_URL}/api/v2/transaction/deductee-section-summary`; 
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}

export async function getTransactionV3Summary(data) {
  const url = `${BASE_URL}/api/v2/transaction/summary-list`; 
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}


export async function getDeducteesTransactionsMismatchReports(data) {
  const url = `${BASE_URL}/api/v2/transaction/mismatch-report`; 
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}

export async function getCorrectionDeducteesTransactions(data) {
  const url = `${BASE_URL}/api/v1/correction/transaction/list`;
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}

export async function getCorrectionDeducteeTransactionSummary(data) {
  const url = `${BASE_URL}/api/v1/correction/transaction/summary`;
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}

export async function getDeducteeTransactionDetail(getParams) {
  const url = `${BASE_URL}/api/v1/transaction/details${getParams}`;

  try {
    const deducteeTransactionData = await axios.get(url, getConfig());
    if (Number(deducteeTransactionData.data.status) !== 1) {
      throw (deducteeTransactionData.data.message);
    }

    return deducteeTransactionData.data.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getCorrectionDeducteeTransactionDetail(getParams) {
  const url = `${BASE_URL}/api/v1/correction/transaction/details${getParams}`;

  try {
    const deducteeCorrectionTransactionData = await axios.get(url, getConfig());
    if (Number(deducteeCorrectionTransactionData.data.status) !== 1) {
      throw (deducteeCorrectionTransactionData.data.message);
    }

    return deducteeCorrectionTransactionData.data.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function updateDeducteeTransaction(data) {
  const url = `${BASE_URL}/api/v1/transaction`;

  try {
    const deductorTransactionData = await axios.put(url, data, getConfig());
    if (Number(deductorTransactionData.data.status) !== 1) {
      throw (deductorTransactionData.data.message);
    }

    return deductorTransactionData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function unLinkChallanFromDeducteeTransaction(data) {
  const url = `${BASE_URL}/api/v1/transaction/challan`;

  try {
    const deductorTransactionData = await axios.put(url, data, getConfig());
    if (Number(deductorTransactionData.data.status) !== 1) {
      throw (deductorTransactionData.data.message);
    }

    return deductorTransactionData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function updateCorrectionDeducteeTransaction(data) {
  const url = `${BASE_URL}/api/v1/correction/transaction`;

  try {
    const deductorTransactionData = await axios.put(url, data, getConfig());
    if (Number(deductorTransactionData.data.status) !== 1) {
      throw (deductorTransactionData.data.message);
    }

    return deductorTransactionData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function addDeducteeTransaction(data) {
  const url = `${BASE_URL}/api/v1/transaction`;

  try {
    if(data.challan_id==='0' || data.challan_id===''){
      data.challan_id=null;
    }
    const deductorTransactionData = await axios.post(url, data, getConfig());
    if (Number(deductorTransactionData.data.status) !== 1) {
      throw (deductorTransactionData.data.message);
    }

    return deductorTransactionData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function addCorrectionDeducteeTransaction(data) {
  const url = `${BASE_URL}/api/v1/correction/transaction`;

  try {
    if(data.challan_id==='0' || data.challan_id===''){
      data.challan_id=null;
    }
    const deductorTransactionData = await axios.post(url, data, getConfig());
    if (Number(deductorTransactionData.data.status) !== 1) {
      throw (deductorTransactionData.data.message);
    }

    return deductorTransactionData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function deleteDeducteeTransaction(deducteeTransactionIds,deductorId,returnId) {
  const url = `${BASE_URL}/api/v1/transaction`;
  try {
    const payload = {
      deductee_transaction_id: deducteeTransactionIds,
      deductor_id: deductorId,
      return_id: returnId,
    }
    const deductorData = await axios.delete(url, getConfigForDeleteHttpVerb(payload));
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return deductorData.data.data;
  } catch (err) {
    throw handleError(err);
  }
}


export async function sendTransactionEmail(data) {
  const url = `${BASE_URL}/api/v2/transaction/mail`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    
    return result;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getSectionWiseTransactionV2(data) {
  const url = `${BASE_URL}/api/v2/transaction/section-wise`; 
  try {
      const result = await axios.post(url, data, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return handleSuccess(result.data);
  } catch (err) {
      throw handleError(err);
  }
}


