import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BASE_PATH } from '../../app/constants';
import './UserList.css';
import "../../components/profile/Profile.css"
import "./ManageClients.scss"
import queryString from 'query-string';
import ListClients from "./ListClients"
import ListAndManageClients from "./ListAndManageClients"
import { gocallApi } from '../../api/issueCertificateAPI';
import ShowAlert from '../../components/ShowAlert';

const ManageClients = ({ history }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const userId = params?.user_id ?? "";
  const userName = params?.usr ?? "";
  const [activeTab, setActiveTab] = useState(params?.tab ?? 1);
  const [summaryCount, setSummaryCount] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const getSummaryAssignedClient = async () => {
    let url = `/api/v1/user/deductor/summary`
    let payload = {
      user_id: userId,
    }
    try {
      const result = await gocallApi("post", url, payload)
      setSummaryCount(result?.data ?? [])
    } catch (err) {
      setError(err.message);
    }
  }
  useEffect(() => {

    getSummaryAssignedClient();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <div className="container mt-2">

        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-4 col-12">
            <a href="Customer_care_user_action.html" target="_self" className="sd_usermanage_backbtn">
              <Link className="backtoclr" to={`${BASE_PATH}user-management`}>
                &lt; Back
              </Link>
            </a>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-8 col-12">
            <h4 className="sd_usermanage_manageassing_title">
              Manage and Assign New Deductors
            </h4>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-xl-7 col-lg-7 col-md-7 col-7 pb-2">
            <div className="d-flex justify-content-start">
              <nav className="sd_usermanage_nav">
                <div className="nav nav-tabs sd_usermanage_tabs" id="nav-tab" role="tablist">

                  <a className={`nav-item nav-link sd_usermanage_tabbox ${activeTab === "1" ? "active" : ""}`}
                    data-toggle="tab"
                    href="#1"
                    role="tab"
                    aria-controls="nav-home"
                    onClick={() => {
                      setActiveTab("1")
                      history.push(`${BASE_PATH}user-management/manage-clients?tab=1&user_id=${userId}&usr=${userName}`)
                    }}
                    aria-selected="false">
                    Assigned Deductors ({summaryCount?.assigned ?? 0})
                  </a>
                  <a className={`nav-item nav-link sd_usermanage_tabbox sd_usermanage_borderleft ${activeTab === "2" ? "active" : ""}`}
                    data-toggle="tab" href="#2"
                    role="tab"
                    aria-controls="nav-home"
                    onClick={() => {
                      setActiveTab("2")
                      history.push(`${BASE_PATH}user-management/manage-clients?tab=2&user_id=${userId}&usr=${userName}`)
                    }}
                    aria-selected="false">
                    Unassigned Deductors ({summaryCount?.unassigned ?? 0})
                  </a>
                  <a className={`nav-item nav-link sd_usermanage_tabbox sd_usermanage_borderleft ${activeTab === "3" ? "active" : ""}`}
                    data-toggle="tab"
                    href="#3"
                    role="tab"
                    aria-controls="nav-home"
                    onClick={() => {
                      setActiveTab("3")
                      history.push(`${BASE_PATH}user-management/manage-clients?tab=3&user_id=${userId}&usr=${userName}`)
                    }}
                    aria-selected="false">
                    All Deductors List ({summaryCount?.all_count ?? 0})
                  </a>
                </div>

              </nav>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-5 col-5 text-right">
          <span className="sd_usermanage_zeroclients_ass_textfs usr-label">User:&nbsp;&nbsp;<span style={{fontWeight:'700', color:'#000'}}>{userName}</span></span>
          </div>

          <div className="col-md-12">
            <div className="tab-content" id="nav-tabContent">
              {activeTab === "1" &&
                <div class="tab-pane   active" role="tabpanel" aria-labelledby="nav-home-tab">
                  <ListClients
                  />
                </div>
              }

              {(activeTab === "2" || activeTab === "3") &&
                <div className="tab-pane   active" role="tabpanel" aria-labelledby="nav-home-tab">
                  <ListAndManageClients
                    deducteeSummary={summaryCount}
                    handleSuccess={() => {
                      getSummaryAssignedClient()
                    }}
                  />
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageClients;
