
export const reasonList24Q = [{ id: 'A', value: 'A - Lower Deduction/No Deduction u/s 197', certificate_applicable: true, },
{ id: 'B', value: 'B - Non Deduction u/s 197A', certificate_applicable: true, },
{ id: 'C', value: 'C - Higher Rate due to non availability of PAN', certificate_applicable: false, }];



export const reasonList26Q = [{ id: 'A', value: 'A - Lower Deduction/No Deduction u/s 197', certificate_applicable: true, },
{ id: 'B', value: 'B - No deduction u/s 197 A', certificate_applicable: true, },
{ id: 'C', value: 'C - Higher rate (Valid PAN not available)', certificate_applicable: false, },
{ id: 'D', value: 'D - No/lower deduction on payment made to notified person', certificate_applicable: false, },
{ id: 'E', value: 'E - No deduction on payment made to person referred in circular', certificate_applicable: false, },
{ id: 'M', value: 'M - No/lower deduction under second provision of 194N', certificate_applicable: false, },
{ id: 'N', value: 'N - No deduction -clause  (iii,iv,v)-section 194 N', certificate_applicable: false, },
{ id: 'O', value: 'O - No deduction as per (2A) of 194LBA', certificate_applicable: false, },
{ id: 'R', value: 'R - Deduction on interest income-Senior citizens)', certificate_applicable: false, },
{ id: 'S', value: 'S - Software Providers', certificate_applicable: false, },
{ id: 'T', value: 'T - Transporter', certificate_applicable: false, },
{ id: 'Y', value: 'Y - Threshold limit', certificate_applicable: false, },
{ id: 'Z', value: 'Z - No deduction u/s 197A(1F)', certificate_applicable: false, },

];

export const reasonList26QExtraFields = [{ id: 'A', value: 'A - Lower Deduction/No Deduction u/s 197', certificate_applicable: true, },
{ id: 'B', value: 'B - No deduction u/s 197 A', certificate_applicable: true, },
{ id: 'C', value: 'C - Higher rate (Valid PAN not available)', certificate_applicable: false, },
{ id: 'D', value: 'D - No/lower deduction on payment made to notified person', certificate_applicable: false, },
{ id: 'E', value: 'E - No deduction on payment made to person referred in circular', certificate_applicable: false, },
{ id: 'M', value: 'M - No/lower deduction under second provision of 194N', certificate_applicable: false, },
{ id: 'N', value: 'N - No deduction -clause  (iii,iv,v)-section 194 N', certificate_applicable: false, },
{ id: 'O', value: 'O - No deduction as per (2A) of 194LBA', certificate_applicable: false, },
{ id: 'P', value: 'P - No deduction payment of dividend made to a business trust', certificate_applicable: false, },
{ id: 'Q', value: 'Q - No deduction in view of payment ', certificate_applicable: false, },
{ id: 'R', value: 'R - Deduction on interest income-Senior citizens)', certificate_applicable: false, },
{ id: 'S', value: 'S - Software Providers', certificate_applicable: false, },
{ id: 'T', value: 'T - Transporter', certificate_applicable: false, },
{ id: 'U', value: 'U - higher rate in view of section 206AB', certificate_applicable: false, },
{ id: 'Y', value: 'Y - Threshold limit', certificate_applicable: false, },
{ id: 'Z', value: 'Z - No deduction u/s 197A(1F)', certificate_applicable: false, },

];


export const reasonList27EQ = [{ id: 'A', value: 'A - Lower Collection', certificate_applicable: true, },
{ id: 'B', value: 'B - Non Collection', certificate_applicable: false, },
{ id: 'C', value: 'C - Higher Rate due to non availability of PAN', certificate_applicable: false, },
{ id: 'D', value: 'D - No collection on first proviso of 1G', certificate_applicable: false, },
{ id: 'E', value: 'E - No collection on fourth proviso of 1G', certificate_applicable: false, },
{ id: 'F', value: 'F - No collection on fifth proviso of 1H', certificate_applicable: false, },
{ id: 'G', value: 'G - No collection on second proviso of 1H', certificate_applicable: false, },
{ id: 'H', value: 'H - No collection on A,B,C sub clause', certificate_applicable: false, }];

export const reasonList27EQExtraFields = [{ id: 'A', value: 'A - Lower Collection', certificate_applicable: true, },
{ id: 'B', value: 'B - Non Collection', certificate_applicable: false, },
{ id: 'C', value: 'C - Higher Rate due to non availability of PAN', certificate_applicable: false, },
{ id: 'D', value: 'D - No collection on first proviso of 1G', certificate_applicable: false, },
{ id: 'E', value: 'E - No collection on fourth proviso of 1G', certificate_applicable: false, },
{ id: 'F', value: 'F - No collection on fifth proviso of 1H', certificate_applicable: false, },
{ id: 'G', value: 'G - No collection on second proviso of 1H', certificate_applicable: false, },
{ id: 'H', value: 'H - No collection on A,B,C sub clause', certificate_applicable: false, },
{ id: 'I', value: 'I - Higher rate in view of section 206CCA', certificate_applicable: false, },
];

export const reasonList27Q = [{ id: 'A', value: 'A - In case of lower deduction or no deduction on account of certificate under section 197', certificate_applicable: true, },
{ id: 'B', value: 'B - In case of no deduction on account of declaration under section 197A', certificate_applicable: true, },
{ id: 'C', value: 'C - Higher rate- Valid PAN not Available', certificate_applicable: false, },
{ id: 'S', value: 'S - Software Providers', certificate_applicable: false, },
{ id: 'N', value: 'N - No Deduction as per clause (iii),(iv) (v)-Section 194N', certificate_applicable: false, },
{ id: 'O', value: 'O - No Deduction as per sub-section (2A) of section 194LBA', certificate_applicable: false, },
{ id: 'M', value: 'M - No/Lower deduction under second provision to section 194N', certificate_applicable: false, },
{ id: 'G', value: 'G - No deduction as per clause (a) or (b) of sub-section (1D) of section 197A', certificate_applicable: false, },
{ id: 'I', value: 'I - No Deduction as per sub-section (2) of 196D 2021-22 onwards and 196D(1A)', certificate_applicable: false, },
{ id: 'H', value: 'H - No Deduction as per sub-section (1A) of section 196D as per clause 10 (4D)', certificate_applicable: false, },
{ id: 'J', value: 'J - Deduction is at a higher rate as per 206AB', certificate_applicable: false, },
{ id: 'Y', value: 'Y - Amount paid/credited to the vendor/party has not exceeded the threshold limit-194B-A and 194BA-P', certificate_applicable: false, },
];

export const getReasonList = (headerDataDetails) => {
    const fyFromHeader = parseInt(headerDataDetails.financial_year_formatted.split('-')[0]);
    if (headerDataDetails.form_type === '24Q') {
        return reasonList24Q;
    }
    if (headerDataDetails.form_type === '26Q') {
        if ((fyFromHeader === 2021 && headerDataDetails.quarter >= 'Q2') || fyFromHeader > 2021) {
            return reasonList26QExtraFields
        } else {
            return reasonList26Q;
        }
    }
    if (headerDataDetails.form_type === '27EQ') {
        if ((fyFromHeader === 2021 && headerDataDetails.quarter >= 'Q2') || fyFromHeader > 2021) {
            if(isJRemarkApplicable(fyFromHeader, headerDataDetails.quarter)){ // the code will be applicable after September 30, 2024
                reasonList27EQExtraFields.push({ id: 'J', value: 'J- No/Lower Collection in view of notification U/s 206C(12)', certificate_applicable: true, })
            } 
            return reasonList27EQExtraFields;
        } else {
            return reasonList27EQ;
        }
    }
    if (headerDataDetails.form_type === '27Q') {
        return reasonList27Q;
    }
    return [];
}

export const reasonTotalList24Q = [
    { value: 'A', label: 'A - Lower Deduction/No Deduction u/s 197', certificate_applicable: true, },
    { value: 'B', label: 'B - Non Deduction u/s 197A', certificate_applicable: true, },
    { value: 'C', label: 'C - Higher Rate due to non availability of PAN', certificate_applicable: false, }
];
export const reasonTotalListFor26Q = [
    { value: 'A', label: 'A - Lower Deduction/No Deduction u/s 197', certificate_applicable: true, },
    { value: 'B', label: 'B - No deduction u/s 197 A', certificate_applicable: true, },
    { value: 'C', label: 'C - Higher rate (Valid PAN not available)', certificate_applicable: false, },
    { value: 'D', label: 'D - No/lower deduction on payment made to notified person', certificate_applicable: false, },
    { value: 'E', label: 'E - No deduction on payment made to person referred in circular', certificate_applicable: false, },
    { value: 'M', label: 'M - No/lower deduction under second provision of 194N', certificate_applicable: false, },
    { value: 'N', label: 'N - No deduction -clause  (iii,iv,v)-section 194 N', certificate_applicable: false, },
    { value: 'O', label: 'O - No deduction as per (2A) of 194LBA', certificate_applicable: false, },
    { value: 'R', label: 'R - Deduction on interest income-Senior citizens)', certificate_applicable: false, },
    { value: 'S', label: 'S - Software Providers', certificate_applicable: false, },
    { value: 'T', label: 'T - Transporter', certificate_applicable: false, },
    { value: 'Y', label: 'Y - Threshold limit', certificate_applicable: false, },
    { value: 'Z', label: 'Z - No deduction u/s 197A(1F)', certificate_applicable: false, },
    { value: 'P', label: 'P - No deduction payment of dividend made to a business trust', certificate_applicable: false, },
    { value: 'Q', label: 'Q - No deduction in view of payment ', certificate_applicable: false, },
    { value: 'U', label: 'U - higher rate in view of section 206AB', certificate_applicable: false, },
]
export const reasonTotalList27Q = [
    { value: 'A', label: 'A - In case of lower deduction or no deduction on account of certificate under section 197', certificate_applicable: true, },
    { value: 'B', label: 'B - In case of no deduction on account of declaration under section 197A', certificate_applicable: true, },
    { value: 'C', label: 'C - Higher rate- Valid PAN not Available', certificate_applicable: false, },
    { value: 'S', label: 'S - Software Providers', certificate_applicable: false, },
    { value: 'N', label: 'N - No Deduction as per clause (iii),(iv) (v)-Section 194N', certificate_applicable: false, },
    { value: 'O', label: 'O - No Deduction as per sub-section (2A) of section 194LBA', certificate_applicable: false, },
    { value: 'M', label: 'M - No/Lower deduction under second provision to section 194N', certificate_applicable: false, },
    { value: 'P', label: 'P - If lower deduction or no deduction is in view of notification issued under sub-section (1F) of section 197A', certificate_applicable: false, },
    { value: 'G', label: 'G - No deduction as per clause (a) or (b) of sub-section (1D) of section 197A', certificate_applicable: false, },
    { value: 'I', label: 'I - No Deduction as per sub-section (2) of 196D 2021-22 onwards and 196D(1A)', certificate_applicable: false, },
    { value: 'H', label: 'H - No Deduction as per sub-section (1A) of section 196D as per clause 10 (4D)', certificate_applicable: false, },
    { value: 'J', label: 'J - Deduction is at a higher rate as per 206AB', certificate_applicable: false, },
    { value: 'Y', label: 'Y - Amount paid/credited to the vendor/party has not exceeded the threshold limit-194B-A and 194BA-P', certificate_applicable: false, },
];
export const reasonTotalList27EQ = [
    { value: 'A', label: 'A - Lower Collection', certificate_applicable: true, },
    { value: 'B', label: 'B - Non Collection', certificate_applicable: false, },
    { value: 'C', label: 'C - Higher Rate due to non availability of PAN', certificate_applicable: false, },
    { value: 'D', label: 'D - No collection on first proviso of 1G', certificate_applicable: false, },
    { value: 'E', label: 'E - No collection on fourth proviso of 1G', certificate_applicable: false, },
    { value: 'F', label: 'F - No collection on fifth proviso of 1H', certificate_applicable: false, },
    { value: 'G', label: 'G - No collection on second proviso of 1H', certificate_applicable: false, },
    { value: 'H', label: 'H - No collection on A,B,C sub clause', certificate_applicable: false, },
    { value: 'I', label: 'I - Higher rate in view of section 206CCA', certificate_applicable: false, },
    { value: 'J', label: 'J- No/Lower Collection in view of notification U/s 206C(12)', certificate_applicable: true, },// the code will be applicable after September 30, 2024
];

export const getReasonListV2 = (headerDataDetails, nature_of_payment_id, toggleAllOption) => {
    const fyFromHeader = parseInt(headerDataDetails.financial_year_formatted.split('-')[0]);

    function getFormWiseReasonList(form, lessCode, moreCodes, flag = null) {
        const options = {
            dropdownLess: [],
            dropdownMore: []
        }

        let totalList = [{ value: "", label: "Select" }];
        if (form === '24Q') {
            totalList = reasonTotalList24Q;
        } else if (form === '26Q') {
            totalList = reasonTotalListFor26Q;
        } else if (form === '27Q') {
            totalList = reasonTotalList27Q;
        } else if (form === '27EQ') {
            totalList = reasonTotalList27EQ;
        }

        totalList.forEach((item) => {
            if (lessCode.includes(item.value)) {
                options.dropdownLess.push(item);
            }
            if (moreCodes.includes(item.value)) {
                options.dropdownMore.push(item);
            }
        });

        if (flag === 'ALL') {
            options.dropdownLess = totalList;
            options.dropdownMore = totalList;
        }

        if (flag === null) {
            options.dropdownLess.push({ value: "showmore", label: "Show More" });
            options.dropdownMore.push({ value: "showless", label: "Show Less" });
        }

        return options;
    }

    let options = {};

    if (nature_of_payment_id === '' || nature_of_payment_id === undefined || headerDataDetails.form_type === undefined) return [];

    let natureOfPaymentId = parseInt(nature_of_payment_id);

    if (headerDataDetails.form_type === '24Q') {
        switch (natureOfPaymentId) {
            //194P
            case 63: options = getFormWiseReasonList('24Q', ['C'], ['C'], 'SAME'); break;
            //192A, 192B, 192C,
            case 23:
            case 24:
            case 56:
            default:
                options = getFormWiseReasonList('24Q', ['A', 'B'], ['A', 'B', 'C']); break;
        }
    } else if (headerDataDetails.form_type === '26Q') {
        const allCodes = ['A', 'B', 'C', 'D', 'E', 'M', 'N', 'O', 'R', 'S', 'T', 'Y', 'Z', 'P', 'Q', 'U'];
        switch (natureOfPaymentId) {
            //193
            case 1: options = getFormWiseReasonList('26Q', ['A', 'B'], ['A', 'B', 'E', 'C', 'U']); break;
            //194
            case 2: options = getFormWiseReasonList('26Q', ['A', 'B', 'Y', 'P'], ['A', 'B', 'Y', 'P', 'E', 'C', 'U']); break;

            //194DA, 194I(a), 
            case 6:
            case 33:
            case 41: options = getFormWiseReasonList('26Q', ['B'], ['B', 'E', 'C', 'U']); break;

            //194EE
            case 7: options = getFormWiseReasonList('26Q', ['B', 'Y'], ['B', 'Y', 'E', 'C', 'U']); break;

            //194LA, 194G, 194H, 194I, 194I(b)
            case 8:
            case 31:
            case 32:
            case 48:
            case 52: options = getFormWiseReasonList('26Q', ['A', 'B'], ['A', 'B', 'Y', 'E', 'C', 'U']); break;

            //194D
            case 28: if (fyFromHeader >= 2024) {
                options = getFormWiseReasonList('26Q', ['A', 'Y', 'Z'], ['A', 'Y', 'Z', 'E', 'C', 'U']); break;
            } else {
                options = getFormWiseReasonList('26Q', ['A', 'Y'], ['A', 'Y', 'E', 'C', 'U']); break;
            }

            //194A
            case 25: options = getFormWiseReasonList('26Q', ['A', 'B', 'Y', 'Z', 'R', 'D', 'Q'], ['A', 'B', 'Y', 'Z', 'R', 'D', 'Q', 'E', 'C', 'U']); break;
            //194B
            case 26: options = getFormWiseReasonList('26Q', ['Y'], ['Y', 'C', 'E']); break;
            //194C
            case 27: options = getFormWiseReasonList('26Q', ['A', 'B', 'Y', 'T'], ['A', 'B', 'Y', 'T', 'E', 'C', 'U']); break;
            //194K
            case 35: options = getFormWiseReasonList('26Q', ['A', 'B', 'Y'], ['A', 'B', 'Y', 'E', 'C', 'U']); break;

            //194J, 194J(a), 194J(b)
            case 34:
            case 50:
            case 51: options = getFormWiseReasonList('26Q', ['A', 'B', 'Y', 'S'], ['A', 'B', 'Y', 'S', 'E', 'C', 'U']); break;

            //194Q
            case 62: 
            if (fyFromHeader >= 2024 && headerDataDetails?.quarter === "Q3") {
                options = getFormWiseReasonList('26Q', ['S'], ['A', 'B', 'S', 'E', 'C', 'U', 'E']); break;
            } else {
                options = getFormWiseReasonList('26Q', ['S'], ['S', 'E', 'C', 'U', 'E']); break;
            }            

            //194R, 194S
            case 74:
            case 75: options = getFormWiseReasonList('26Q', ['C', 'U'], ['C', 'U', 'E']); break;

            //194B-P
            case 76: options = getFormWiseReasonList('26Q', ['Y'], ['Y', 'C', 'E']); break;

            //194F, 194IC
            case 30:
            case 47: options = getFormWiseReasonList('26Q', ['C', 'U'], ['C', 'U'], 'SAME'); break;

            //194R-p, 194S-P
            case 77:
            case 78: options = getFormWiseReasonList('26Q', ['C', 'U'], ['C', 'U', 'E']); break;

            //194LBA
            case 4: options = getFormWiseReasonList('26Q', ['O', 'U', 'C'], allCodes); break;

            // 194LBA(a)
            case 54: options = getFormWiseReasonList('26Q', ['A', 'O', 'U', 'C'], allCodes); break;

            //194LBB, 194LBA(b)
            case 42:
            case 55: options = getFormWiseReasonList('26Q', ['A', 'U', 'C'], allCodes); break;

            //194LBC, 194O
            case 46:
            case 61: options = getFormWiseReasonList('26Q', ['A', 'C'], allCodes); break;

            //194N, 
            case 36: options = getFormWiseReasonList('26Q', ['M', 'N'], ['M', 'N', 'C']); break;

            //194NF, 194NC, 194NFT
            case 53:
            case 81:
            case 82: options = getFormWiseReasonList('26Q', ['M', 'N', 'C'], allCodes); break;

            //194BB
            case 5: options = getFormWiseReasonList('26Q', ['Y',], ['Y', 'C', 'E']); break;

            //194B, 194BA, 
            //case 26:
            case 79: options = getFormWiseReasonList('26Q', ['Y', 'U', 'C'], allCodes); break;

            default:
                options = getFormWiseReasonList('26Q', [], [], 'ALL'); break;
        }
    } else if (headerDataDetails.form_type === '27Q') {
        const allCodes = ['A', 'B', 'C', 'S', 'N', 'O', 'M', 'P', 'G', 'I', 'H', 'J', 'Y'];
        switch (natureOfPaymentId) {
            //195 codes
            case 3:
            case 66:
            case 67:
            case 69:
            case 70:
            case 72: options = getFormWiseReasonList('27Q', ['A', 'B', 'S', 'J', 'C'], allCodes); break;

            // 195 - Payment of interest
            case 71: if (fyFromHeader >= 2024) {
                options = getFormWiseReasonList('27Q', ['P'], allCodes); break;
            } else {
                options = getFormWiseReasonList('27Q', ['A', 'B', 'S', 'J', 'C'], allCodes); break;
            }

            //194LBA
            case 4: options = getFormWiseReasonList('27Q', ['O', 'J', 'C'], allCodes); break;

            //194LBA(a)
            case 54: options = getFormWiseReasonList('27Q', ['A', 'O', 'J', 'C'], allCodes); break;

            //194BB, 194B, 194BA
            case 5:
            case 26:
            case 79: options = getFormWiseReasonList('27Q', ['Y', 'J', 'C'], allCodes); break;

            //194LB, 196A, 196B, 196C
            case 9:
            case 37:
            case 38:
            case 39: options = getFormWiseReasonList('27Q', ['J', 'C'], allCodes); break;

            //194LD
            case 11: options = getFormWiseReasonList('27Q', ['C'], allCodes); break;

            //194N, 194NF, 194NC, 194NFT
            case 36:
            case 53:
            case 81:
            case 82: options = getFormWiseReasonList('27Q', ['M', 'N', 'C'], allCodes); break;

            //196D
            case 40: options = getFormWiseReasonList('27Q', ['J', 'C', 'H', 'I'], allCodes); break;

            //194LBB, 194LBA(b)
            case 42:
            case 55: options = getFormWiseReasonList('27Q', ['A', 'J', 'C'], allCodes); break;

            //194LBC, 194LBC(c)
            case 46:
            case 64: options = getFormWiseReasonList('27Q', ['A', 'C'], allCodes); break;

            //196D(1A)
            case 65: options = getFormWiseReasonList('27Q', ['H', 'I', 'J', 'C'], allCodes); break;

            //194LC, 194LC (2)(i) and (ia), 194LC (2)(ib), 194LC (2)(ic)
            case 10:
            case 83:
            case 84:
            case 85: options = getFormWiseReasonList('27Q', ['J'], allCodes); break;

            //192A
            case 41: options = getFormWiseReasonList('27Q', [], [], 'ALL'); break;

            default:
                options = getFormWiseReasonList('27Q', [], [], 'ALL'); break;
        }

    } else if (headerDataDetails.form_type === '27EQ') {
        switch (natureOfPaymentId) {
            //6CA,6AB,6CA,6CD,6CE,6CF,6CG,6CH,6CI,6CJ,6CK,6CL,6CM,6CN,6CO
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 43:
            case 44:
            case 45:
            case 57: {
                if(isJRemarkApplicable(fyFromHeader, headerDataDetails.quarter)) options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'I', 'J'], ['A', 'B', 'C', 'I', 'J'], 'SAME');
                else options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'I'], ['A', 'B', 'C', 'I'], 'SAME'); 
                break;
            }

            //6CP, 6CQ
            case 58:
            case 59: {
                if(isJRemarkApplicable(fyFromHeader, headerDataDetails.quarter)) options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'D', 'E', 'F', 'I', 'J'], ['A', 'B', 'C', 'D', 'E', 'F', 'I', 'J'], 'SAME');
                else options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'D', 'E', 'F', 'I'], ['A', 'B', 'C', 'D', 'E', 'F', 'I'], 'SAME');
                break;
            }
            case 60: {
                if(isJRemarkApplicable(fyFromHeader, headerDataDetails.quarter)) options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'G', 'H', 'I', 'J'], ['A', 'B', 'C', 'G', 'H', 'I', 'J'], 'SAME'); 
                else options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'G', 'H', 'I'], ['A', 'B', 'C', 'G', 'H', 'I'], 'SAME'); 
                break;
            }
            case 86: {
                if(isJRemarkApplicable(fyFromHeader, headerDataDetails.quarter)) options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'I', 'J'], ['A', 'B', 'C', 'I', 'J'], 'SAME'); 
                else options = getFormWiseReasonList('27EQ', ['A', 'B', 'C', 'I'], ['A', 'B', 'C', 'I'], 'SAME'); 
                break;
            }
            default:
                options = getFormWiseReasonList('27EQ', [], [], 'ALL'); break;
        }
    }

    let dorpDownOptions = toggleAllOption ? options?.dropdownLess : options?.dropdownMore;
    dorpDownOptions = [{ value: "", label: "Select" }, ...dorpDownOptions];
    return dorpDownOptions;
}

const isJRemarkApplicable = (FY, quarter) => {
    if((FY === 2024 && quarter >= 'Q3') || FY > 2024){
        return true;
    }else{
        return false;
    }
}