export const salaryDetailsInitializeData = {
    save_type: 'new',
    salary_id: '',
    deductor_id: 0,
    deductee_id: '',
    deductee_name: '',
    salary_category: '',
    is_government_deductor: false,
    financial_year: 0,
    employment_period_from: '',
    employment_period_to: '',
    tax_regime: 'OLD',
    salary_amtp: 0,
    perquisites_amtp: 0,
    profit_in_lieu_amtp: 0,
    total_gross_salary_amtp: 0,
    salary_breakup_amtp: {
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "1": 0,
        "2": 0,
        "3": 0
    },
    total_allowance_exempt_amtp: 0,
    allowances: [{
        allowance_type_id: 0,
        exempt_amtp: 0,
        allowance_details: null,
    }],
    standard_deduction_amtp: 0,
    ent_allowance_amtp: 0,
    professional_tax_amtp: 0,
    total_deduction_amtp: 0,
    previous_employer: false,
    previous_employer_amount_received_amtp: 0,
    previous_employer_tds_amtp: 0,
    net_salary_amtp: 0
};

export const otherIncomeInitializeData = {
    let_out_amtp: 0,
    let_out_breakup: null,
    self_occupied_amtp: 0,
    self_occupied_breakup: null,
    total_house_property_income_amtp: 0,
    total_other_income_offered_amtp: 0,
    other_income_offered_details: [{
        particular_code: "",
        amount_amtp: 0
    }],
    total_gross_income_amtp: 0,
    total_deduction_vi_a_amtp: 0,
    deduction_vi_a_details: [{
        code: "",
        gross_amount_amtp: 0,
        deductible_amount_amtp: 0
    }],
    net_taxable_income: {
        total_net_taxable_income_amtp: 0,
        relief_u_s_89_amtp: 0,
        tds_ded_by_current_emp_amtp: 0,
        tds_tcs_192_2b_amtp: 0,
        total_tax_computed_amtp: 0,
        excess_short_tds_amtp: 0,
        tax_computed: {
            income_tax_amtp: 0,
            rebate_us_87_a_amtp: 0,
            surcharge_amtp: 0,
            health_edu_cess_amtp: 0,
            relief_us_89_amtp: 0,
            tax_payable_amtp: 0
        }
    },
};

export const otherInitializeData = {
    whether_total_rent_greater_onelakh: 0,
    landlord_details: [{
        name_of_landlord: '',
        pan_of_landlord: '',
        rent_amount_amtp: 0
    }],
    whether_interest_paid_to_lender: 0,
    lender_details: [{
        name_of_lender: '',
        pan_of_lender: '',
        interest_amount_amtp: 0
    }],
    whether_amtrec_from_superannuation_fund: 0,
    fund_name: '',
    fund_from: '',
    fund_to: '',
    fund_received_amtp: 0,
    tax_rate_b100: 0,
    tds_deducted_amtp: 0,
};