
export const maxLimit80C = 150000;
export const maxLimit80CCD_1B = 50000;
export const maxLimit80CCD_2 = 200000;
export const maxLimit80DForSelfAndFamilySenior = 50000;
export const maxLimit80DForSelfAndFamily = 25000;
export const maxLimit80DForParentsSeniorCitizen = 50000;
export const maxLimit80DForParents = 25000;
export const maxLimitPreventiveHealthCheckup = 5000;
export const maxLimit80TTA = 10000;

// Higher Deduction minimum slab
export const HIGHER_DEDUCTION_MIN_LIMIT = 250000;
// Higher Deduction tax rate
export const HIGHER_DEDUCTION_TAX_RATE = 20;

export const PanDefaultValue = ['PANAPPLIED', 'PANINVALID', 'PANNOTAVBL']


export let available_for_80C = 0;
export let available_for_deduction = 0;


// -- Calculation Rules
export const calculateTotalDeductionUnderChapter6Aa = (deduction_vi_a_details, salary_category, total_gross_income_amtp) => {

    available_for_80C = maxLimit80C;
    available_for_deduction = total_gross_income_amtp;
    let total_deduction_vi_a_amtp = 0;

    deduction_vi_a_details.map((data, index) => {
        if (data.code) {

            let { deductible, qualifying } = computeByType(data, data.code, salary_category);

            deduction_vi_a_details[index].deductible_amount_amtp = deductible;
            total_deduction_vi_a_amtp = parseFloat(total_deduction_vi_a_amtp) + parseFloat(deductible);

            if (qualifying) {
                deduction_vi_a_details[index].qualifying_amtp = qualifying;
            }

            available_for_deduction = parseFloat(available_for_deduction) - parseFloat(deductible);
            available_for_deduction = parseFloat(available_for_deduction) >= 0 ? available_for_deduction : 0;

        }
        return false;
    });

    return [deduction_vi_a_details, total_deduction_vi_a_amtp];

}


export const computeByType = (deduction, particular, salary_category) => {

    let deduction_amtp = parseInt(deduction.gross_amount_amtp) > 0 ? deduction.gross_amount_amtp : 0;


    // 80CCH pr 80CCH(1)
    if (particular === '80CCH' || particular === '80CCH_1') {
        return { deductible: deduction_amtp };
    }


    // 80C + 80CCC + 80CCD(1) - We need to maintain available limit for 80C sections
    if (particular === '80C' || particular === '80CCC' || particular === '80CCD_1') {
        let { deductible } = computeGeneric(deduction_amtp, available_for_80C);
        available_for_80C = available_for_80C - deductible;
        available_for_80C = available_for_80C <= 0 ? 0 : available_for_80C;
        return { deductible: deductible };
    }

    // 80CCD(1B)
    if (particular === '80CCD_1B') {
        let { deductible } = computeGeneric(deduction_amtp, maxLimit80CCD_1B);
        return { deductible: deductible };
    }

    // 80CCD(2) OR 80E - No Upper Limit
    if (particular === '80CCD_2') {
        let { deductible } = computeGeneric(deduction_amtp, -1);
        return { deductible: deductible };
    }

    // 80D for self & family
    if (particular === '80D_FOR_SELF_AND_FAMILY') {
        let { deductible } = computeGeneric(deduction_amtp, compute80DLimitForSelfAndFamily(salary_category));
        return { deductible: deductible };
    }

    // 80D for Parents
    if (particular === '80D_FOR_PARENTS') {
        let { deductible } = computeGeneric(deduction_amtp, deduction.is_senior_citizen ? maxLimit80DForParentsSeniorCitizen : maxLimit80DForParents);
        return { deductible: deductible };
    }

    // Preventive Health Checkup
    if (particular === 'PREVENTIVE_HEALTH_CHECKUP') {
        let { deductible } = computeGeneric(deduction_amtp, maxLimitPreventiveHealthCheckup);
        return { deductible: deductible };
    }


    // Sections which have Qualifying Amount

    // 80E, 80G (100%), 80G (Others 100%), and Others - Limit = Gross amount (meaning, no limit). Has qualifying
    if (particular === '80E' || particular === '80G_100' || particular === '80G_OTHER_100' || particular === 'OTHERS') {
        let { deductible, qualifying } = computeGeneric(deduction_amtp, -1, true);
        return { deductible: deductible, qualifying: qualifying };
    }

    // 80G (50%), 80G (Others 50%) - Limit = Gross amount * 50% (meaning, no limit). Has qualifying
    if (particular === '80G_50' || particular === '80G_OTHER_50') {
        let { deductible, qualifying } = computeGeneric(deduction_amtp, (0.5 * parseFloat(deduction_amtp)).toFixed(2), true);
        return { deductible: deductible, qualifying: qualifying };
    }

    // 80TTA - Limit = 10000. Has Qualifying
    if (particular === '80TTA') {
        let { deductible, qualifying } = computeGeneric(deduction_amtp, maxLimit80TTA, true);
        return { deductible: deductible, qualifying: qualifying };
    }

}

export const computeGeneric = (deduction, limit, has_qualifying) => {
    // Deductible = minimum of Available for deduction, Limit, and Gross
    let deductible = Math.min(parseFloat(deduction), parseFloat(available_for_deduction));
    if (limit >= 0) {
        deductible = Math.min(parseFloat(deduction), parseFloat(available_for_deduction), parseFloat(limit));
    }

    // If qualifying is to be computed, then it is min(Gross amount, Upper limit)
    if (has_qualifying) {
        let qualifying = parseFloat(deduction);
        if (limit >= 0) {
            qualifying = Math.min(parseFloat(deduction), parseFloat(limit));
        }
        return { deductible: deductible, qualifying: qualifying };
    }
    else {
        return { deductible: deductible };
    }

}

export const compute80DLimitForSelfAndFamily = (SalaryVO) => {
    let maxLimit80D = SalaryVO === 'S' || SalaryVO === 'O' ? maxLimit80DForSelfAndFamilySenior : maxLimit80DForSelfAndFamily;
    return maxLimit80D;
}



export const calculateTaxComputed = (net_taxable_income, values, financialYear) => {
    function getEmployeePan4thChar(values){ 
        let empPan = "";       
        if((values?.pan ?? "") !== "") {
            empPan = String(values?.pan ?? "").trim();
        }else{
            const panData = String(values.deductee_name).split('-');
            empPan = String(panData?.[1] ?? "").trim();
        }
        return empPan.charAt(3);
    }

    let salary_category = values.salary_category;
    let tax_regime = values.tax_regime;
    let tds_ded_by_current_emp_amtp = parseFloat(net_taxable_income.tds_ded_by_current_emp_amtp) > 0 ? parseFloat(net_taxable_income.tds_ded_by_current_emp_amtp) : 0;    
    let tds_tcs_192_2b_amtp = (financialYear >= 2024) ? (parseFloat(net_taxable_income.tds_tcs_192_2b_amtp) > 0 ? parseFloat(net_taxable_income.tds_tcs_192_2b_amtp) : 0) : 0;
    let previous_employer_tds_amtp = parseFloat(values.previous_employer_tds_amtp) > 0 ? parseFloat(values.previous_employer_tds_amtp) : 0;
    let tds_deducted_amtp = parseFloat(values.other_details.tds_deducted_amtp) > 0 ? parseFloat(values.other_details.tds_deducted_amtp) : 0;
    let total_net_taxable_income_amtp = parseFloat(net_taxable_income.total_net_taxable_income_amtp) > 0 ? parseFloat(net_taxable_income.total_net_taxable_income_amtp) : 0;
    let relief_us_89_amtp = parseFloat(net_taxable_income.relief_u_s_89_amtp) > 0 ? parseFloat(net_taxable_income.relief_u_s_89_amtp) : 0;
    let total_tax_computed_amtp = 0;
    let income_tax_amtp = 0;
    let excess_short_tds_amtp = 0;
    let rebate_us_87_a_amtp = 0;
    let surcharge_amtp = 0;
    let health_edu_cess_amtp = 0;
    let tax_payable_amtp = 0;
    const empPan4thChar = getEmployeePan4thChar(values); 

    //income tax Calculation
    income_tax_amtp = calculateIncomeTax(total_net_taxable_income_amtp, tax_regime, salary_category, "normal", financialYear);

    // But, what if the higher deduction is applicable. Handle that.
    let higher_ded_income_tax = handleHigherDeduction(total_net_taxable_income_amtp, income_tax_amtp, values.pan);
    if (higher_ded_income_tax) income_tax_amtp = higher_ded_income_tax;
    //rebate u/s 87
    if (tax_regime === "NEW" && financialYear >= 2023 ) {
        if (total_net_taxable_income_amtp <= 700000 && !higher_ded_income_tax) {
            rebate_us_87_a_amtp = income_tax_amtp < 25000 ? income_tax_amtp : 25000;
        }else if(total_net_taxable_income_amtp > 700000 && !higher_ded_income_tax && empPan4thChar === "P"){
            rebate_us_87_a_amtp = Math.max(0, income_tax_amtp - (total_net_taxable_income_amtp - 700000));
        }
    } else {
        if (total_net_taxable_income_amtp <= 500000 && !higher_ded_income_tax) {
            rebate_us_87_a_amtp = income_tax_amtp < 12500 ? income_tax_amtp : 12500;
        }
    }

    //surcharge
    surcharge_amtp = calculateSurcharge(total_net_taxable_income_amtp, income_tax_amtp, 'normal', financialYear, tax_regime);


    //marginal relief 
    if (parseFloat(surcharge_amtp) > 0) {
        let marginal_relief = calculateMarginalRelief(total_net_taxable_income_amtp, tax_regime, salary_category, income_tax_amtp, surcharge_amtp, financialYear);
        surcharge_amtp = parseFloat(surcharge_amtp) - parseFloat(marginal_relief);
    }

    // Health & Edu. Cess
    if (!higher_ded_income_tax)
        health_edu_cess_amtp = 4 / 100 * (income_tax_amtp - rebate_us_87_a_amtp + surcharge_amtp);

    //tax payable
    tax_payable_amtp = parseFloat(income_tax_amtp) - parseFloat(rebate_us_87_a_amtp) + parseFloat(surcharge_amtp) + parseFloat(health_edu_cess_amtp) - parseFloat(relief_us_89_amtp);

    //total tax computed
    total_tax_computed_amtp = tax_payable_amtp;

    //excess amount
    excess_short_tds_amtp = (parseFloat(tds_ded_by_current_emp_amtp) + parseFloat(tds_tcs_192_2b_amtp) + parseFloat(previous_employer_tds_amtp) + parseFloat(tds_deducted_amtp)) - parseFloat(total_tax_computed_amtp);

    //set value
    net_taxable_income.tax_computed.income_tax_amtp = parseFloat(income_tax_amtp).toFixed(2);
    net_taxable_income.tax_computed.rebate_us_87_a_amtp = parseFloat(rebate_us_87_a_amtp).toFixed(2);
    net_taxable_income.tax_computed.surcharge_amtp = parseFloat(surcharge_amtp).toFixed(2);
    net_taxable_income.tax_computed.health_edu_cess_amtp = parseFloat(health_edu_cess_amtp).toFixed(2);
    net_taxable_income.tax_computed.relief_us_89_amtp = parseFloat(relief_us_89_amtp).toFixed(2);
    net_taxable_income.tax_computed.tax_payable_amtp = parseFloat(tax_payable_amtp).toFixed(2);
    net_taxable_income.total_tax_computed_amtp = parseFloat(total_tax_computed_amtp).toFixed(2);
    net_taxable_income.excess_short_tds_amtp = parseFloat(excess_short_tds_amtp).toFixed(2);

    return net_taxable_income;
}




export const calculateIncomeTax = (total_net_taxable_income_amtp, tax_regime, salary_category, type = 'normal', financialYear) => {

    if (type === 'marginal') {
        total_net_taxable_income_amtp = getNetTaxableIncomeForMarginal(total_net_taxable_income_amtp);
    }

    let income_tax_amtp = 0;
    if (total_net_taxable_income_amtp > 0) {
        if (tax_regime === 'OLD' && (salary_category === 'G' || salary_category === 'W')) {
            if (total_net_taxable_income_amtp <= 250000) {
                income_tax_amtp = 0;
            }
            if (total_net_taxable_income_amtp > 250000 && total_net_taxable_income_amtp <= 500000) {
                income_tax_amtp = 5 / 100 * (total_net_taxable_income_amtp - 250000);
            }
            if (total_net_taxable_income_amtp > 500000 && total_net_taxable_income_amtp <= 1000000) {
                income_tax_amtp = 20 / 100 * (total_net_taxable_income_amtp - 500000) + 12500;
            }
            if (total_net_taxable_income_amtp > 1000000) {
                income_tax_amtp = 30 / 100 * (total_net_taxable_income_amtp - 1000000) + 112500;
            }
        }

        if (tax_regime === 'OLD' && salary_category === 'S') {
            if (total_net_taxable_income_amtp <= 300000) {
                income_tax_amtp = 0;
            }
            if (total_net_taxable_income_amtp > 300000 && total_net_taxable_income_amtp <= 500000) {
                income_tax_amtp = 5 / 100 * (total_net_taxable_income_amtp - 300000);
            }
            if (total_net_taxable_income_amtp > 500000 && total_net_taxable_income_amtp <= 1000000) {
                income_tax_amtp = 20 / 100 * (total_net_taxable_income_amtp - 500000) + 10000;
            }
            if (total_net_taxable_income_amtp > 1000000) {
                income_tax_amtp = 30 / 100 * (total_net_taxable_income_amtp - 1000000) + 110000;
            }
        }

        if (tax_regime === 'OLD' && salary_category === 'O') {
            if (total_net_taxable_income_amtp <= 500000) {
                income_tax_amtp = 0;
            }
            if (total_net_taxable_income_amtp > 500000 && total_net_taxable_income_amtp <= 1000000) {
                income_tax_amtp = 20 / 100 * (total_net_taxable_income_amtp - 500000);
            }
            if (total_net_taxable_income_amtp > 1000000) {
                income_tax_amtp = 30 / 100 * (total_net_taxable_income_amtp - 1000000) + 100000;
            }
        }
        if (tax_regime === 'NEW') {
            if (financialYear >= 2024) {
                if (total_net_taxable_income_amtp <= 300000) {
                    income_tax_amtp = 0;
                }
                if (total_net_taxable_income_amtp > 300000 && total_net_taxable_income_amtp <= 700000) {
                    income_tax_amtp = 5 / 100 * (total_net_taxable_income_amtp - 300000);
                }
                if (total_net_taxable_income_amtp > 700000 && total_net_taxable_income_amtp <= 1000000) {
                    income_tax_amtp = 10 / 100 * (total_net_taxable_income_amtp - 700000) + 20000;
                }
                if (total_net_taxable_income_amtp > 1000000 && total_net_taxable_income_amtp <= 1200000) {
                    income_tax_amtp = 15 / 100 * (total_net_taxable_income_amtp - 1000000) + 50000;
                }
                if (total_net_taxable_income_amtp > 1200000 && total_net_taxable_income_amtp <= 1500000) {
                    income_tax_amtp = 20 / 100 * (total_net_taxable_income_amtp - 1200000) + 80000;
                }
                if (total_net_taxable_income_amtp > 1500000) {
                    income_tax_amtp = 30 / 100 * (total_net_taxable_income_amtp - 1500000) + 140000;
                }
            } else if (financialYear === 2023) {
                if (total_net_taxable_income_amtp <= 300000) {
                    income_tax_amtp = 0;
                }
                if (total_net_taxable_income_amtp > 300000 && total_net_taxable_income_amtp <= 600000) {
                    income_tax_amtp = 5 / 100 * (total_net_taxable_income_amtp - 300000);
                }
                if (total_net_taxable_income_amtp > 600000 && total_net_taxable_income_amtp <= 900000) {
                    income_tax_amtp = 10 / 100 * (total_net_taxable_income_amtp - 600000) + 15000;
                }
                if (total_net_taxable_income_amtp > 900000 && total_net_taxable_income_amtp <= 1200000) {
                    income_tax_amtp = 15 / 100 * (total_net_taxable_income_amtp - 900000) + 45000;
                }
                if (total_net_taxable_income_amtp > 1200000 && total_net_taxable_income_amtp <= 1500000) {
                    income_tax_amtp = 20 / 100 * (total_net_taxable_income_amtp - 1200000) + 90000;
                }
                if (total_net_taxable_income_amtp > 1500000) {
                    income_tax_amtp = 30 / 100 * (total_net_taxable_income_amtp - 1500000) + 150000;
                }
            }
            else {
                if (total_net_taxable_income_amtp <= 250000) {
                    income_tax_amtp = 0;
                }
                if (total_net_taxable_income_amtp > 250000 && total_net_taxable_income_amtp <= 500000) {
                    income_tax_amtp = 5 / 100 * (total_net_taxable_income_amtp - 250000);
                }
                if (total_net_taxable_income_amtp > 500000 && total_net_taxable_income_amtp <= 750000) {
                    income_tax_amtp = 10 / 100 * (total_net_taxable_income_amtp - 500000) + 12500;
                }
                if (total_net_taxable_income_amtp > 750000 && total_net_taxable_income_amtp <= 1000000) {
                    income_tax_amtp = 15 / 100 * (total_net_taxable_income_amtp - 750000) + 37500;
                }
                if (total_net_taxable_income_amtp > 1000000 && total_net_taxable_income_amtp <= 1250000) {
                    income_tax_amtp = 20 / 100 * (total_net_taxable_income_amtp - 1000000) + 75000;
                }
                if (total_net_taxable_income_amtp > 1250000 && total_net_taxable_income_amtp <= 1500000) {
                    income_tax_amtp = 25 / 100 * (total_net_taxable_income_amtp - 1250000) + 125000;
                }
                if (total_net_taxable_income_amtp > 1500000) {
                    income_tax_amtp = 30 / 100 * (total_net_taxable_income_amtp - 1500000) + 187500;
                }
            }


        }
    }
    return income_tax_amtp;
}




export const calculateSurcharge = (total_net_taxable_income_amtp, income_tax_amtp, type = 'normal', financialYear, tax_regime) => {

    if (type === 'marginal') {
        total_net_taxable_income_amtp = getNetTaxableIncomeForMarginal(total_net_taxable_income_amtp);
    }

    let surcharge_amtp = 0;
    if (total_net_taxable_income_amtp > 5000000 && total_net_taxable_income_amtp <= 10000000) {
        surcharge_amtp = 10 / 100 * (income_tax_amtp);
    }
    if (total_net_taxable_income_amtp > 10000000 && total_net_taxable_income_amtp <= 20000000) {
        surcharge_amtp = 15 / 100 * (income_tax_amtp);
    }
    if (total_net_taxable_income_amtp > 20000000 && total_net_taxable_income_amtp <= 50000000) {
        surcharge_amtp = 25 / 100 * (income_tax_amtp);
    }
    if (total_net_taxable_income_amtp > 50000000) {
        if (financialYear >= 2023 && tax_regime === "NEW") {
            surcharge_amtp = 25 / 100 * (income_tax_amtp);
        }
        else { surcharge_amtp = 37 / 100 * (income_tax_amtp); }
    }
    return surcharge_amtp;
}




export const calculateMarginalRelief = (total_net_taxable_income_amtp, tax_regime, salary_category, income_tax_amtp, surcharge_amtp, financialYear) => {
    //Total income tax on current income
    let total_income_tax = parseFloat(income_tax_amtp) + parseFloat(surcharge_amtp);


    // get income tax on default income
    let default_income_tax = calculateIncomeTax(total_net_taxable_income_amtp, tax_regime, salary_category, 'marginal', financialYear);

    let default_surcharge_amtp = calculateSurcharge(total_net_taxable_income_amtp, default_income_tax, 'marginal', financialYear, tax_regime);
    let default_total_income_tax = parseFloat(default_income_tax) + parseFloat(default_surcharge_amtp);

    // get extra paying tax
    let extra_tax = parseFloat(total_income_tax) - parseFloat(default_total_income_tax);

    //get extra income
    let extra_income = 0;
    if (total_net_taxable_income_amtp > 5000000 && total_net_taxable_income_amtp <= 10000000) {
        extra_income = (parseFloat(total_net_taxable_income_amtp) - 5000000);
    }
    if (total_net_taxable_income_amtp > 10000000 && total_net_taxable_income_amtp <= 20000000) {
        extra_income = (parseFloat(total_net_taxable_income_amtp) - 10000000);
    }
    if (total_net_taxable_income_amtp > 20000000 && total_net_taxable_income_amtp <= 50000000) {
        extra_income = (parseFloat(total_net_taxable_income_amtp) - 20000000);
    }
    if (total_net_taxable_income_amtp > 50000000) {
        extra_income = (parseFloat(total_net_taxable_income_amtp) - 50000000);
    }


    //get marginal relief
    let marginal_relief = 0;
    if (extra_income <= extra_tax) {
        marginal_relief = extra_tax - extra_income
    }

    return marginal_relief;

}


export const getNetTaxableIncomeForMarginal = (total_net_taxable_income_amtp) => {

    if (total_net_taxable_income_amtp > 5000000 && total_net_taxable_income_amtp <= 10000000) {
        total_net_taxable_income_amtp = 5000000;
    }
    if (total_net_taxable_income_amtp > 10000000 && total_net_taxable_income_amtp <= 20000000) {
        total_net_taxable_income_amtp = 10000000;
    }
    if (total_net_taxable_income_amtp > 20000000 && total_net_taxable_income_amtp <= 50000000) {
        total_net_taxable_income_amtp = 20000000;
    }
    if (total_net_taxable_income_amtp > 50000000) {
        total_net_taxable_income_amtp = 50000000;
    }
    return total_net_taxable_income_amtp;

}


export const handleHigherDeduction = (total_net_taxable_income_amtp, income_tax_amtp, pan) => {
    // Higher deduction is only applicable when
    // Net Taxable Income should be higher than HIGHER_DEDUCTION_MIN_LIMIT
    // and, PAN = PANAPPLIED or PANINVALID or PANNOTAVBL
    if (total_net_taxable_income_amtp <= HIGHER_DEDUCTION_MIN_LIMIT || PanDefaultValue.includes(pan) === false) {
        return false;
    }

    // Higher deduction is applicable - Calculate higher deduction income tax
    let higher_ded_income_tax = total_net_taxable_income_amtp * (HIGHER_DEDUCTION_TAX_RATE / 100.0);

    // However, we will use the higher deduction value only when it is greater than regular income tax
    // Equality condition is used because in case of regular income tax, even if it is same as Higher deduction tax,
    // additional health & edu. cess is also applied. In case of higher deduction, it is ignored.
    if (higher_ded_income_tax <= income_tax_amtp) {
        return false;
    }
    // Update income tax due to higher deduction applicable.
    //$salary -> setHigherDeduction(true); // Needed later when calculating Health and Edu. cess
    return higher_ded_income_tax;
}