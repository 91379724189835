/* eslint-disable no-throw-literal */
import axios from "axios";
import { handleConnectorError, handleError } from "./responseHandler";
import { getConfig, getConnectorConfig } from "./common";
import { BASE_URL } from "../app/constants";

const connectorError =
  "Unable to connect to the Express Connector. Please ensure that the Express Connector is running. If the problem still persists, then close the browser completely and restart.";

export async function getConnectionStatusFromConnector(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/status`;

  try {
    const result = await axios.get(url, { ...getConfig(), getConnectorConfig });

    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);

    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getCaptchaOLD(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/connector/traces/getCaptcha`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyPANOLD(port, data) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/connector/traces/verifyPan`;

  try {
    const result = await axios.post(url, data);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getTracesCaptchaFromConnector(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/captcha`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    //console.error('error: ', response);

    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyTracesLoginFromConnector(port, data) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/login`;

  try {
    const result = await axios.post(url, data);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyBulkPanFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/bulk-pan-verify`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getTracesProcessStatusFromConnector(port, captcha_ref) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/status${captcha_ref}`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifySinglePanFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/pan-verify`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getReturnTrailConnectorApi(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/returns-to-fetch-statement`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function raiseRequestFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/raise-request`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function importTracesUnconsumedChallansFromConnector(
  port,
  data,
  headers
) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/unconsumed-challans-list`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function createChallanPaymentUsingConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/gui/action/generate-challan`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyEFilingCredsFromConnector(port, data) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/login`;

  try {
    const result = await axios.post(url, data);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function importedChallansFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/payment-history`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getInsightsCaptchaFromConnector(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/captcha`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyInsightsLoginFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/tds/insights/login`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyPANandCheckCompliance(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/tds/compliance`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function getInsightsProcessStatusFromConnector(port, captcha_ref) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/insights/status${captcha_ref}`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyInsightsLoginAndReturnCaptcha(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/tds/insights/captcha`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyInsightsLoginForSinglePan(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/tds/insights/pan-compliance`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function refreshInsightsCaptcha(port, captcha_ref) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/insights/captcha${captcha_ref}`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function checkExistingComplianceRequest(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/tds/check-existing-compliance`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function updateFilingHistoryOnServer(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/filing-history`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function uploadReturnFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/gui/action/upload-return`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function geteFilingStatusFromConnector(port, action_id) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/gui/action/status?action_id=${action_id}`;

  try {
    const result = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function searchtdstxt() {
  const url = `${BASE_URL}/api/v1/deductor/search-tds-txt`;
  try {
      const result = await axios.get(url, getConfig());
      if (Number(result.data.status) !== 1) {
          throw (result.data.message);
      }
      return result;
  } catch (err) {
      throw handleError(err);
  }
}

export async function runSearchtdstxtConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/custom/action/perform`;
  try {
      const result = await axios.post(url, data, {
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              ...headers,
          }
      });
      return result.data;
  } catch (err) {
      const response = handleConnectorError(err);
      if (response.code === 404) {
          throw { code: 404, message: connectorError };
      } else {
          throw response;
      }
  }
}

export async function getProcessIdStatus(port, processId) {

  const url = `http://127.0.0.1:${port}/api/v1/tds/custom/action/status?process_id=${processId}`;
  try {
    const result = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    });
    return { data: result.data, headers: result.headers };
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 401 || response.code === 404) {
      throw { code: response.code, message: connectorError };
    } else {
      throw response;
    }
  }
}
export async function getNoticeOrderConnectorApi(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/fetch-notice-order`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function updateFilingItHistoryOnPortal(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/gui/action/login`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function updateFilingTracesHistoryOnPortal(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/gui/action/login`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function importLdcListFromPortal(
  port,
  data,
  headers
) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/bulk-ldc-verify`;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function verifyInsightsRegistrationFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };
  const url = `http://127.0.0.1:${port}/api/v1/tds/itd/insights-new-registration`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}