/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Formik, Form,
} from 'formik';
import { manageSalaryData, tdsReturnValidNumber, titleForRegime } from '../../../../utils/UtilityFunctions';
import { Tooltip } from '@progress/kendo-react-tooltip';
import deleteIcon from '../../../../images/icons/delete.svg';
import addBtnRoundIcon from '../../../../images/icons/add_btn_round.svg';
import filedoticon from '../../../../images/loginicon/filedoticon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { getCorrectionDeducteesAutocomplete } from '../../../../api/deducteeAPI';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import NumericInputWithDecimalTwo from '../../../../components/NumericInputWithDecimalTwo';
import { calculateTaxComputed, calculateTotalDeductionUnderChapter6Aa } from './calculationScema';
import { salaryDetailsvalidationSchema } from './validationSchema';
import Monthwisesalarydetails from './popup/monthwisesalarydetails';
import Allowancedetails from './popup/allowancedetails';
import { getInitializeData, resetInitialData } from './salaryHelper';
import { MONTH_LIST } from '../../../../app/constants'
import { formatNumber, roundUpValue } from '../../../../utils/UtilityFunctions';
import DatePicker from '../../../../components/datePicker/DatePicker';
import Addbtnround from '../../../../images/icons/add_btn_round.svg'
import { getDeducteesV2 } from '../../../../api/deducteeAPI';

const SalaryDetails = (props) => {

    const { salaryData, setSalaryData, deductor_id, showSalaryPopup, setShowSalaryPopup, setDeducteeName, addSalary, viewComputation, allowanceList, getDeducteeTDSAmount, headerDataDetails, componentImport, return_id, actionStatus, setShowCreateForm, deductionVIAParticulars } = props;
    const [showMonthlyPopup, setShowMonthlyPopup] = useState(false);
    const [showAllowancePopup, setShowAllowancePopup] = useState(-1);
    const [deducteeData, setDeducteeData] = useState([]);
    // const [showCreateForm, setShowCreateForm] = useState(false);

    useEffect(() => {
        document.getElementById('deductee_name').setSelectionRange(0, 0);
        document.getElementById('deductee_name').focus();
    }, [showSalaryPopup]);



    const handleDeducteeNameChange = (event) => {
        const nameStr = event.target.value;
        async function fetchDeducteeName() {

            if (componentImport) {
                const result = await getCorrectionDeducteesAutocomplete(`?deductor_id=${deductor_id}&return_id=${return_id}&filter_text=${nameStr}`)
                const resultData = result?.data ?? [];
                const DeducteeDataName = [];
                resultData.forEach((deductee) => {
                    const panDetail = deductee.pan;
                    let deductee_name = deductee.deductee_name;
                    if (deductee_name.length > 25) {
                        deductee_name = deductee_name.substring(0, 25) + '...';
                    }
                    DeducteeDataName.push({ id: deductee.deductee_id, salary_category: deductee.salary_category, text: `${deductee.deductee_name} - ${panDetail}`, pan: panDetail });
                    // return true;
                });
                setDeducteeData(DeducteeDataName);
                localStorage.setItem('deducteeList', JSON.stringify(DeducteeDataName));
            } else {
                const result = await getDeducteesV2({ deductor_id: deductor_id, search_text: nameStr, limit: 17, filter_status: 1, deductee_type: 'INDIVIDUAL_02,OTH_10' }, false);
                const DeducteeDataName = [];
                const resultData = result?.data?.data ?? [];
                resultData.map((deductee) => {
                    const panDetail = deductee.pan;
                    let deductee_name = deductee.deductee_name;
                    if (deductee_name.length > 25) {
                        deductee_name = deductee_name.substring(0, 25) + '...';
                    }
                    DeducteeDataName.push({ id: deductee.deductee_id, salary_category: deductee.salary_category, text: `${deductee.deductee_name} - ${panDetail}`, pan: panDetail });
                    return true;
                });
                setDeducteeData(DeducteeDataName);
                localStorage.setItem('deducteeList', JSON.stringify(DeducteeDataName));
            }
        }
        if (nameStr.length !== 0 && nameStr.length > 0) {
            fetchDeducteeName();
        } else {
            setDeducteeData([]);
        }
    };


    const selectedDeducteeName = async (value, values, setFieldValue) => {
        let filterDeducteeData = deducteeData.filter((data, index) => { return data.text === value; });
        if (filterDeducteeData.length > 0) {
            setFieldValue('deductee_id', filterDeducteeData[0].id);
            setFieldValue('salary_category', filterDeducteeData[0].salary_category);
            setFieldValue('deductee_name', value);
            setDeducteeName(value);
            let result = await getDeducteeTDSAmount(deductor_id, filterDeducteeData[0].id, salaryData.financial_year);
            if (result.data) {
                let net_taxable_income = values.net_taxable_income;
                net_taxable_income.tds_ded_by_current_emp_amtp = result.data.total_tds_amtp;
                setFieldValue('net_taxable_income', net_taxable_income);
                //update tax computed value
                values.net_taxable_income = net_taxable_income;
                values.deductee_id = filterDeducteeData[0].id;
                values.salary_category = filterDeducteeData[0].salary_category;
                values.pan = filterDeducteeData[0].pan;
                totalGrossIncome(values, setFieldValue);
            }
        }
    };


    // const submitPeriodDate = (fieldValue, setFieldValue) => {
    //     if (fieldValue) {
    //         let dateValue = fieldValue.split('-');
    //         dateValue[0] = dateValue[0] ? dateValue[0].trim() : '';
    //         dateValue[1] = dateValue[1] ? dateValue[1].trim() : '';
    //         setFieldValue('employment_period_from', dateValue[0].trim());
    //         setFieldValue('employment_period_to', dateValue[1].trim());
    //     }
    // };

    const changeTexRegimeNew = async (setFieldValue, values) => {
        //set deduction amtp
        setFieldValue('standard_deduction_amtp', 0);
        setFieldValue('ent_allowance_amtp', 0);
        setFieldValue('professional_tax_amtp', 0);
        setFieldValue('total_deduction_amtp', 0);
        values.tax_regime = 'NEW';
        values.ent_allowance_amtp = 0;
        values.professional_tax_amtp = 0;
        values.total_allowance_exempt_amtp = resetAllowances(values, setFieldValue);
        await autoUpdateStandardDeductionAmtp(values.total_gross_salary_amtp, setFieldValue, values);
    }


    const changeTexRegimeOld = async (setFieldValue, values) => {
        values.tax_regime = 'OLD';
        values.total_allowance_exempt_amtp = resetAllowances(values, setFieldValue);
        await autoUpdateStandardDeductionAmtp(values.total_gross_salary_amtp, setFieldValue, values);
    }

    const resetAllowances = (values, setFieldValue) => {
        let valid_allowance = [];
        let allowances = [];
        let total_allowance_exempt_amtp = 0;

        valid_allowance = allowanceList[values.tax_regime].map((data, index) => {
            return data.allowance_type_id;
        });

        allowances = values.allowances.map((allowance_data, index) => {
            if (valid_allowance.indexOf(parseInt(allowance_data.allowance_type_id)) >= 0) {
                let exempt_amtp = parseInt(allowance_data.exempt_amtp) ? parseFloat(allowance_data.exempt_amtp) : 0;
                total_allowance_exempt_amtp = parseFloat(total_allowance_exempt_amtp) + exempt_amtp;
                return allowance_data;
            }
            else {
                return false;
            }
        });
        allowances = allowances.filter(el => { return el.allowance_type_id; });

        if (allowances.length === 0) {
            allowances = [{ allowance_type_id: 0, exempt_amtp: 0, allowance_details: null }];
        }

        total_allowance_exempt_amtp = total_allowance_exempt_amtp.toFixed(2);
        setFieldValue('allowances', allowances);
        setFieldValue('total_allowance_exempt_amtp', total_allowance_exempt_amtp);
        return total_allowance_exempt_amtp;
    }


    const set_salary_breakup = (salary, values, setFieldValue) => {
        salary = parseInt(salary) > 0 ? salary : 0;
        let monthly_salary = (parseFloat(salary) / 12).toFixed(2);
        let salary_breakup_amtp = values.salary_breakup_amtp;
        MONTH_LIST.map((month, index) => {
            salary_breakup_amtp[`${month.value}`] = monthly_salary;
            return true;
        });

        //adjust extra value
        let breakup_total = monthly_salary * 12;
        let extra_value = (parseFloat(salary) - parseFloat(breakup_total)).toFixed(2);
        if (salary_breakup_amtp && salary_breakup_amtp[`3`]) {
            salary_breakup_amtp[`3`] = (parseFloat(salary_breakup_amtp[`3`]) + parseFloat(extra_value)).toFixed(2);
        }
        values.salary_breakup_amtp = salary_breakup_amtp;
        setFieldValue('salary_breakup_amtp', salary_breakup_amtp);
    }


    const totalGrossSalary = (field, value, setFieldValue, values) => {
        values[field] = value;
        let salary_amtp = parseInt(values.salary_amtp) >= 0 ? values.salary_amtp : 0;
        let perquisites_amtp = parseInt(values.perquisites_amtp) >= 0 ? values.perquisites_amtp : 0;
        let profit_in_lieu_amtp = parseInt(values.profit_in_lieu_amtp) >= 0 ? values.profit_in_lieu_amtp : 0;
        let total_gross_salary_amtp = (parseFloat(salary_amtp) + parseFloat(perquisites_amtp) + parseFloat(profit_in_lieu_amtp)).toFixed(2);
        setFieldValue('total_gross_salary_amtp', total_gross_salary_amtp);
        values.total_gross_salary_amtp = total_gross_salary_amtp;
        //totalNetSalary(total_gross_salary_amtp, values.total_deduction_amtp, values.total_allowance_exempt_amtp, values.previous_employer_amount_received_amtp, values, setFieldValue);
        autoUpdateStandardDeductionAmtp(total_gross_salary_amtp, setFieldValue, values);
    }
    const autoUpdateStandardDeductionAmtp = (total_gross_salary_amtp, setFieldValue, values) => {
        let standard_deduction_amtp = 0;

        if (values.tax_regime === 'NEW' && salaryData.financial_year >= 2024) {
            standard_deduction_amtp = total_gross_salary_amtp > 75000.00 ? 75000.00 : total_gross_salary_amtp;
        } else if (values.tax_regime === 'OLD' || (values.tax_regime === 'NEW' && salaryData.financial_year === 2023)) {
            standard_deduction_amtp = total_gross_salary_amtp > 50000.00 ? 50000.00 : total_gross_salary_amtp;
        }

        setFieldValue('standard_deduction_amtp', standard_deduction_amtp);
        totalDeductionAmt('standard_deduction_amtp', standard_deduction_amtp, setFieldValue, values);
    }


    const totalDeductionAmt = (field, value, setFieldValue, values) => {
        values[field] = value;
        let standard_deduction_amtp = parseInt(values.standard_deduction_amtp) >= 0 ? values.standard_deduction_amtp : 0;
        let ent_allowance_amtp = parseInt(values.ent_allowance_amtp) >= 0 ? values.ent_allowance_amtp : 0;
        let professional_tax_amtp = parseInt(values.professional_tax_amtp) >= 0 ? values.professional_tax_amtp : 0;
        let total_deduction_amtp = (parseFloat(standard_deduction_amtp) + parseFloat(ent_allowance_amtp) + parseFloat(professional_tax_amtp)).toFixed(2);
        setFieldValue('total_deduction_amtp', total_deduction_amtp);
        totalNetSalary(values.total_gross_salary_amtp, total_deduction_amtp, values.total_allowance_exempt_amtp, values.previous_employer_amount_received_amtp, values, setFieldValue);
    }


    const getTotalAllowanceAmtp = (values, allowances, setFieldValue) => {
        let total_allowance_exempt_amtp = 0;
        allowances.map((data, index) => {
            let exempt_amtp = parseInt(data.exempt_amtp) ? parseFloat(data.exempt_amtp) : 0;
            total_allowance_exempt_amtp = parseFloat(total_allowance_exempt_amtp) + exempt_amtp;
            return false;
        });
        total_allowance_exempt_amtp = total_allowance_exempt_amtp.toFixed(2);
        setFieldValue('total_allowance_exempt_amtp', total_allowance_exempt_amtp);
        totalNetSalary(values.total_gross_salary_amtp, values.total_deduction_amtp, total_allowance_exempt_amtp, values.previous_employer_amount_received_amtp, values, setFieldValue);
    }

    const totalNetSalary = (total_gross_salary_amtp, total_deduction_amtp, total_allowance_exempt_amtp, previous_employer_amount_received_amtp, values, setFieldValue) => {
        let total_net_salary_amtp = parseFloat(total_gross_salary_amtp) - parseFloat(total_allowance_exempt_amtp);
        total_net_salary_amtp = parseFloat(total_net_salary_amtp) - parseFloat(total_deduction_amtp);
        total_net_salary_amtp = parseFloat(total_net_salary_amtp) + parseFloat(previous_employer_amount_received_amtp);

        setFieldValue('net_salary_amtp', total_net_salary_amtp.toFixed(2));
        values.net_salary_amtp = total_net_salary_amtp.toFixed(2);
        totalGrossIncome(values, setFieldValue);
    }

    const totalGrossIncome = (values, setFieldValue) => {
        let total_gross_income_amtp = 0;
        total_gross_income_amtp = (parseFloat(values.net_salary_amtp) + parseFloat(values.total_house_property_income_amtp) + parseFloat(values.total_other_income_offered_amtp)).toFixed(2);
        setFieldValue('total_gross_income_amtp', total_gross_income_amtp);
        values.total_gross_income_amtp = total_gross_income_amtp;
        totalDeductionUnderChapter6Aa(values, values.deduction_vi_a_details, setFieldValue);
    }

    const deductionCodeList = (allowed_particulars) => {
        const codes = [];
        if (allowed_particulars && allowed_particulars.length > 0) {
            allowed_particulars.forEach((item) => {
                codes.push(item?.code);
            })
        }
        return codes;
    }

    const resetDeductionDataByTaxRegime = (values, deduction_vi_a_details) => {
        const tax_regime = values?.tax_regime ?? '';
        const allowed_deductions = deductionCodeList(deductionVIAParticulars[tax_regime] ?? []);
        if (deduction_vi_a_details && deduction_vi_a_details.length > 0) {
            deduction_vi_a_details.forEach((item, index) => {
                if (!allowed_deductions.includes(item.code)) {
                    item.gross_amount_amtp = 0;
                    item.deductible_amount_amtp = 0;
                }
            })
        }
    }

    const totalDeductionUnderChapter6Aa = (values, deduction_vi_a_details, setFieldValue) => {
        resetDeductionDataByTaxRegime(values, deduction_vi_a_details);
        let result = calculateTotalDeductionUnderChapter6Aa(deduction_vi_a_details, values.salary_category, values.total_gross_salary_amtp);
        setFieldValue('deduction_vi_a_details', result[0]);
        setFieldValue('total_deduction_vi_a_amtp', result[1].toFixed(2));
        values.total_deduction_vi_a_amtp = result[1].toFixed(2);
        totalNetTaxableIncome(values, setFieldValue);
    }

    const totalNetTaxableIncome = (values, setFieldValue) => {
        let total_net_taxable_income_amtp = 0;
        total_net_taxable_income_amtp = (parseFloat(values.total_gross_income_amtp) - parseFloat(values.total_deduction_vi_a_amtp)).toFixed(2);

        let net_taxable_income = values.net_taxable_income;
        net_taxable_income.total_net_taxable_income_amtp = total_net_taxable_income_amtp;
        net_taxable_income = calculateTaxComputed(net_taxable_income, values, parseInt(headerDataDetails?.financial_year_formatted.split("-")?.[0] ?? 0));
        setFieldValue('net_taxable_income', net_taxable_income);
    }

    const changeAllowance = (e, field, index, setFieldValue, values, isRoundUpValue = false) => {
        let allowances = values.allowances;
        allowances[index][field] = isRoundUpValue ? roundUpValue(e.target.value) : e.target.value;
        allowances[index].allowance_details = null;
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(values, allowances, setFieldValue);
    }

    const addAllowance = (setFieldValue, values) => {
        let allowances = values.allowances;
        allowances.push({
            allowance_type_id: 0, exempt_amtp: 0, allowance_details: null
        });
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(values, allowances, setFieldValue);
    }

    const removeAllowance = (raw, setFieldValue, values) => {
        let allowances = values.allowances;
        allowances = allowances.filter((data, index) => { return raw !== index; });
        setFieldValue('allowances', allowances);
        getTotalAllowanceAmtp(values, allowances, setFieldValue);
    }

    const isAllowanceUse = (list_id, allowance_type_id, allowances) => {
        let show = true;
        allowances.map((checkallowance) => {
            if (parseInt(checkallowance.allowance_type_id) === parseInt(list_id) && parseInt(allowance_type_id) !== parseInt(list_id)) {
                show = false;
            }
            return true;
        })
        return show;
    }

    const listNoDataRender = (element, setFieldValue, values) => {
        const noData = (

            <span>
                <h4 style={{ fontSize: '1em' }} onMouseDown={(e) => e.stopPropagation()}>
                    <span className="k-icon k-i-warning" style={{ fontSize: '1.6em', top: '0px', paddingBottom: '3px' }} />
                    <div className='nodata'>No data found</div>
                    <hr style={{ margin: '4px 0px  0px 0px' }} />
                    <span
                        onClick={() => { showform(setFieldValue, values) }}
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        className="btn btn-default" style={{ padding: '0px', color: '#31719b', backgroundColor: 'none', border: 'none !important', textTransform: 'capitalize', height: '20px', }}>
                        <span className='addnewded_icon'>
                            <img src={Addbtnround} width={16} alt='' />
                            <span className='addnewded_textfs pl-2'>Add new deductee</span>
                        </span>

                    </span>
                </h4>
            </span>
        );

        return React.cloneElement(element, { ...element.props }, noData);
    }

    const itemRender = (li, itemProps, setFieldValue, values) => {
        const index = itemProps.index;
        const itemChildren = (
            <span>
                <span style={{ color: "#00F" }}>{li.props.children}</span><br /><br />
                {index + 1 === deducteeData.length && (
                    <>
                        <li className="k-button addinnerbtnbgclr" onClick={() => { showform(setFieldValue, values); }}
                            style={{ background: '#31719b', height: '30px', width: '150px', color: '#fff', textTransform: 'capitalize' }}>
                            <span className='addnewded_icon pr-2'>
                                <img src={Addbtnround} width={16} alt='' />
                            </span>
                            <span className='addnewded_textfs'>Add new deductee</span>
                        </li>
                    </>
                )
                }
            </span>
        )
        return React.cloneElement(li, li.props, itemChildren);
    }

    const showform = (setFieldValue, values) => {
        setTimeout(function () {
            values.deductee_name = '';
            values.deductee_id = '';
            values.salary_category = '';
            setFieldValue('deductee_name', '');
            setFieldValue('deductee_id', '');
            setFieldValue('salary_category', '');
            setDeducteeName(null);
            setShowCreateForm(true);
        });
    }

    // const HandleClose = (setFieldValue, values) => {
    //     let NewAddedDeductee = JSON.parse(localStorage.getItem('getNewAddedDeductee'));
    //     if (NewAddedDeductee) {
    //         let getDeducteeAllData = NewAddedDeductee[0];
    //         let getDeducteeData = NewAddedDeductee[1];
    //         // console.log("data", getDeducteeAllData, getDeducteeData);
    //         let value = `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan} - ${getDeducteeData.salary_category_display_text}`;
    //         values.deductee_id = getDeducteeData.deductee_id;
    //         values.salary_category = getDeducteeData.salary_category;
    //         values.deductee_name = value;
    //         setFieldValue('deductee_id', getDeducteeData.deductee_id);
    //         setFieldValue('salary_category', getDeducteeData.salary_category);
    //         setFieldValue('deductee_name', value);
    //         setDeducteeName(value);

    //         deducteeTDSAmount(values, getDeducteeData.deductee_id, getDeducteeData.salary_category, setFieldValue);
    //     }
    //     setShowCreateForm(false);
    //     localStorage.removeItem('getNewAddedDeductee');
    //     setTimeout(function () {
    //         $('#deductee_name').focus();
    //     });
    // };

    // const deducteeTDSAmount = async (values, deductee_id, salary_category, setFieldValue) => {
    //     let result = await getDeducteeTDSAmount(deductor_id, deductee_id, salaryData.financial_year);
    //     if (result.data) {
    //         // console.log("result.data",result.data);
    //         let net_taxable_income = values.net_taxable_income;
    //         net_taxable_income.tds_ded_by_current_emp_amtp = result.data.total_tds_amtp;
    //         setFieldValue('net_taxable_income', net_taxable_income);
    //         //update tax computed value
    //         values.net_taxable_income = net_taxable_income;
    //         values.deductee_id = deductee_id;
    //         values.salary_category = salary_category;
    //         totalGrossIncome(values, setFieldValue);
    //     }
    // }

    const disable_field = componentImport && actionStatus !== 'ADDED' && salaryData.salary_id ? true : false;

    // let width1 = document.body.clientWidth;

    // const roundUpValue = (value) => {       
    //     return Math.ceil(Number(value ?? 0)).toFixed(2);
    // }



    return (
        // <div className="container">        
        <Formik
            initialValues={salaryData}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={salaryDetailsvalidationSchema}
            onSubmit={async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
                const submitValues = { ...values };
                if (values.save_type !== 'new' && values.save_type !== 'save') {
                    setSalaryData(submitValues);
                    let popup = document.getElementById('salary_details_submit').getAttribute('data-popup');
                    setShowSalaryPopup(popup);
                }
                else {
                    let save_success = await addSalary(submitValues);
                    if (save_success) {
                        resetForm(getInitializeData(values.deductor_id, values.is_government_deductor, values.financial_year));
                    }
                    else {
                        resetInitialData(salaryData);
                    }
                }
            }}
        >
            {({
                errors, values, setFieldValue,
            }) => {

                if (!values.salary_id) {

                    setTimeout(function () {
                        manageSalaryData('save', headerDataDetails.tan, values.financial_year, values);
                    }, 1000);
                }

                if (Object.keys(errors).length > 0) {
                    document.getElementById('save-salary-details').disabled = false;
                    document.getElementById('save-new-salary-details').disabled = false;
                }
                return (<>

                    {/* {
                            showCreateForm ? (
                                <AddDeductee
                                    onClose={() => HandleClose(setFieldValue, values)}
                                    deductorId={deductor_id}
                                    showDeducteeFormLeft={true}
                                    componentImport={componentImport}
                                    left={width1 - 1850}
                                />
                            )
                                : null
                        } */}
                    < Form key={1} autoComplete="off" className="salary_details_form" id="salary_details_form" >
                        <Tooltip anchorElement="pointer" position="left">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="form-group col-xl-4 col-lg-4 col-md-6">
                                            {/* <div className={'form-group col-md-8 mb-0'} > */}
                                            <label htmlFor="" className="">Deductee/Employee Name<span className="red_text">*</span></label>
                                            <AutoComplete
                                                data={deducteeData}
                                                id="deductee_name"
                                                textField="text"
                                                disabled={disable_field}
                                                style={{ backgroundColor: disable_field ? '#e9ecef' : '' }}
                                                dataItemKey="id"
                                                value={values.deductee_name}
                                                className="form-control"
                                                popupSettings={{ className: 'form-control-option' }}
                                                onChange={(e) => {
                                                    handleDeducteeNameChange(e);
                                                    setFieldValue(
                                                        'deductee_name',
                                                        e.target.value,
                                                    );
                                                    if (e.target.value.length < 2) {
                                                        setFieldValue('deductee_id', '');
                                                        setFieldValue('salary_category', '');
                                                        setFieldValue('pan', '');
                                                        setDeducteeName(null);
                                                    }
                                                }}
                                                onClose={(e) => { selectedDeducteeName(e.target.value, values, setFieldValue) }}
                                                placeholder="Enter Deductee Name"
                                                name="deductee_name"
                                                listNoDataRender={(element) => { return listNoDataRender(element, setFieldValue, values); }}
                                                itemRender={(li, itemProps) => { return itemRender(li, itemProps, setFieldValue, values); }}
                                            />
                                            {errors.deductee_id ? (
                                                <span className="error_message">
                                                    {errors.deductee_id}
                                                </span>
                                            ) : null}
                                            {(componentImport && salaryData && salaryData.old_deductee_name) ? (<div className="error_message clrbtnfrmDeducteetrans" style={{ color: salaryData.deductee_name_color ? `#${salaryData.deductee_name_color}` : '#0000ff' }}>{salaryData.old_deductee_name}</div>) : null}


                                        </div>

                                        <div className="form-group col-xl-5 col-lg-5 col-md-6 pl-1 pr-1">
                                            <label htmlFor="">Employment Period<span className="red_text">*</span></label>
                                            {/* <DateRangePickers
                                                    initialSettings={
                                                        values.employment_period_from && values.employment_period_to ?
                                                            {
                                                                startDate: values.employment_period_from, endDate: values.employment_period_to,
                                                                minDate: `01/04/${values.financial_year}`,
                                                                maxDate: `31/03/${parseInt(values.financial_year, 10) + 1}`,
                                                                locale: {
                                                                    format: 'DD/MM/YYYY',
                                                                },
                                                            } :
                                                            {
                                                                minDate: `01/04/${values.financial_year}`,
                                                                maxDate: `31/03/${parseInt(values.financial_year, 10) + 1}`,
                                                                autoUpdateInput: false,
                                                                locale: {
                                                                    format: 'DD/MM/YYYY',
                                                                },
                                                            }
                                                    }
                                                    disabled={disable_field}
                                                    onApply={(value) => {
                                                        submitPeriodDate(value, setFieldValue);
                                                    }}
                                                    onHide={(value) => {
                                                        submitPeriodDate(value, setFieldValue);
                                                    }}
                                                /> */}
                                            <div className='row'>
                                                <div className='col-md-6 pr-1'>
                                                    <div className='monthwisesalalry_dateboxwidth'>
                                                        <DatePicker
                                                            selectedDate={values.employment_period_from ? values.employment_period_from : null}
                                                            onChange={(value) => {
                                                                setFieldValue('employment_period_from', value);
                                                            }}
                                                            minDate={new Date(`01/04/${parseInt(values.financial_year)}`)}
                                                            maxDate={new Date(`31/03/${parseInt(values.financial_year, 10) + 1}`)}
                                                            placeHolder="From Date"
                                                            disabled={disable_field}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 pr-2 pl-2'>
                                                    <div className='monthwisesalalry_dateboxwidth'>
                                                        <DatePicker
                                                            selectedDate={values.employment_period_to ? values.employment_period_to : null}
                                                            onChange={(value) => {
                                                                setFieldValue('employment_period_to', value);
                                                            }}
                                                            minDate={new Date(`01/04/${parseInt(values.financial_year)}`)}
                                                            maxDate={new Date(`31/03/${parseInt(values.financial_year, 10) + 1}`)}
                                                            placeHolder="To Date"
                                                            disabled={disable_field}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            {errors.employment_period_from && !errors.employment_period_to ? (
                                                <span className="error_message">
                                                    {errors.employment_period_from}
                                                </span>
                                            ) : null}

                                            {errors.employment_period_to && !errors.employment_period_from ? (
                                                <span className="error_message">
                                                    {errors.employment_period_to}
                                                </span>
                                            ) : null}

                                            {errors.employment_period_to && errors.employment_period_from ? (
                                                <span className="error_message">
                                                    {errors.employment_period_from}
                                                </span>
                                            ) : null}
                                            {(componentImport && salaryData && salaryData.old_employment_period_from) ? (<div className="error_message clrbtnfrmDeducteetrans" style={{ color: salaryData.employment_period_from_color ? `#${salaryData.employment_period_from_color}` : '#0000ff' }}>{salaryData.old_employment_period_from + ' - ' + salaryData.old_employment_period_to}</div>) : null}
                                        </div>

                                        {
                                            Number(values?.financial_year ?? 0) >= 2023
                                                ?
                                                <>
                                                    <div className="form-group col-xl-3 col-lg-3 col-md-12">
                                                        <label htmlFor="" className="pl-4" style={{ width: '100%' }}>
                                                            Tax Regime
                                                        </label>
                                                        <div className='pt-2 pl-4'>
                                                            <span title='Opting Out from 115BAC(1A) = No' className="salary_rightsidetextfs pr-2" onClick={() => { setFieldValue('tax_regime', 'NEW'); changeTexRegimeNew(setFieldValue, values); }}>New</span>
                                                            <span className="salary_statetoggle_ht02">
                                                                <span className={`salary_statetoggle_button__ht02 salary_togglebtn_01 ${values.tax_regime === "NEW" ? "active " : ''}`} id="toggle-button2"
                                                                    onClick={() => { if ((values.tax_regime ?? null) !== "NEW") { setFieldValue('tax_regime', 'NEW'); changeTexRegimeNew(setFieldValue, values); } }}
                                                                >
                                                                </span>

                                                                <span className={`salary_statetoggle_button__ht02 salary_togglebtn_03 ${values.tax_regime === "OLD" ? "active " : ''}`} id="toggle-button3"
                                                                    onClick={() => { if ((values.tax_regime ?? null) !== "OLD") { setFieldValue('tax_regime', 'OLD'); changeTexRegimeOld(setFieldValue, values); } }}
                                                                >
                                                                </span>
                                                            </span>
                                                            <span title='Opting Out from 115BAC(1A) = Yes' className="salary_rightsidetextfs pl-2" style={{ color: "green" }} onClick={() => { setFieldValue('tax_regime', 'OLD'); changeTexRegimeOld(setFieldValue, values); }}>Old</span>
                                                        </div>
                                                        {(componentImport && salaryData && salaryData.old_tax_regime) ? (<div className="error_message" style={{ color: salaryData.tax_regime_color ? `#${salaryData.tax_regime_color}` : '#0000ff' }}>{salaryData.old_tax_regime}</div>) : null}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="form-group col-xl-3 col-lg-3 col-md-12">
                                                        <label htmlFor="" className="" style={{ width: '100%' }}> {titleForRegime(values.financial_year)} (New Tax Regime) </label>
                                                        <div className="form-check form-check-inline pt-2">
                                                            <input
                                                                name="tax_regime"
                                                                value="NEW"
                                                                className="form-check-input"
                                                                disabled={disable_field}
                                                                type="radio"
                                                                checked={values.tax_regime === 'NEW' ? true : false}
                                                                onChange={() => {
                                                                    setFieldValue('tax_regime', 'NEW');
                                                                    changeTexRegimeNew(setFieldValue, values);
                                                                }
                                                                }
                                                            />
                                                            <label className="form-check-label" htmlFor="">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline pt-2">
                                                            <input
                                                                name="tax_regime"
                                                                value="OLD"
                                                                disabled={disable_field}
                                                                className="form-check-input"
                                                                type="radio"
                                                                checked={values.tax_regime === 'OLD' ? true : false}
                                                                onChange={() => {
                                                                    setFieldValue('tax_regime', 'OLD');
                                                                    changeTexRegimeOld(setFieldValue, values);
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="">No</label>
                                                        </div>
                                                        {(componentImport && salaryData && salaryData.old_tax_regime) ? (<div className="error_message" style={{ color: salaryData.tax_regime_color ? `#${salaryData.tax_regime_color}` : '#0000ff' }}>{salaryData.old_tax_regime}</div>) : null}
                                                    </div>
                                                </>
                                        }


                                        <div className="col-md-12">
                                            <div className="card grosssalaryph3card">
                                                <div className="card-header">
                                                    Gross Salary
                                                    <input type="hidden" name="deductee_id" value={values.total_gross_salary_amtp} />
                                                    <span className="float-right"> Total = <span className=" fsizeiconrup"><FontAwesomeIcon icon={faRupeeSign} /></span> {formatNumber(values.total_gross_salary_amtp)}</span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className=""> Salary u/s 17(1)</label>
                                                            <img className="grosssalaryicon" onClick={() => setShowMonthlyPopup(true)} title="Salary includes Ar rear Salary, Advance Salary, Gross Amount of Allowances to employee, etc" src={filedoticon} alt="" />
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                disabled={disable_field}
                                                                className="form-control"
                                                                name="salary_amtp"
                                                                value={values.salary_amtp}
                                                                placeholder="0.00"
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue(
                                                                        'salary_amtp',
                                                                        e.target.value,
                                                                    );
                                                                    set_salary_breakup(e.target.value, values, setFieldValue);
                                                                    totalGrossSalary('salary_amtp', e.target.value, setFieldValue, values);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('salary_amtp', roundUpValue(e.target.value));
                                                                    set_salary_breakup(roundUpValue(e.target.value), values, setFieldValue);
                                                                    totalGrossSalary('salary_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                }}
                                                            />
                                                            {errors.salary_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.salary_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_salary_amtp && salaryData.old_salary_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.salary_amtp_color ? `#${salaryData.salary_amtp_color}` : '#0000ff' }}>{salaryData.old_salary_amtp}</div>) : null}


                                                            {showMonthlyPopup &&
                                                                <Monthwisesalarydetails
                                                                    show={showMonthlyPopup}
                                                                    handleClose={() => setShowMonthlyPopup(!showMonthlyPopup)}
                                                                    values={values}
                                                                    setFieldValue={setFieldValue}
                                                                    totalGrossSalary={totalGrossSalary}
                                                                />}
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">Perquisites u/s 17(2)</label>
                                                            <NumericInputWithDecimalTwo
                                                                disabled={disable_field}
                                                                type="text"
                                                                className="form-control"
                                                                name="perquisites_amtp"
                                                                value={values.perquisites_amtp}
                                                                placeholder="0.00"
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue(
                                                                        'perquisites_amtp',
                                                                        e.target.value,
                                                                    );
                                                                    totalGrossSalary('perquisites_amtp', e.target.value, setFieldValue, values);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('perquisites_amtp', roundUpValue(e.target.value));
                                                                    totalGrossSalary('perquisites_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                }}
                                                            />
                                                            {errors.perquisites_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.perquisites_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_perquisites_amtp && salaryData.old_perquisites_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.perquisites_amtp_color ? `#${salaryData.perquisites_amtp_color}` : '#0000ff' }}>{salaryData.old_perquisites_amtp}</div>) : null}

                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">Profit in Lieu u/s 17(3)</label>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                disabled={disable_field}
                                                                className="form-control"
                                                                name="profit_in_lieu_amtp"
                                                                value={values.profit_in_lieu_amtp}
                                                                placeholder="0.00"
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue(
                                                                        'profit_in_lieu_amtp',
                                                                        e.target.value,
                                                                    );
                                                                    totalGrossSalary('profit_in_lieu_amtp', e.target.value, setFieldValue, values);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('profit_in_lieu_amtp', roundUpValue(e.target.value));
                                                                    totalGrossSalary('profit_in_lieu_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                }}
                                                            />
                                                            {errors.profit_in_lieu_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.profit_in_lieu_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_profit_in_lieu_amtp && salaryData.old_profit_in_lieu_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.profit_in_lieu_amtp_color ? `#${salaryData.profit_in_lieu_amtp_color}` : '#0000ff' }}>{salaryData.old_profit_in_lieu_amtp}</div>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-12 pt-2">
                                            <div className="card grosssalaryph3card">
                                                <div className="card-header">
                                                    Allowances Exempt u/s 10
                                                    <span className="float-right">Total = <span className=" fsizeiconrup"><FontAwesomeIcon icon={faRupeeSign} /></span> {formatNumber(values.total_allowance_exempt_amtp)}</span>
                                                    {(componentImport && salaryData && salaryData.old_total_allowance_exempt_amtp && salaryData.old_total_allowance_exempt_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.total_allowance_exempt_amtp_color ? `#${salaryData.total_allowance_exempt_amtp_color}` : '#0000ff' }}>{formatNumber(salaryData.old_total_allowance_exempt_amtp)}</div>) : null}

                                                </div>

                                                {values.allowances.map((allowance_data, allowance_index) => {
                                                    return (<div className="card-body" key={allowance_index}>
                                                        <div className="row">
                                                            <div className="form-group col-md-4">
                                                                <label className="">Type of Allowance</label>
                                                                <select
                                                                    name="allowance_type_id"
                                                                    className="custom-select"
                                                                    disabled={disable_field}
                                                                    onChange={(e) => {
                                                                        changeAllowance(e, 'allowance_type_id', allowance_index, setFieldValue, values)
                                                                    }}
                                                                    value={allowance_data.allowance_type_id}>
                                                                    <option value="0">Select</option>
                                                                    {allowanceList && allowanceList[values.tax_regime].map((data, index) => {
                                                                        if (isAllowanceUse(data.allowance_type_id, allowance_data.allowance_type_id, values.allowances)) {
                                                                            return (<option key={index} value={data.allowance_type_id}>{data.allowance_type_name}</option>)
                                                                        }
                                                                        else {
                                                                            return false;
                                                                        }
                                                                    })}
                                                                </select>

                                                                {errors.allowances && errors.allowances[allowance_index] && errors.allowances[allowance_index].allowance_type_id ? (
                                                                    <span className="error_message">
                                                                        {errors.allowances[allowance_index].allowance_type_id}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group col-md-4 pr-0">
                                                                <label className="">Exempt Amt</label>
                                                                <NumericInputWithDecimalTwo
                                                                    type="text"
                                                                    disabled={disable_field}
                                                                    className="form-control"
                                                                    name="exempt_amtp"
                                                                    value={allowance_data.exempt_amtp}
                                                                    placeholder="0.00"
                                                                    onChange={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value)) return;
                                                                        changeAllowance(e, 'exempt_amtp', allowance_index, setFieldValue, values)
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (!tdsReturnValidNumber(e.target.value)) return;
                                                                        changeAllowance(e, 'exempt_amtp', allowance_index, setFieldValue, values, true)
                                                                    }}
                                                                />
                                                                {errors.allowances && errors.allowances[allowance_index] && errors.allowances[allowance_index].exempt_amtp ? (
                                                                    <span className="error_message">
                                                                        {errors.allowances[allowance_index].exempt_amtp}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group col-md-4 pl-0">
                                                                <br />
                                                                <span className="threedoticonpadd_delicon">
                                                                    {allowance_data.allowance_type_id > 0 && parseInt(allowance_data.allowance_type_id) !== 3 && parseInt(allowance_data.allowance_type_id) !== 7 && <span className="pr-1 threedoticonpadd"><img onClick={() => setShowAllowancePopup(allowance_index)} src={filedoticon} alt="" width="5" title="Enter details for calculation of exempt amount" /></span>}
                                                                    {allowance_index > 0 && <img src={deleteIcon} alt="" width="16" onClick={() => { removeAllowance(allowance_index, setFieldValue, values) }} />}

                                                                    <span className="alladdiconbox ">
                                                                        {allowance_index === parseInt(values.allowances.length) - 1 && allowanceList && values.allowances.length < allowanceList[values.tax_regime].length && <img src={addBtnRoundIcon} alt="" onClick={() => { addAllowance(setFieldValue, values) }} />}
                                                                    </span>

                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>)
                                                })}

                                                {showAllowancePopup >= 0 &&
                                                    <Allowancedetails
                                                        show={showAllowancePopup}
                                                        handleClose={() => setShowAllowancePopup(-1)}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        allowanceList={allowanceList[values.tax_regime]}
                                                        getTotalAllowanceAmtp={getTotalAllowanceAmtp}
                                                    />}
                                            </div>
                                        </div>


                                        <div className="col-md-12 pt-2">
                                            <div className="card grosssalaryph3card">
                                                <div className="card-header">
                                                    Deduction u/s 16<span className="float-right">Total = <span className=" fsizeiconrup"><FontAwesomeIcon icon={faRupeeSign} /></span> {formatNumber(values.total_deduction_amtp)}</span>
                                                    {(componentImport && salaryData && salaryData.old_total_deduction_amtp && salaryData.old_total_deduction_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.total_deduction_amtp_color ? `#${salaryData.total_deduction_amtp_color}` : '#0000ff' }}>{formatNumber(salaryData.old_total_deduction_amtp)}</div>) : null}

                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">Standard Deduction(ia)</label>

                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                className="form-control"
                                                                name="standard_deduction_amtp"
                                                                value={values.standard_deduction_amtp}
                                                                readOnly={true}
                                                                placeholder="0.00"
                                                            />


                                                            {errors.standard_deduction_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.standard_deduction_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_standard_deduction_amtp && salaryData.old_standard_deduction_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.standard_deduction_amtp_color ? `#${salaryData.standard_deduction_amtp_color}` : '#0000ff' }}>{salaryData.old_standard_deduction_amtp}</div>) : null}
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">Ent. Allowance 16(i)</label>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                disabled={disable_field}
                                                                className="form-control"
                                                                name="ent_allowance_amtp"
                                                                value={values.ent_allowance_amtp}
                                                                readOnly={!values.is_government_deductor || values.tax_regime === 'NEW'}
                                                                placeholder="0.00"
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('ent_allowance_amtp', e.target.value);
                                                                    totalDeductionAmt('ent_allowance_amtp', e.target.value, setFieldValue, values);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('ent_allowance_amtp', roundUpValue(e.target.value));
                                                                    totalDeductionAmt('ent_allowance_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                }}
                                                            />
                                                            {errors.ent_allowance_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.ent_allowance_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_ent_allowance_amtp && salaryData.old_ent_allowance_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.ent_allowance_amtp_color ? `#${salaryData.ent_allowance_amtp_color}` : '#0000ff' }}>{salaryData.old_ent_allowance_amtp}</div>) : null}
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">Professional Tax 16( iii)</label>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                disabled={disable_field}
                                                                className="form-control"
                                                                name="professional_tax_amtp"
                                                                value={values.professional_tax_amtp}
                                                                readOnly={values.tax_regime === 'NEW'}
                                                                placeholder="0.00"
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('professional_tax_amtp', e.target.value);
                                                                    totalDeductionAmt('professional_tax_amtp', e.target.value, setFieldValue, values);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('professional_tax_amtp', roundUpValue(e.target.value));
                                                                    totalDeductionAmt('professional_tax_amtp', roundUpValue(e.target.value), setFieldValue, values);
                                                                }}
                                                            />
                                                            {errors.professional_tax_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.professional_tax_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_professional_tax_amtp && salaryData.old_professional_tax_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.professional_tax_amtp_color ? `#${salaryData.professional_tax_amtp_color}` : '#0000ff' }}>{salaryData.old_professional_tax_amtp}</div>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 pt-2">
                                            <div className="card grosssalaryph3card">
                                                <div className="card-header">
                                                    Any Previous Employer ?
                                                    <span className="float-right">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="previous_employer"
                                                                value="1"
                                                                disabled={disable_field}
                                                                className="form-check-input"
                                                                type="radio"
                                                                checked={values.previous_employer ? true : false}
                                                                onChange={() => { setFieldValue('previous_employer', true) }} />
                                                            <label className="form-check-label" htmlFor="">Yes</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                name="previous_employer"
                                                                value="0"
                                                                disabled={disable_field}
                                                                className="form-check-input"
                                                                type="radio"
                                                                checked={!values.previous_employer ? true : false}
                                                                onChange={() => {
                                                                    setFieldValue('previous_employer', 0);
                                                                    setFieldValue('previous_employer_amount_received_amtp', 0);
                                                                    setFieldValue('previous_employer_tds_amtp', 0);
                                                                    totalNetSalary(values.total_gross_salary_amtp, values.total_deduction_amtp, values.total_allowance_exempt_amtp, 0, values, setFieldValue);
                                                                }} />
                                                            <label className="form-check-label" htmlFor="">No</label>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">Amt Received</label>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                disabled={disable_field}
                                                                className="form-control"
                                                                name="previous_employer_amount_received_amtp"
                                                                value={values.previous_employer_amount_received_amtp}
                                                                readOnly={!values.previous_employer}
                                                                placeholder="0.00"
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('previous_employer_amount_received_amtp', e.target.value);
                                                                    totalNetSalary(values.total_gross_salary_amtp, values.total_deduction_amtp, values.total_allowance_exempt_amtp, e.target.value ? e.target.value : 0, values, setFieldValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('previous_employer_amount_received_amtp', roundUpValue(e.target.value));
                                                                    totalNetSalary(values.total_gross_salary_amtp, values.total_deduction_amtp, values.total_allowance_exempt_amtp, e.target.value ? roundUpValue(e.target.value) : 0, values, setFieldValue);
                                                                }}
                                                            />
                                                            {errors.previous_employer_amount_received_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.previous_employer_amount_received_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_previous_employer_amount_received_amtp && salaryData.old_previous_employer_amount_received_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.previous_employer_amount_received_amtp_color ? `#${salaryData.previous_employer_amount_received_amtp_color}` : '#0000ff' }}>{salaryData.old_previous_employer_amount_received_amtp}</div>) : null}
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="" className="">TDS Amt</label>
                                                            <NumericInputWithDecimalTwo
                                                                type="text"
                                                                disabled={disable_field}
                                                                className="form-control"
                                                                name="previous_employer_tds_amtp"
                                                                value={values.previous_employer_tds_amtp}
                                                                placeholder="0.00"
                                                                readOnly={!values.previous_employer}
                                                                onChange={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('previous_employer_tds_amtp', e.target.value);
                                                                    values.previous_employer_tds_amtp = e.target.value;
                                                                    totalNetTaxableIncome(values, setFieldValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    if (!tdsReturnValidNumber(e.target.value)) return;
                                                                    setFieldValue('previous_employer_tds_amtp', roundUpValue(e.target.value));
                                                                    values.previous_employer_tds_amtp = roundUpValue(e.target.value);
                                                                    totalNetTaxableIncome(values, setFieldValue);
                                                                }}
                                                            />
                                                            {errors.previous_employer_tds_amtp ? (
                                                                <span className="error_message">
                                                                    {errors.previous_employer_tds_amtp}
                                                                </span>
                                                            ) : null}
                                                            {(componentImport && salaryData && salaryData.old_previous_employer_tds_amtp && salaryData.old_previous_employer_tds_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.previous_employer_tds_amtp_color ? `#${salaryData.previous_employer_tds_amtp_color}` : '#0000ff' }}>{salaryData.old_previous_employer_tds_amtp}</div>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2 mb-3 pb-4">
                                            <div className="salarydtopm">
                                                <span className="netsalarytext">Net Salary</span>
                                                <span className="float-right fsizeiconrup" style={{ textAlign: 'right' }}>
                                                    <span className="pr-1"><FontAwesomeIcon icon={faRupeeSign} />
                                                        {formatNumber(values.net_salary_amtp)}</span>
                                                    {errors.net_salary_amtp ? (<>
                                                        <br />                                                    <span className="error_message">
                                                            {errors.net_salary_amtp}
                                                        </span>
                                                    </>) : null}
                                                    {(componentImport && salaryData && salaryData.old_net_salary_amtp && salaryData.old_net_salary_amtp > 0) ? (<div className="error_message" style={{ color: salaryData.net_salary_amtp_color ? `#${salaryData.net_salary_amtp_color}` : '#0000ff' }}>{formatNumber(salaryData.old_net_salary_amtp)}</div>) : null}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            {/* <div className="savebtnxsdisblock"> */}
                                            <button type="submit" id="salary_details_submit" data-popup="other_income" onClick={() => setFieldValue('save_type', 'other_income')} className="btn btn-default grossph3btnblueclr mr-2 ml-2 mb-1">NEXT</button>
                                            <button type="submit" id="save-new-salary-details" onClick={() => setFieldValue('save_type', 'new')} className="btn btn-default grossph3btnblueclr mb-1">SAVE & NEW </button>
                                            <button type="submit" id="save-salary-details" onClick={() => setFieldValue('save_type', 'save')} className="btn btn-default grossph3btnblueclr mr-2 ml-2 mb-1">SAVE</button>
                                            <button type="button" className="btn btn-default grossph3btnblueclr mb-1" onClick={() => viewComputation(values)}>VIEW COMPUTATION</button>
                                            {/* </div> */}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Tooltip>
                    </Form>
                </>
                );
            }}
        </Formik>
        // </div >
    );
};

export default SalaryDetails;