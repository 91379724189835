/* eslint-disable no-throw-literal */

import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleSuccess, handleError, handleConnectorError, handleErrorWithCode } from './responseHandler';
import { getConfig, getConfigForDeleteHttpVerb } from './common';
const connectorError = "Unable to connect to the Express Connector. Please ensure that the Express Connector is running. If the problem still persists, then Close the Browser completely and restart.";


export async function getIssueRequestStatus(deductorId) {
  const url = `${BASE_URL}/api/v1/tds-tcs-cert/status?deductor_id=${deductorId}`;


  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function updateIssueRequestStatus(data) {
  const url = `${BASE_URL}/api/v1/tds-tcs-cert/status`;

  try {
    const deductorData = await axios.post(url, data, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }

    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
}


export async function checkRaiseRequestFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/traces/update-request-status `;

  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      }
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function postPdfGenFromConnector(port, data, headers) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/tds/tds-tcs-cert/generate-pdf`;
  try {
    const result = await axios.post(url, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      }
    });
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}



export async function getPdfGenStatusFromConnector(port, processId) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/process-status${processId}`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}


export async function getDscList(port) {
  if (!port) throw { code: 404, message: connectorError };

  const url = `http://127.0.0.1:${port}/api/v1/dsc-list`;

  try {
    const result = await axios.get(url);
    return result.data;
  } catch (err) {
    const response = handleConnectorError(err);
    if (response.code === 404) {
      throw { code: 404, message: connectorError };
    } else {
      throw response;
    }
  }
}

export async function gocallApi(type = '', geturl = '', data = null, needCompleteResult = false) {
  
  if (geturl === '' || type === '') return;
  let url = `${BASE_URL}${geturl}`;
  let result = {}
  try {
    switch (type.toLocaleLowerCase()) {
      case 'get':
        result = await axios.get(url, getConfig());
        break;
      case 'put':
        result = await axios.put(url, data, getConfig());
        break;
      case 'post':
        result = await axios.post(url, data, getConfig());
        break;
      case 'delete':
        result = await axios.delete(url, getConfigForDeleteHttpVerb(data));
        break;
      default:
    }

    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    if (needCompleteResult) {
      return handleSuccess(result);
    } else {
      return handleSuccess(result.data);
    }
  }
  catch (err) {
    throw handleError(err);
  }

}

export async function getDownloadPdfRaiseRequest(data) {
  const url = `${BASE_URL}/api/v1/tds-tcs-cert/download-pdf`;
  try {
    const result = await axios.post(url, data, getConfig());
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getDownloadPdfRaiseRequestV2(data) {
  const url = `${BASE_URL}/api/v2/tds-tcs-cert/download-pdf`;
  try {
    const result = await axios.post(url, data, getConfig());
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getDeducteeListSendMail(data) {
  const url = `${BASE_URL}/api/v1/tds-tcs-cert/deductee/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }

}

export async function getDeducteeSummarySendMail(data) {
  const url = `${BASE_URL}/api/v1/tds-tcs-cert/deductee/summary`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleError(err);
  }

}

export async function sendEmailtoDeductee(data) {
  const url = `${BASE_URL}/api/v1/tds-tcs-cert/send-mail`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function exportRegularHealthReport(params) {
  const url = `${BASE_URL}/api/v1/report/health/export${params}`;
  try {
    const config = { ...getConfig() };
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}



