import React, { useState } from 'react';
import { getFormObject, getDeducteeTransactionsObject } from '../../components/createDeducteeTransactionsForm/DeducteeTransactionsFormHelper';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { updateDeducteeTransaction } from '../../api/deducteeTransactionsAPI';
import CreateDeducteeTransactionsFormV2 from '../../components/createDeducteeTransactionsForm/CreateDeducteeTransactionsFormV2';

const EditDeducteeTransactionsV2 = (props) => {
  const { onClose, deductorId, returnId, deducteeTransactionID, deducteeTrnsactnInfo, headerDataDetails, componentImport, correctionDeducteeTrnsactnInfo,
    updateDeducteeInfos, deducteeTransactionsList, currentDeductee, setCurrentDeductee, horizontalView, setConfirmPopup, confirmPopup, setShowEditForm, formType,
    onDTSave, ldcListStatus = false
  } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);



  async function createDeducteeTransactions(data, closeForm = true) {
    setError(null);
    setSuccess(null);
    const deducteeTransactionsData = getDeducteeTransactionsObject(data);
    deducteeTransactionsData.deductee_transaction_id = deducteeTrnsactnInfo.deductee_transaction_id;
    deducteeTransactionsData.deductor_id = deductorId;
    deducteeTransactionsData.return_id = returnId;
    if (headerDataDetails.form_type === "24Q") {
      deducteeTransactionsData.tds_rate_b100 = null;
    }

    async function callAPI() {
      async function updateDeducteeTransactionsProcess() {
        const result = await updateDeducteeTransaction(deducteeTransactionsData);
        return result;
      }
      try {
        const result = await updateDeducteeTransactionsProcess();
        setSuccess(result.message);
        if (closeForm) {
          setTimeout(() => {
            onClose(true);
          }, 2000)
        }
        if (onDTSave) { onDTSave(true) }
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    callAPI();
  }




  return (
    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      <CreateDeducteeTransactionsFormV2
        onClose={() => onClose()}
        createDeducteeTransactions={createDeducteeTransactions}
        returnId={returnId}
        deductorId={deductorId}
        deducteeTransactionFormInfo={getFormObject(deducteeTrnsactnInfo, headerDataDetails.form_type)}
        deducteeTransactionID={deducteeTransactionID}
        headerDataDetails={headerDataDetails}
        componentImport={componentImport}
        correctionDeducteeTrnsactnInfo={correctionDeducteeTrnsactnInfo}
        updateDeducteeInfos={updateDeducteeInfos}
        deducteeTransactionsList={deducteeTransactionsList}
        currentDeductee={currentDeductee}
        setCurrentDeductee={setCurrentDeductee}
        horizontalView={horizontalView}
        show={true}
        setConfirmPopup={setConfirmPopup}
        confirmPopup={confirmPopup}
        setShowEditForm={setShowEditForm}
        formType={formType}
        onDTSave={onDTSave}
        ldcListStatus={ldcListStatus}
      />

    </div>

  );
};

export default EditDeducteeTransactionsV2;
