import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import './UserList.css';
import "../../components/profile/Profile.css"
import "./UserManagement.scss"
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import queryString from 'query-string';
import { gocallApi } from '../../api/issueCertificateAPI';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { DEDUCTOR_CATEGORIES, DEDUCTOR_CATEGORIES_TYPES } from '../../app/constants';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import { Tooltip } from '@progress/kendo-react-tooltip';

const ListAndManageClients = (prop) => {
  const { deducteeSummary, handleSuccess } = prop;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [deductorList, setDeductorList] = useState([]);
  //  const [deducteeSummary, setDeducteeSummary] = useState([]);
  //const [deducteeAssignedOnlySummary, setDeducteeAssignedOnlySummary] = useState([]);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [dataState, setDataState] = useState({});
  const userId = params?.user_id ?? ""
  const searchStr = location.search;
  const limit = 15;
  const [selectedIds, setSelectedIds] = useState([]);
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [initialSelectedIds, setInitialSelectedIds] = useState([]); // Original selected items
  const [currentSelectedIds, setCurrentSelectedIds] = useState([]); // User-modified selection
  const [filterPostData, setFilterPostData] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [allSelectedTabUnassign, setAllSelectedTabUnassign] = useState(null);
  const [allSelectedTabAll, setAllSelectedTabAll] = useState(null);


  const getAssignedClientList = async (postData) => {
    postData.page && postData.page > 1 ? setShowMoreLoading(true) : setLoading(true);
    let url = `/api/v1/user/deductor/list`
    try {
      const result = await gocallApi("post", url, postData)

      let new_DeductorList = [];
      if (deductorList?.length > 0) new_DeductorList = deductorList;
      if (result?.data?.cursor?.current_page > 1) {
        new_DeductorList = new_DeductorList.concat(result?.data?.list ?? []);
      } else {
        new_DeductorList = result?.data?.list ?? [];
      }
      setDeductorList(new_DeductorList);
      setCursor(result?.data?.cursor ?? {});


      if (params?.tab === "3" && result?.data?.list) {
        const initiallySelected = result?.data?.list
          ?.filter((item) => item.assigned)
          .map((item) => ({ deductor_id: item.deductor_id, tan: item.tan }));

        setInitialSelectedIds((prevIds) => {
          const prevTanNos = new Set(prevIds.map((item) => item.tan)); // Existing tan numbers
          const newIds = initiallySelected?.filter((item) => !prevTanNos.has(item.tan)); // Check by tan
          return [...prevIds, ...newIds]; // Append only new items
        });

        setCurrentSelectedIds((prevIds) => {
          const prevTanNos = new Set(prevIds.map((item) => item.tan)); // Existing tan numbers
          const newIds = initiallySelected?.filter((item) => !prevTanNos.has(item.tan)); // Check by tan
          return [...prevIds, ...newIds]; // Append only new items
        });
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setShowMoreLoading(false);
    }
  }

  // const getSummaryAllAndUnAssignedClient = async () => {
  //   let url = `/api/v1/user/deductor/summary`
  //   let payload = {
  //     assigned: params?.tab === "2" ? 0 : 2,
  //     user_id: userId,
  //   }
  //   try {
  //     const result = await gocallApi("post", url, payload)
  //     setDeducteeSummary(result?.data ?? 0)
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // }

  // const getSummaryAssignedOnlyClient = async () => {
  //   let url = `/api/v1/user/deductor/summary`
  //   let payload = {
  //     assigned: 1,
  //     user_id: userId,
  //   }
  //   try {
  //     const result = await gocallApi("post", url, payload)
  //     setDeducteeAssignedOnlySummary(result?.data ?? 0)
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // }

  useEffect(() => {
    const postData = getParamsHistoryUrl();
    getAssignedClientList(postData);

    setAllSelectedTabUnassign(null);
    setAllSelectedTabAll(null);
    setSelectedIds([]);
    
    //reset filters on new tab
    if (!postData.filters) {
      resetDataStateFilters();
    }
       
    // eslint-disable-next-line
  }, [searchStr, success]);

  const resetDataStateFilters = () => {
    setDataState((prevState) => ({
      ...prevState,
      filter: null, // Reset the filter property
    }));
  };

  const getParamsHistoryUrl = () => {
    const postData = {
      user_id: params?.user_id,
      usr: params?.usr,
      assigned: (params?.tab === "2") ? 0 : 2,
    };

    if (params?.sort_on) {
      dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
      postData.sort_on = params.sort_on ? params.sort_on : '';
      postData.order_by = params.order_by ? params.order_by : "";
    }

    if (params?.filters) {
      dataState.filter = { filters: [] };
      dataState.filter.filters = JSON.parse(atob(params.filters));
      postData.filters = JSON.parse(atob(params.filters));
    }

    if (params?.page) {
      postData.page = params.page;
    } else {
      postData.page = 1;
    }
    postData.limit = params?.limit ?? limit;

    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };

  // const toggleSelected = (checked) => {
  //   setAllSelected(() => checked);
  //   const selected_ids = new Set();
  //   if (checked) {
  //     if (deductorList.length > 0) {
  //       deductorList.forEach((item) => {
  //         selected_ids.add(item?.deductor_id);
  //       })
  //     }
  //   } else {
  //     selected_ids.clear();
  //   }

  //   if (params?.tab === "2") {
  //     setSelectedIds(() => {
  //       return [...selected_ids]; // Add IDs
  //     });
  //   }

  //   if (params?.tab === "3") {
  //     setCurrentSelectedIds((prevSelectedIds) => {
  //       if (checked) {

  //         if (deductorList.length > 0) {
  //           deductorList.forEach((item) => {
  //             selected_ids.add({ item?.deductor_id, item?.tan });
  //           })
  //         }

  //         if (!prevSelectedIds.some((item) => item.tan === tan)) {
  //           return [...prevSelectedIds, ...selected_ids];
  //         }
  //         return prevSelectedIds; // No change if duplicate
  //       } else {
  //         return [...selected_ids];
  //       }
  //     });
  //   }
  // }


  const toggleSelected = (checked) => {

    // Initialize a Set to store selected IDs or objects
    const selected_ids = new Set();

    if (checked && deductorList.length > 0) {
      // Populate selected_ids when checkbox is checked
      if (params?.tab === "2") {
        setAllSelectedTabUnassign(checked);
        deductorList.forEach((item) => {
          selected_ids.add(item?.deductor_id);
        });
      }

      if (params?.tab === "3") {
        setAllSelectedTabAll(checked);
        deductorList.forEach((item) => {
          selected_ids.add({ deductor_id: item?.deductor_id, tan: item?.tan });
        });
      }
    } else {
      setAllSelectedTabUnassign(null);
      setAllSelectedTabAll(null);
    }

    if (params?.tab === "2") {
      setSelectedIds(() => [...selected_ids]); // Convert Set to Array
    }

    if (params?.tab === "3") {
      setCurrentSelectedIds((prevSelectedIds) => {
        if (checked) {
          const uniqueIds = [...prevSelectedIds];

          selected_ids.forEach((newItem) => {
            // Avoid duplicates by checking existing TANs
            if (!uniqueIds.some((item) => item.tan === newItem.tan)) {
              uniqueIds.push(newItem);
            }
          });

          return uniqueIds;
        } else {
          // Clear selection when unchecked
          return [...selected_ids];
        }
      });
    }
  };


  const singleSelect = (checked, dataItem) => {
    const { deductor_id, tan } = dataItem;

    if (params?.tab === "2") {
      setSelectedIds((prevSelectedIds) => {
        if (checked) {
          return [...prevSelectedIds, deductor_id]; // Add ID
        } else {
          return prevSelectedIds.filter((item) => item !== deductor_id); // Remove ID
        }
      });

    } else {
      setCurrentSelectedIds((prevSelectedIds) => {
        if (checked) {
          // Add only if `tan` is not already present
          if (!prevSelectedIds.some((item) => item.tan === tan)) {
            return [...prevSelectedIds, { deductor_id, tan }];
          }
          return prevSelectedIds; // No change if duplicate
        } else {
          // Remove by `tan`
          return prevSelectedIds.filter((item) => item.tan !== tan);
        }
      });
    }
  };

  // Submit selected deductor IDs
  const handleUnassignSubmit = async () => {
    const url = `/api/v1/user/deductor/assign-unassign`
    const payload = {
      user_id: userId,
      deductor_ids: selectedIds?.length > 0 ? selectedIds.map((id) => ({
        deductor_id: id,
        assign: 1,
      })) : {},
    }
    try {
      const result = await gocallApi("post", url, payload)
      setSelectedIds([]) //reset count
      setSuccess(result?.message);
      handleSuccess();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAssignAndUnassignSubmit = async () => {
    const url = `/api/v1/user/deductor/assign-unassign`

    // Extract the new selections and removals based on `deductor_id` only
    const additions = currentSelectedIds.filter(
      (item) => !initialSelectedIds.some((initialItem) => initialItem.deductor_id === item.deductor_id)
    ); // Newly selected by matching `deductor_id`

    const removals = initialSelectedIds.filter(
      (item) => !currentSelectedIds.some((currentItem) => currentItem.deductor_id === item.deductor_id)
    ); // Newly unselected by matching `deductor_id`

    // Merge into a single array with the proper `assign` values
    const deductor_ids = [
      ...additions.map((item) => ({ deductor_id: item.deductor_id, assign: 1 })),
      ...removals.map((item) => ({ deductor_id: item.deductor_id, assign: 0 })),
    ];

    const payload = {
      user_id: userId,
      deductor_ids: deductor_ids
    }
    try {
      const result = await gocallApi("post", url, payload)
      setCurrentSelectedIds([]) //reset count
      setInitialSelectedIds([]) //reset count
      setSuccess(result?.message);
      handleSuccess();
    } catch (err) {
      setError(err.message);
    }
  };

  const rowRender = (trElement, props) => {
    let dataItem = props.dataItem ?? {};
    const key = trElement._owner.index;
    const city = dataItem.city ? dataItem.city?.toLowerCase() : "";
    if (dataItem?.deductor_category === "Z") {
      dataItem.deductor_category = "";
    }

    const deactiveItemCss = dataItem?.deductor_status !== 1 ? "deactive-text" : "";

    return (
      <tr key={key}>
        <td><div>
          <input
            type="checkbox"
            onChange={(e) => singleSelect(e.target.checked, dataItem)}
            checked={
              params?.tab === "2"
                ? selectedIds.includes(dataItem?.deductor_id)
                : currentSelectedIds.some((item) => item.tan === dataItem?.tan)
            }
            value={dataItem?.deductor_id}
          /></div>
        </td>
        <td className={`text-left textoverflow overflowtooltipconttdsamt ${deactiveItemCss}`}>
          <span data-toggle="tooltip" data-placement="top" title={dataItem?.deductor_name}>
            {dataItem?.deductor_name}
          </span>
        </td>
        <td className={`text-center ${deactiveItemCss}`}>
          {dataItem?.tan}
        </td>
        <td className={`text-left textoverflow overflowtooltipconttdsamt text-capitalize ${deactiveItemCss}`} title={city}>
          {city}
        </td>
        <td className={`text-left textoverflow overflowtooltipconttdsamt text-capitalize ${deactiveItemCss}`}>
          {dataItem?.deductor_category && <span>{DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? "-"}</span>}
        </td>
        <td className={`text-center ${deactiveItemCss}`}>
          {dataItem?.mobile_no}
        </td>
        <td className={`text-left textoverflow overflowtooltipconttdsamt ${deactiveItemCss}`} title={dataItem?.email}>
          {dataItem?.email}
        </td>
      </tr>
    )
  };


  const createHistoryUrl = (filters) => {

    let filterStr = `?page=1&tab=${params?.tab ?? "1"}`
    if (params?.user_id) {
      filterStr += `&user_id=${params.user_id}&usr=${params.usr}`;
    }

    if (filters?.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(filters?.filter?.filters))}`;
    }

    if (filters?.sort && filters.sort.length > 0) {
      filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
    }

    history.push(filterStr);
  };

  const dataStateChange = (dataState) => {
    createHistoryUrl(dataState);
    setDataState(dataState);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState?.filter) {
      dataState?.filter.filters.map((filter, index) => {
        if (filter?.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };

  const hasDifference = (array1, array2) => {
    const pans1 = new Set(array1.map((item) => item.tan));
    const pans2 = new Set(array2.map((item) => item.tan));

    // Check if there are items in array1 not in array2 or vice versa
    const isDifferent =
      array1.some((item) => !pans2.has(item.tan)) ||
      array2.some((item) => !pans1.has(item.tan));

    return isDifferent;
  };

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      <div className="row">
        <div className="col-md-12">
          <p className="sd_usermanage_zeroclients_ass_textfs pt-1">
          {
            params?.tab === "2" ? (
              selectedIds.length > 0 ? `${selectedIds.length} Unassigned Deductors Selected` : null
            ) : (
              (deducteeSummary?.assigned + (currentSelectedIds.length - initialSelectedIds.length)) > 0
                ? `${deducteeSummary?.assigned + (currentSelectedIds.length - initialSelectedIds.length)} Deductors Selected`
                : null
            )
          }
          </p>
        </div>

        <div className="col-md-12">
          <div className="card " style={{ padding: '0px' }}>

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              <Tooltip anchorElement="pointer" position="right" >
                <Grid
                  className="table table-striped sd_usermanage_manageassing_table mb-0"
                  data={deductorList}
                  rowRender={rowRender}
                  {...dataState}
                  sortable
                  onDataStateChange={(event) => dataStateChange(event.dataState)}
                  filterOperators={{
                    text: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                    ],
                    dropdown: [
                      { text: 'grid.filterEqOperator', operator: 'in' },
                    ],
                    numeric: [
                      { text: 'grid.filterGteOperator', operator: 'gte' },
                      { text: 'grid.filterLteOperator', operator: 'lte' },
                      { text: 'grid.filterLtOperator', operator: 'lt' },
                      { text: 'grid.filterGtOperator', operator: 'gt' },
                      { text: 'grid.filterEqOperator', operator: 'eq' },
                      { text: 'grid.filterNotEqOperator', operator: 'neq' },
                    ],
                    textWithEmpty: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                      { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                    ],
                  }}
                  style={{ maxHeight: '555px', overflowX: 'hidden' }}
                >
                  <Column
                    width="22"
                    field=""
                    headerCell={() => {

                      return (
                        <div>
                          <span>
                            <input
                              type="checkbox"
                              checked={params?.tab === "2" ? allSelectedTabUnassign : allSelectedTabAll}
                              onChange={(e) => toggleSelected(e.target.checked)}
                              className="checkboxvertalaign"
                            />
                          </span>
                        </div>
                      );


                    }}
                  />
                  <Column
                    width="250"
                    field="deductor_name"
                    filter="text"
                    title="Deductor Name"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                  />

                  <Column
                    width="100"
                    field="tan"
                    filter="text"
                    title='TAN'
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('tan') ? 'active' : ''}
                  />

                  <Column
                    width="120"
                    field="city"
                    filter="text"
                    title='Location'
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('city') ? 'active' : ''}

                  />
                  <Column
                    width="140"
                    field="deductor_category"
                    title='Company Type'
                    filter="dropdown"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter filterList={DEDUCTOR_CATEGORIES_TYPES} {...props} />)}
                    headerClassName={isColumnActive('deductor_category') ? 'active' : ''}
                  />

                  <Column
                    width="120"
                    field="mobile_no"
                    title="Mobile No."
                    filter="text"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('mobile_no') ? 'active' : ''}
                  />

                  <Column
                    width="180"
                    field="email"
                    filter="text"
                    title="Email Address"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('email') ? 'active' : ''}
                  />
                </Grid>
              </Tooltip>
            </div>
            <div className="col-md-12 pb-2 text-center">
              <ShowMorePagination
                cursor={cursor}
                fetchData={getAssignedClientList}
                postData={filterPostData}
                loading={showMoreLoading}
              />
            </div>

          </div>
        </div>

        <div className="col-md-12 pt-2 text-center">

          {params?.tab === "2" &&
            <button
              className="btn btn-deauld sd_usermanage_assing_submitbtn mb-1"
              onClick={() => { handleUnassignSubmit() }} disabled={selectedIds.length === 0}>
              Submit
            </button>
          }

          {params?.tab === "3" &&
            <button
              className="btn btn-deauld sd_usermanage_assing_submitbtn  mb-1"
              disabled={!hasDifference(currentSelectedIds, initialSelectedIds)}
              onClick={() => { handleAssignAndUnassignSubmit() }}>
              Submit
            </button>
          }

        </div>
        {params?.tab === "3" &&
          <div>
            <p className="sd_usermanage_zeroclients_ass_textfs mt-0 mb-0 ml-3">Unchecked Deductor - It is not selected to be assigned against the user. </p>
            <p className="sd_usermanage_zeroclients_ass_textfs mt-0 mb-0 ml-3">Checked Deductor - It is selected to be assigned against the user.</p>
          </div>
        }
      </div>
    </>
  );
};

export default ListAndManageClients;
