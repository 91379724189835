import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleErrorWithCode, handleSuccess } from './responseHandler';
import { getConfig } from './common';

export async function getCorrectionTDScomputationSumary(getParams) {

  const url = `${BASE_URL}/api/v1/correction/computation/summary${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}


export async function getCorrectionInterestBreakup(getParams) {
  const url = `${BASE_URL}/api/v1/correction/computation/interest-breakup${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getReturnsFilingData(getParams) {
  const url = `${BASE_URL}/api/v1/returns/filing-data${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getVerifiedChallanCountData(getParams) {

  const url = `${BASE_URL}/api/v1/returns/verified-challan-count${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getVerifiedPanCountData(getParams) {
  const url = `${BASE_URL}/api/v1/report/deductor/pan-status-wise-deductee-summary${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
export async function getLateFeeData(getParams) {
  const url = `${BASE_URL}/api/v1/report/fvu/late-fee-info${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function updateFiling(data) {
  const url = `${BASE_URL}/api/v1/returns/filing-data`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function downloadRegularReturnTxtFile(getParams) {

  const url = `${BASE_URL}/api/v1/return/regular/export-txt${getParams}`;
  try {
    const result = await axios.get(url, getConfig());
    return result;    
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getLowerDeductionCertificateData(getParams) {
  const url = `${BASE_URL}/api/v1/report/health/ldc/list${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}