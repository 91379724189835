/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './ConfirmPopup.css';
function ConfirmPopupNew(props) {
  const {
    show, handleClose, handleProceed, confirmText, confirmButtonText, confirmButtonText2, handleProceed2, showHeader, modalclassName
  } = props;

  return (
    <Modal className={`alertbox_popup ${modalclassName}`} show={show} backdrop={false} onHide={handleClose}>     
    {showHeader && <Modal.Header closeButton={handleClose}>{showHeader} <p></p></Modal.Header> }
      <Modal.Body>
        
        <p>{confirmText ?? 'Are you sure that you want to delete the Challan ? Please note that the process is irreversible. Click Delete button to continue.'}</p></Modal.Body>
      <Modal.Footer className="areusuredelmboxF">        
        <Button className="btnfscof" variant="danger" onClick={handleProceed}>
          {confirmButtonText ?? 'Confirm'}
        </Button>
        {confirmButtonText2 ?
          <Button className="btnfscof" variant="danger" onClick={handleProceed2}>
            {confirmButtonText2 ?? 'Confirm'}
          </Button> : null}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmPopupNew;
