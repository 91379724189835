/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './DeducteeTransactions.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import nuterrowicon from '../../images/nuterrowicon.png';
import editIcon from '../../images/icons/edit.svg';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { CustomCell, CustomDataCell } from '../../components/grid/CustomCell';

import NatureOfPaymentSummaryPopup from './NatureOfPaymentSummaryPopup';
import EditDeducteeTransactions from './EditDeducteeTransactions';
import AddDeducteeTransactions from './AddDeducteeTransactions';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';

import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { formatNumber, capitalizeEachWordFirstLetter, createImportRegularReturnLocalStorageKey, deepClone } from '../../utils/UtilityFunctions';

import ShowAlert from '../../components/ShowAlert';
import '../../components/tdsFixStyle.css'
import {
  getCorrectionDeducteesTransactions,
  getCorrectionDeducteeTransactionDetail,
  getCorrectionDeducteeTransactionSummary,
} from '../../api/deducteeTransactionsAPI';

import { getNatureOfPaymentCodes } from '../../api/challanAPI';
import { selectHeaderData } from '../header/headerSlice';
import EditDeductee from '../deducteeList/EditDeductee';
import CustomMultiSelect from '../../components/multiSelect/MultiSelect';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid';
import ImportReturnProgress from '../returnDashboard/importReturnFromExcel/ImportReturnProgress';

const PAN_STATUS = [
  { label: 'PAN Available and Verified', value: '1' },
  { label: 'PAN Available but Unverified', value: '2' },
  { label: 'PAN Invalid', value: '3' },
  { label: 'PAN Name Mis-Match', value: '4' },
];
const DEDUCTION_STATUS = [
  { label: "TDS Deducted at Higher Rate", value: "6" },
  { label: "Short Deduction", value: "7" },
];

const DeducteeTransactions = ({ history, ...props }) => {
  const { consoStatus } = props;
  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);
  let nextPageUrl = '/';
  let paramsVals = '';
  if (location.state && location.state.nextPageUrl) {
    nextPageUrl = location.state.nextPageUrl;
  }
  if (location.state && location.state.paramsVals) {
    paramsVals = location.state.paramsVals;
  }
  if (nextPageUrl !== '/') {
    let obj = {
      "nextPageUrl": nextPageUrl,
      "paramsVals": paramsVals,
    }
    localStorage.setItem('values', JSON.stringify(obj));
  }

  const [showEditForm, setShowEditForm] = useState(false);
  const [success, setSuccess] = useState(null);

  const [showNatureOfPaymentSummary, setShowNatureOfPaymentSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deducteeTransactionsList, setDeducteeTransactionsList] = useState([]);
  const [error, setError] = useState(null);
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [deducteeTrnsactnInfo, setDeducteeTrnsactnInfo] = useState({});
  const [filterPostData, setFilterPostData] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [showDedcuteeEditForm, setShowDedcuteeEditForm] = useState(false);
  const [deducteeId, setDeducteeId] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [correctionDeducteeTrnsactnInfo, setCorrectionDeducteeTrnsactnInfo] = useState({});
  const [deducteeSummary, setDeducteeSummary] = useState(null);
  const [horizontalView, setHorizontalView] = useState(false);
  const [prevDataState, setPrevDataState] = useState({});


  const PAN_LIST = [
    { label: 'PAN APPLIED', value: 'PANAPPLIED' },
    { label: 'PAN INVALID', value: 'PANINVALID' },
    { label: 'PAN NOT AVAILABLE', value: 'PANNOTAVBL' },
  ];


  const [paymentCodes, setPaymentCodes] = useState([]);

  const [editDeducteeTransaction, setEditDeducteeTransaction] = useState({});
  const [dataState, setDataState] = useState({});
  const [currentDeductee, setCurrentDeductee] = useState(0);


  const headerDataDetails = useSelector(selectHeaderData);

  const [selectedPanStatus, setSelectedPanStatus] = useState([]);
  const [selectedDeduction, setSelectedDeduction] = useState([]);
  const [nonAvailPan, setNonAvailPan] = useState(null);
  const [skeletonList, setSkeletonList] = useState(true);
  const [summaryColumnSkelton, setSummaryColumnSkelton] = useState(true)
  const [showProgressResult, setShowProgressResult] = useState(false)



  const getDeducteeTransactionsList = (data) => {
    async function fetchEverything() {
      async function fetchDeducteesTransactions() {
        const result = await getCorrectionDeducteesTransactions(data);

        if (result.data.cursor.current_page > 1) {
          setDeducteeTransactionsList(deducteeTransactionsList.concat(result.data.data));
        } else {
          setDeducteeTransactionsList(result.data.data);
        }
        setCursor(result.data.cursor);
      }
      try {
        await Promise.all([
          fetchDeducteesTransactions(),
        ]);
        setError(null);
      } catch (err) {
        // console.error('error: ', err);
        setError(err.message);
      } finally {
        setLoading(false);
        setShowMoreLoading(false);
        setSkeletonList(false);
        // setShowMoreLoadingImp(false);
      }
    }

    data.page && data.page > 1 ? setShowMoreLoading(true) : setLoading(true);
    fetchEverything();
  };


  const getDeducteeTransactionsSummary = async (data) => {
    delete data.limit;
    delete data.page;
    setLoading(true);
    setSummaryColumnSkelton(true)
    try {
      const result = await getCorrectionDeducteeTransactionSummary(data);
      if (result?.data) {
        setDeducteeSummary(result?.data ?? {
          received_amtp: '',
          received_amtp_color: '',
          total_num_rows: '',
          total_num_rows_color: '',
          total_tds_collected_amtp: '',
          total_tds_collected_amtp_color: ''
        });
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
      setSummaryColumnSkelton(false)
    }
  };


  const fetchPaymentCodesFunc = (params) => {
    async function fetchEverything() {
      async function fetchPaymentCodes() {
        const result = await getNatureOfPaymentCodes(params);
        const paymentCodeData = [];
        const payDataKey = {};
        result.data.forEach((paymentCode) => {
          paymentCodeData.push({
            label: `${paymentCode.code} - ${paymentCode.description}`,
            value: paymentCode.nature_of_payment_id,
            // tooltip: paymentCode.description,
          });
          payDataKey[paymentCode.code] = paymentCode;
        });
        setPaymentCodes(paymentCodeData);
      }
      try {
        await Promise.all([
          fetchPaymentCodes(),
        ]);
        setError(null);
      } catch (err) {
        // console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  useEffect(() => {
    fetchPaymentCodesFunc();
  }, [1]);

  useEffect(() => {
    const postData = getParamsHistoryUrl();

    setPrevDataState(deepClone(postData));

    getDeducteeTransactionsList(postData);

    if (
      (deducteeSummary === null ||
        JSON.stringify(prevDataState?.sort) === JSON.stringify(postData?.sort))
    ) {
      getDeducteeTransactionsSummary(postData);
    }

  }, [searchStr]);


  const getParamsHistoryUrl = () => {

    const postData = {
      deductor_id: params.deductor_id,
      return_id: params.return_id,
    };

    setSelectedPanStatus([]);
    setSelectedDeduction([]);
    setNonAvailPan(null);
    if (params.additional_filter) {
      postData.additional_filter = params.additional_filter;
      const additional_filters = params.additional_filter.split(',');
      const panStaus = [];
      const deductionStatus = [];
      if (additional_filters.length > 0) {
        additional_filters.forEach((item) => {
          if ([1, 2, 3, 4].includes(parseInt(item))) {
            panStaus.push(item);
          } else if ([6, 7].includes(parseInt(item))) {
            deductionStatus.push(item)
          } else if ([5].includes(parseInt(item))) {
            setNonAvailPan(5);
          }
        })
      }
      setSelectedPanStatus(panStaus);
      setSelectedDeduction(deductionStatus);
    }

    if (params.sort) {
      dataState.sort = JSON.parse(atob(params.sort));
      postData.sort = JSON.parse(atob(params.sort));
    }
    if (params.filters) {

      // IN getParamsHistoryUrl
      let frs = JSON.parse(atob(params.filters));
      for (let i in frs) {
        for (let j in frs[i].filters) {
          if (frs[i].filters[j].field === 'payment_date' || frs[i].filters[j].field === 'deduction_date') {
            frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
          }
        }
      }

      dataState.filter = { filters: [] };
      dataState.filter.filters = frs;
      postData.filters = frs;
    }
    if (params.page) {
      postData.page = params.page;
    }
    if (params.action) {
      postData.action = params.action.split(",");
    }

    postData.limit = params.limit ?? 15;
    setDataState(dataState);
    setFilterPostData({...postData});
    return postData;
  };

  const handleTransactionPopup = (info = false) => {
    setShowCreateForm(true);
  }

  const updatePopUpSaveAlt = () => {
    getDeducteeTransactionsList(searchStr);
    getDeducteeTransactionsSummary(searchStr);
  };


  const showNatureOfPaymentSummaryPopup = () => {
    setShowNatureOfPaymentSummary(true);
    // setLoading(true);
  };

  const updateDeducteeInfos = (deducteeTransactionInfo, view = false) => {
    setEditDeducteeTransaction(deducteeTransactionInfo);
    setHorizontalView(view);
    async function getDeducteeTrnsactionDetail() {
      async function getDetail() {
        const result = await getCorrectionDeducteeTransactionDetail(`?deductee_transaction_id=${deducteeTransactionInfo.deductee_transaction_id}&deductor_id=${params.deductor_id}&return_id=${params.return_id}`);
        setDeducteeTrnsactnInfo(result);
        setCorrectionDeducteeTrnsactnInfo(result);
        setLoading(true);
        setShowEditForm(false);;
        setTimeout(function () { setShowEditForm(true); setLoading(false); }, 1);
        setShowEditForm(true);
      }
      try {
        await Promise.all([
          getDetail(),
        ]);
      } catch (err) {
        // console.error('error: ', err);
      }
    }
    getDeducteeTrnsactionDetail();
  };

  const showSMSEmailPopup = () => {
    setLoading(false);
  };

  const onClose = (reload, msg = '') => {
    setShowDedcuteeEditForm(false)
    setShowCreateForm(false);
    setShowEditForm(false);
    if (msg) {
      setSuccess(msg);
    }

    if (reload) {
      setTimeout(() => {
        history.go();
      }, 300);
    }
  };

  const dataStateChange = (dataState) => {
    setDataState(dataState);
    let additional_filter = [];
    let action = '';
    if (params.additional_filter) {
      additional_filter = params.additional_filter.split(",");
    }
    if (params.action) {
      action = params.action;
    }
    createHistoryUrl(dataState, action, additional_filter);
  };

  const createHistoryUrl = (dataState = [], action = '', additional_filter = []) => {
    let filterStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;

    if (params.tab) {
      filterStr += `&tab=${params.tab}`;
    }

    if (params.action) {
      let all_status = params.action.split(",");
      let array_index = all_status.indexOf(action);
      if (array_index !== -1) {
        all_status.splice(array_index, 1);
      }
      else {
        all_status.push(action);
      }
      filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
    }
    else {
      filterStr += `&action=${action}`;
    }

    if (additional_filter.length > 0) {
      filterStr += `&additional_filter=${additional_filter.join(",")}`;
    }

    if (dataState.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
    }

    if (dataState.sort && dataState.sort.length > 0) {
      filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
    }



    history.push(filterStr);
  };


  const editInfo = (props) => (
    <td className="saldtddeleditsect text-center">
      {' '}
      <span
        onClick={() => { setCurrentDeductee(props.dataIndex); updateDeducteeInfos(props.dataItem) }}
      >
        <img src={editIcon} alt="" />
      </span>
    </td>
  )

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };


  const challanCells = (dataItem) => (
    <td className={statusColor(dataItem, "saldtddeleditsect text-right")}>
      {' '}
      <span>
        {dataItem.challan_no ? dataItem.challan_no : '-'}
      </span>
    </td>

  );
  const statusColor = (dataItem, className) => {
    const action = dataItem.action;
    if (action === 'ADDED') {
      className = `${className} green_color`;
    }
    else if (action === 'UPDATED') {
      className = `${className} blue_color`;
    }
    else {
      className = `${className} black_color`;
    }
    return className;
  };

  useEffect(() => {
    let action = params.action ? params.action.split(",") : [];
    setStatusFilter(action);
  }, [searchStr]);

  const changeFilter = (type, value) => {

    let selectedFilters = [];

    if (type === 'pan_status') {
      setSelectedPanStatus((prev) => {
        prev = value
        return prev
      });
      const nonPanStatus = [];
      if (nonAvailPan) {
        nonPanStatus.push(nonAvailPan);
      }
      selectedFilters = [...value, ...selectedDeduction, ...nonPanStatus];
    } else if (type === 'deduction') {
      setSelectedDeduction((prev) => {
        prev = value
        return prev
      });
      const nonPanStatus = [];
      if (nonAvailPan) {
        nonPanStatus.push(nonAvailPan);
      }
      selectedFilters = [...selectedPanStatus, ...value, ...nonPanStatus];
    } else if (type === 'non_avail') {
      setNonAvailPan((prev) => {
        prev = value
        return prev
      })
      const nonAvailPan = [];
      if (value !== null) {
        nonAvailPan.push(value)
      }
      selectedFilters = [...selectedPanStatus, ...selectedDeduction, ...nonAvailPan];
    }

    createHistoryUrl(dataState, '', selectedFilters);
  };
  useEffect(() => {
    if (headerDataDetails?.mobile_number && headerDataDetails?.tan && headerDataDetails?.financial_year_formatted && headerDataDetails?.quarter && headerDataDetails?.form_type) {
      const checkRunningImport = localStorage.getItem(createImportRegularReturnLocalStorageKey(headerDataDetails))
      if (checkRunningImport) {
        setShowProgressResult(true);
      }
    }
  }, [headerDataDetails])
  return (
    <div>
      {showProgressResult &&
        <ImportReturnProgress
          show={showProgressResult}
          handleClose={() => {
            setShowProgressResult(false);
          }}
          deductorId={params.deductor_id}
          returnId={params.return_id}
          headerData={headerDataDetails}
        />
      }
      {showNatureOfPaymentSummary ?
        <NatureOfPaymentSummaryPopup
          show={showNatureOfPaymentSummary}
          closeModal={() => {
            setShowNatureOfPaymentSummary(false);
          }}
          onHide={() => {
            setShowNatureOfPaymentSummary(false);
            showSMSEmailPopup();
          }}
          deductorId={params.deductor_id}
          returnId={params.return_id}
          headerDataDetails={headerDataDetails}
          componentImport={true}
        /> : null}

      {showCreateForm
        ? (
          <AddDeducteeTransactions
            onClose={onClose}
            deductorId={params.deductor_id}
            returnId={params.return_id}
            updatePopUpSaveAlt={updatePopUpSaveAlt}
            headerDataDetails={headerDataDetails}
          />
        )
        : null}
      {showEditForm
        ? (
          <EditDeducteeTransactions
            onClose={onClose}
            deductorId={params.deductor_id}
            returnId={params.return_id}
            editDeducteeTransaction={editDeducteeTransaction}
            deducteeTrnsactnInfo={deducteeTrnsactnInfo}
            deducteeTransactionID={editDeducteeTransaction.deductee_transaction_id}
            headerDataDetails={headerDataDetails}
            correctionDeducteeTrnsactnInfo={correctionDeducteeTrnsactnInfo}
            updateDeducteeInfos={updateDeducteeInfos}
            deducteeTransactionsList={deducteeTransactionsList}
            currentDeductee={currentDeductee}
            setCurrentDeductee={setCurrentDeductee}
            horizontalView={horizontalView}

          />
        )
        : null}


      {showDedcuteeEditForm
        ? (
          <EditDeductee
            onClose={onClose}
            deductorId={params.deductor_id}
            deducteeId={deducteeId}
            deducteeInfo={false}
            headerNotUpdate={true}
            componentImport={true}
          />
        )
        : null}



      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <div className="container-fluid w-98 mt-2">
        <div className="row">
          <div className="col-xs-0">
          </div>

          <div className="col-xl-8 col-lg-8 col-md-6 col-xs-12 text-right DTpaddright">
            <h4 className="listofDTtext">
              List of Deductee Transactions

            </h4>
            {' '}
          </div>
          <div className="col-xl-0">
            <div className="float-right attextclr btnAhref topmar">
            </div>
          </div>
          <div className="col-md-4 text-right pt-2">
            <div className="form-check-inline" onClick={() => { createHistoryUrl(dataState, 'ADDED') }}>
              <label className="form-check-label" for="check1">
                <input type="checkbox" className="form-check-input" checked={statusFilter.includes('ADDED') ? true : false} style={{ verticalAlign: 'sub' }} /> New Added
              </label>
            </div>
            <div className="form-check-inline" onClick={() => { createHistoryUrl(dataState, 'UPDATED') }}>
              <label className="form-check-label" for="check1">
                <input type="checkbox" className="form-check-input" checked={statusFilter.includes('UPDATED') ? true : false} style={{ verticalAlign: 'sub' }} /> Updated
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid w-98 mt-2">
        <div className=" row mt-2">
          <div className='col-md-12'>
            <div className="card padding_top_manage">
              <div className='row'>
                <div className='col-md-12 float-right pt-2'>
                  <div className="btn-group listofchallanboxgp">
                    <CustomMultiSelect
                      options={PAN_STATUS}
                      selected={selectedPanStatus}
                      disableSearch
                      onSelectedChanged={(selectedValue) => {
                        changeFilter('pan_status', selectedValue);
                      }}
                      valueRenderer={(selectedItems) => (selectedItems.length > 0 ? `${selectedItems.length}  selected` : 'PAN')}
                    />

                    <CustomMultiSelect
                      options={DEDUCTION_STATUS}
                      selected={selectedDeduction}
                      disableSearch
                      onSelectedChanged={(selectedValue) => {
                        changeFilter('deduction', selectedValue);
                      }}
                      valueRenderer={(selectedItems) => (selectedItems.length > 0 ? `${selectedItems.length} selected` : 'Deductions')}
                    />
                    {nonAvailPan === null
                      ? <button className="btn btn-default nonvailofpan_btnbgclr " onClick={(event) => changeFilter('non_avail', '5')}>Non-Avail. of PAN</button>
                      : <button className="btn btn-default nonvailofpan_btnbgclr active" onClick={(event) => changeFilter('non_avail', null)}>Non-Avail. of PAN</button>
                    }

                  </div>
                </div>
              </div>


              {
                skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                  :
                  <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                    <Tooltip anchorElement="pointer" position="right">
                      <Grid
                        className="table table-striped listofsalarydetailstb"
                        style={{ height: '300px' }}

                        data={deducteeTransactionsList}
                        {...dataState}
                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        filterOperators={{
                          text: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                          ],
                          numeric: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                          ],
                          date: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                          ],
                          textWithEmpty: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                          dropdown: [
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                          ],
                          pan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterContainsOperator', operator: 'in' },
                          ],
                          challan: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                        }}
                        sortable={{
                          allowUnsort: true,
                        }}
                      >
                        <Column
                          field="deductee_name"
                          headerClassName={isColumnActive('deductee_name') ? 'active' : ''}                         

                          title={<CustomDataCell
                            field="deductee_name"
                            dataState={dataState}
                            dataStateChange={dataStateChange}
                            handleClick={() => handleTransactionPopup()}
                            title='Transaction For'
                            subtextData={{ subText: deducteeSummary?.total_num_rows ?? 0, subTextColor: deducteeSummary?.total_num_rows_color ?? '' }}
                            addTooltip="Click to add new transaction"
                            showButton={true}
                            headClass={true}
                            summaryColumnSkelton={summaryColumnSkelton}
                          />}


                          width="198"
                          cell={(event) => (
                            <td title={capitalizeEachWordFirstLetter(event.dataItem.deductee_name)} className={statusColor(event.dataItem, "deductee_link")} onClick={() => { setDeducteeId(event.dataItem.deductee_id); setShowDedcuteeEditForm(true); }}>
                              {' '}
                              <span>
                                {capitalizeEachWordFirstLetter(event.dataItem.deductee_name)}
                              </span>
                            </td>
                          )}
                          columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                          sortable={false}
                        />
                        
                        <Column
                          field="pan"
                          headerClassName={isColumnActive('pan') ? 'active' : ''}
                          title="PAN"
                          width="135"
                          cell={(event) => (
                            <td className={statusColor(event.dataItem, "")} style={{ color: '#' + event.dataItem.pan_color }}>
                              {' '}
                              {event.dataItem.pan}
                            </td>
                          )}
                          columnMenu={(props) => <ColumnMenu {...props} panFilter={true} filterList={PAN_LIST} />}
                          filter="pan"
                        />
                        <Column
                          field="code"
                          headerClassName={isColumnActive('code') ? 'active' : ''}
                          title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Section'}
                          filter="dropdown"
                          width="100"
                          cell={(event) => (
                            <td className={statusColor(event.dataItem, "")}>
                              {event.dataItem.code}
                            </td>
                          )}
                          columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                        />
                        {(headerDataDetails.form_type !== "24Q") ? (
                          <Column
                            field="tds_rate_b100"
                            headerClassName={isColumnActive('tds_rate_b100') ? 'active' : ''}
                            title="Rate"
                            filter="numeric"
                            cell={(event) => (
                              <td className={statusColor(event.dataItem, "text-center")}> {parseFloat(event.dataItem.tds_rate_b100)}</td>
                            )}
                            width="75"
                            columnMenu={ColumnMenu}
                          />
                        ) : null}
                        <Column
                          field="payment_date"
                          headerClassName={isColumnActive('payment_date', dataState) ? 'active' : ''}
                          // title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Rcvd Date' : 'Payment Date'}
                          title={<CustomCell title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Rcvd Date' : 'Payment Date'} field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                          width="145"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                          filter="date"
                          cell={(event) => (
                            <td className={statusColor(event.dataItem, "text-center")}> {event.dataItem.payment_date ? moment(event.dataItem.payment_date).format('DD/MM/YYYY') : '-'}</td>
                          )}
                        />
                        <Column
                          field="deduction_date"
                          headerClassName={isColumnActive('deduction_date', dataState) ? 'active' : ''}
                          // title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Date' : 'Deduction Date'}
                          title={<CustomCell title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Date' : 'Deduction Date'} field="deduction_date" dataStateChange={dataStateChange} dataState={dataState} />}
                          filter="date"
                          width="145"
                          columnMenu={(props) => <ColumnMenu {...props} />}
                          cell={(event) => (
                            <td className={statusColor(event.dataItem, "text-center")}> {event.dataItem.deduction_date ? moment(event.dataItem.deduction_date).format('DD/MM/YYYY') : '-'}</td>
                          )}
                        />
                        <Column
                          field="paid_amtp"
                          headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                          filter="numeric"
                          cell={(event) => (
                            <td className={statusColor(event.dataItem, "text-right")} title={formatNumber(parseFloat(event.dataItem.paid_amtp).toFixed(2))}> {formatNumber(parseFloat(event.dataItem.paid_amtp).toFixed(2))}</td>
                          )}
                          width="121"
                          columnMenu={ColumnMenu}
                          title={<CustomDataCell
                            field="paid_amtp"
                            dataState={dataState}
                            dataStateChange={dataStateChange}
                            title={headerDataDetails && headerDataDetails.form_type === '27EQ' ? 'Amt Rcvd' : 'Amt Paid'}
                            subtextData={{ subText: formatNumber(deducteeSummary?.received_amtp ?? 0), subTextColor: deducteeSummary?.received_amtp_color ?? '' }}
                            showButton={false}
                            summaryColumnSkelton={summaryColumnSkelton}
                          />}
                        />

                        <Column
                          field="total_tds_amtp"
                          headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                          title={<CustomDataCell
                            field="total_tds_amtp"
                            dataState={dataState}
                            dataStateChange={dataStateChange}
                            title={headerDataDetails && headerDataDetails.form_type === '27EQ' ? 'Total Tax Collected' : 'Total Tax Deducted'}
                            subtextData={{ subText: formatNumber(deducteeSummary?.total_tds_collected_amtp ?? 0), subTextColor: deducteeSummary?.total_tds_collected_amtp_color ?? '' }}
                            showButton={false}
                            summaryColumnSkelton={summaryColumnSkelton}
                          />}
                          filter="numeric"
                          cell={(event) => (
                            <td className={statusColor(event.dataItem, "text-right")} title={formatNumber(parseFloat(event.dataItem.total_tds_amtp).toFixed(2))}> {formatNumber(parseFloat(event.dataItem.total_tds_amtp).toFixed(2))}</td>
                          )}
                          width="165"
                          columnMenu={ColumnMenu}
                        />
                        <Column
                          field={'challan_no' ? 'challan_no' : '-'}
                          headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                          title="Mapped Challan"
                          filter="challan"
                          cell={(event) => challanCells(event.dataItem)}
                          columnMenu={(props) => <ColumnMenu challanFilter={true} {...props} />}
                          width="130"
                        />
                        <Column
                          field="editIcon" sortable={false}
                          title="Action"
                          cell={editInfo}
                          // width="87"
                          width={(headerDataDetails && headerDataDetails.form_type === '24Q') ? '165' : '70'}
                        />
                      </Grid>

                    </Tooltip>
                    <div className="row">
                      <div className="col-md-12">
                        <form className="pl-3">
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <span style={{ color: '#333' }}><FontAwesomeIcon icon={faSquare} /></span>
                              <span className="pl-1">All</span>
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <span style={{ color: '#08C802' }}><FontAwesomeIcon icon={faSquare} /></span>
                              <span className="pl-1">New Added</span>
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <span style={{ color: '#0000ff' }}><FontAwesomeIcon icon={faSquare} /></span>
                              <span className="pl-1">Updated</span>
                            </label>
                          </div>
                          <div className="form-check-inline col-md-7">
                            <ShowMorePagination
                              cursor={cursor}
                              fetchData={getDeducteeTransactionsList}
                              postData={filterPostData}
                              loading={showMoreLoading}
                            />
                          </div>
                        </form>
                      </div>

                      <div className="form-check-inline" style={{ display: 'inline-block', width: '97%', textAlign: 'right' }}>
                        <label className="form-check-label">
                          <span className="pl-1 text-warning">{consoStatus && consoStatus.conso_file_status === 'IMPORT_IN_PROGRESS' ? 'IMPORT IN PROGRESS PLEASE WAIT....' : null}</span>
                        </label>
                      </div>

                    </div>
                  </div>
              }


            </div>
          </div>
        </div>

      </div>

      <div className="col-sm-3" />
      <div className="position-absolute summary">
        <a
          href="/#"
          className="mb-0 btn"
          type="button"
          data-toggle="modal"
          onClick={() => showNatureOfPaymentSummaryPopup('1')}
        >
          {(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Nature of Collection Wise Summary' : 'Nature of payment-wise summary'}
          <span className="naturoficon">
            <img className="" src={nuterrowicon} alt="" />
          </span>
        </a>
      </div>
    </div>

  );
};

export default DeducteeTransactions;
