import React from 'react'
import { formatNumber } from '../../../utils/UtilityFunctions'

const ViewFixedInput = (props) => {
    const { data, financialYear } = props;
    const show_tds_tcs_192_2b_amtp = financialYear >= 2024 ? true : false;

    return (
        <>
            <tr className="trrow_bgclr_purple">
                <th width="10">
                </th>
                <th width="550" className="text-left">
                    Net Taxable Income
                </th>
                <th width="150" className="text-right">
                    {formatNumber(data?.net_taxable_income?.total_net_taxable_income_amtp ?? 0)}
                </th>
            </tr>
            <tr className="trrow_bgclr_purple">
                <th width="10" id="accordion" className="text-left accordion">
                    <span className="salarytaxregime_edit_mastercollapse " data-toggle="collapse" href="#salarytaxregime_edit_nettaxcollap01" aria-expanded="true"></span>

                </th>
                <th width="550" className="text-left">
                    Total Tax
                </th>
                <th width="150" className="text-right">
                    {formatNumber(data?.net_taxable_income?.total_tax_computed_amtp ?? 0)}
                </th>
            </tr>
            <tr className="hide-table-padding">
                <td colspan="15" className="p-0">
                    <div id="salarytaxregime_edit_nettaxcollap01" className="in collapse show">

                        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs overflow_visible">
                            <table className="table table-borderless Salary_Tax_Regime_from_edittable mb-0">
                                <tbody>


                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="550">
                                            Income Tax
                                        </td>
                                        <td colspan120 className="text-right">
                                            {formatNumber(data?.net_taxable_income?.tax_computed?.income_tax_amtp ?? 0)}
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="">
                                            Rebate U/s 87
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.net_taxable_income?.tax_computed?.rebate_us_87_a_amtp ?? 0)}
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Surcharge
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.net_taxable_income?.tax_computed?.surcharge_amtp ?? 0)}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Health & Education Cess
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.net_taxable_income?.tax_computed?.health_edu_cess_amtp ?? 0)}
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Less:  Relief 89
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.net_taxable_income?.tax_computed?.relief_us_89_amtp ?? 0)}
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Less:  TDS deducted by Previous Employer
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.previous_employer_tds_amtp ?? 0)}
                                        </td>
                                    </tr>

                                    {show_tds_tcs_192_2b_amtp &&
                                        <tr className="">
                                            <td width="10">

                                            </td>
                                            <td width="" >
                                                Less : TCS/TDS for amount reported u/s 192(2B)
                                            </td>
                                            <td colspan="" className="text-right">
                                                {formatNumber(data?.net_taxable_income?.tds_tcs_192_2b_amtp ?? 0)}
                                            </td>
                                        </tr>
                                    }

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Total Tax
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.net_taxable_income?.total_tax_computed_amtp ?? 0)}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            Less:  TDS deducted by Current Employer
                                        </td>
                                        <td colspan="" className="text-right">
                                            {formatNumber(data?.net_taxable_income?.tds_ded_by_current_emp_amtp ?? 0)}
                                        </td>
                                    </tr>

                                    <tr className="">
                                        <td width="10">

                                        </td>
                                        <td width="" >
                                            {data?.net_taxable_income?.excess_short_tds_amtp < 0 && <span className="staxtextcoral">Shortfall</span>}
                                            {data?.net_taxable_income?.excess_short_tds_amtp >= 0 && <span className="staxtextblue">Excess</span>}
                                        </td>
                                        <td colspan="" className="text-right">
                                            {data?.net_taxable_income?.excess_short_tds_amtp < 0 && <span className="staxtextcoral">{formatNumber(Math.abs(data?.net_taxable_income?.excess_short_tds_amtp ?? 0))}</span>}
                                            {data?.net_taxable_income?.excess_short_tds_amtp >= 0 && <span className="staxtextblue">{formatNumber(Math.abs(data?.net_taxable_income?.excess_short_tds_amtp ?? 0))}</span>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewFixedInput