import React, { useState } from 'react';
import CreateDeducteeTransactionsFormV2 from '../../components/createDeducteeTransactionsForm/CreateDeducteeTransactionsFormV2';
import { deducteeTransactionsFormFields, getDeducteeTransactionsObject } from '../../components/createDeducteeTransactionsForm/DeducteeTransactionsFormHelper';
import { addDeducteeTransaction } from '../../api/deducteeTransactionsAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';


const AddDeducteeTransactionsV2 = (props) => {

  const { show, onClose, deductorId, returnId, headerDataDetails, history, formType } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  async function createDeducteeTransactions(data, closeForm = true) {
    setError(null);
    setSuccess(null);
    const deducteeTransactionsData = getDeducteeTransactionsObject(data);
    deducteeTransactionsData.return_id = returnId;
    deducteeTransactionsData.deductor_id = deductorId;
    if (headerDataDetails.form_type === "24Q") {
      deducteeTransactionsData.tds_rate_b100 = null;
    }
    async function callAPI() {
      async function AddDeducteeTransactionsProcess() {
        const result = await addDeducteeTransaction(deducteeTransactionsData);
        return result;
      }
      try {
        await AddDeducteeTransactionsProcess();
        setSuccess("Deductee transaction added successfully");
        if (closeForm) {
          setTimeout(() => {
            onClose(true, 'Deductee transaction added successfully');
            history.go();
          }, 2000);
        }
        return true;
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
        return false;
      } finally {
        setLoading(false);
      }
    }

    setLoading(true);
    return callAPI();

  }

  return (

    <div>
      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      <CreateDeducteeTransactionsFormV2
        show={show}
        onClose={() => onClose()}
        createDeducteeTransactions={createDeducteeTransactions}
        returnId={returnId}
        deductorId={deductorId}
        deducteeTransactionFormInfo={deducteeTransactionsFormFields}
        headerDataDetails={headerDataDetails}
        formType={formType}
      />

    </div>

  );
};

export default AddDeducteeTransactionsV2;
