import axios from 'axios';
import { BASE_URL} from '../app/constants';
import { handleSuccess, handleError} from './responseHandler';
import { getConfig } from './common';


export async function GetDataClause34a(data) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/clause-34a-data`;
  try {
    const reportData = await axios.post(url, data, getConfig());

    if (Number(reportData.data.status) !== 1) {
      throw (reportData.data.message);
    }

    return handleSuccess(reportData.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function GetDataClause34b(data) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/clause-34b-data`;
  try {
    const reportData = await axios.post(url, data, getConfig());

    if (Number(reportData.data.status) !== 1) {
      throw (reportData.data.message);
    }

    return handleSuccess(reportData.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function GetDataClause34c(data) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/clause-34c-data`;
  try {
    const reportData = await axios.post(url, data, getConfig());

    if (Number(reportData.data.status) !== 1) {
      throw (reportData.data.message);
    }

    return handleSuccess(reportData.data);
  } catch (err) {
    throw handleError(err);
  }
}



export async function getPanToTanDetails(pan) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/pan-to-tan?pan=${pan}`;
  try {
    const reportData = await axios.get(url, getConfig());
    if (Number(reportData.data.status) !== 1) {
      throw (reportData.data.message);
    }
    return handleSuccess(reportData.data);
  } catch (err) {
    throw handleError(err);
  }
}


// export async function getIncompleteDeductorCount(pan) {
//   const url = `${BASE_URL}/api/v1/form3cd-annexures/check-exist-incomplete-deductor?pan=${pan}`;
//   try {
//     const reportData = await axios.get(url, getConfig());
//     if (Number(reportData.data.status) !== 1) {
//       throw (reportData.data.message);
//     }
//     return handleSuccess(reportData.data);
//   } catch (err) {
//     throw handleError(err);
//   }
// }


export async function getTanWiseReturnSummary(pan, financialYear) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/return-summary?pan=${pan}&financial_year=${financialYear}`;
  try {
    const reportData = await axios.get(url, getConfig());   
    if (Number(reportData.data.status) !== 1) {
      throw (reportData.data.message);
    }
    return handleSuccess(reportData.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function exportform3cdReport(params) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/export${params}`;
  try {
    const config = { ...getConfig() };
    config.responseType = "blob";
    const result = await axios.get(url, config);
    return result;
  } catch (err) {
    throw handleError(err);
  }
}

export async function importForm3CDTXTFile(data) {
  const url = `${BASE_URL}/api/v1/form3cd-annexures/import`;
  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}




