import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { parseDate } from '@telerik/kendo-intl';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { AddDeducteeTransactionsIcon, CustomCell } from '../../components/grid/CustomCell';
import AddDeducteeTransactionsV2 from './AddDeducteeTransactionsV2';
import ConfirmPopup from '../../components/ConfirmPopup';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { formatNumber, capitalizeEachWordFirstLetter } from '../../utils/UtilityFunctions';
import {
    BASE_PATH,
    SHORT_DEDUCTIONS_26Q,
    SHORT_DEDUCTIONS_27Q,
    SHORT_DEDUCTIONS_27EQ,
    HIGHER_DEDUCTIONS_26Q,
    HIGHER_DEDUCTIONS_27Q,
    HIGHER_DEDUCTIONS_27EQ,
    LOWER_DEDUCTIONS,
    //NO_DEDUCTIONS_24Q,
    NO_DEDUCTIONS_26Q,
    NO_DEDUCTIONS_27Q,
    NO_DEDUCTIONS_27EQ,
    HEADER_FILE_NAME    
} from '../../app/constants';
import ShowAlert from '../../components/ShowAlert';
import {
    getDeducteesTransactionsV2,
    getDeducteeTransactionDetail,
    deleteDeducteeTransaction,
    //updateDeducteeTransaction,
    unLinkChallanFromDeducteeTransaction,
    getTransactionV3Summary
} from '../../api/deducteeTransactionsAPI';
import { getNatureOfPaymentCodes } from '../../api/challanAPI';
import { selectHeaderData } from '../header/headerSlice';
import CustomMultiSelect from '../../components/multiSelect/MultiSelect';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid';
import editIcon from '../../images/icons/edit.svg';
import mapIcon from '../../images/icons/map.svg';
import delIcon from '../../images/del-icon.png';
import exportReturnIcon from '../../images/exceldownload4.png';
import downloadIcon from '../../images/downloadicon_white.png';
import Pagination from '../../components/pagination/Pagination';
import CM_gif from '../../images/CM_gif.gif'
import ConflictPopup from './ConflictPopup';
import { exportRegularReturn, downloadRegularReturnErrorFile } from '../../api/returnAPI';
import { downloadExcelTemplate, downloadFilledExcelTemplate } from '../../api/deductorAPI';
import ImportReturnFromExcelV2 from '../returnDashboard/importReturnFromExcel/ImportReturnFromExcelV2';
import EditDeducteeTransactionsV2 from './EditDeducteeTransactionsV2';
import ReturnFlowTimeLine from './ReturnFlowTimeLine';
import '../../components/tdsFixStyle.css'
import './DeducteeTransactionsV2.scss';
import './ImportReturnFile.scss';
import { gocallApi } from '../../api/issueCertificateAPI';
import moment from 'moment';
import DownloadFile from '../../components/DownloadFile';
import Spinner from 'react-bootstrap/Spinner';
import LowerDeductionCertPopup from '../deducteeTransactionsAndChallan/returnHealth/LowerDeductionCertPopup';
import { getCurrentFinancialYear } from '../form3CD/Form3CDUtilityFunctions';


const TransactionWiseListing = ({ history, ...props }) => {

    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    let nextPageUrl = '/';
    let paramsVals = '';
    if (location.state && location.state.nextPageUrl) {
        nextPageUrl = location.state.nextPageUrl;
    }
    if (location.state && location.state.paramsVals) {
        paramsVals = location.state.paramsVals;
    }
    if (nextPageUrl !== '/') {
        let obj = {
            "nextPageUrl": nextPageUrl,
            "paramsVals": paramsVals,
        }
        localStorage.setItem('values', JSON.stringify(obj));
    }
    const PAN_LIST = [
        { label: 'PAN APPLIED', value: 'PANAPPLIED' },
        { label: 'PAN INVALID', value: 'PANINVALID' },
        { label: 'PAN NOT AVAILABLE', value: 'PANNOTAVBL' },
    ];
    const headerDataDetails = useSelector(selectHeaderData);
    let financialYear = getCurrentFinancialYear();
    if (headerDataDetails.financial_year_formatted) {
        const getYearIndex = headerDataDetails.financial_year_formatted.split("-");
        if (getYearIndex[0]) financialYear = parseInt(getYearIndex[0]);
    }

    const [showEditForm, setShowEditForm] = useState(false);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deducteeTransactionsList, setDeducteeTransactionsList] = useState([]);
    const [error, setError] = useState(null);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [deducteeTrnsactnInfo, setDeducteeTrnsactnInfo] = useState({});
    const [filterPostData, setFilterPostData] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const limit = 15;
    const [deducteeSummary, setDeducteeSummary] = useState(null);
    const [horizontalView, setHorizontalView] = useState(false);
    const [paymentCodes, setPaymentCodes] = useState([]);
    const [editDeducteeTransaction, setEditDeducteeTransaction] = useState({});
    const [dataState, setDataState] = useState({});
    const [currentDeductee, setCurrentDeductee] = useState(0);
    const [selectedHigherDeduction, setSelectedHigherDeduction] = useState([]);
    const [selectedLowerDeduction, setSelectedLowerDeduction] = useState([]);
    const [selectedNoDeduction, setSelectedNoDeduction] = useState([]);
    const [selectedShortDeduction, setSelectedShortDeduction] = useState([]);
    //eslint-disable-next-line
    const [shortDeduction, setShortDeduction] = useState([null]);
    const [skeletonList, setSkeletonList] = useState(true);
    // const [showProgressResult, setShowProgressResult] = useState(false)
    const [allSelected, setAllSelected] = useState(false);
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [showType, setShowType] = useState(null);
    const [showConflictPopup, setShowConflictPopup] = useState(false);
    const [importReturn, setImportReturn] = useState(false);
    const [topSummary, setTopSummary] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [downloadExcelFile, setDownloadExcelFile] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);

    const [formType, setFormType] = useState('');
    const [showOnlyIssueType, setShowOnlyIssueType] = useState(false);
    const [summaryColumnSkelton, setSummaryColumnSkelton] = useState(true);
    const [disabledTimeline, setDisabledTimeline] = useState(false);

    const [showLowerDeductionPopup, setShowLowerDeductionPopup] = useState(false);
    const [ldcSummary, setLdcSummary] = useState({})
    const [ldcListData, setLdcListData] = useState([])
    const [showSkeleton, setShowSkeleton] = useState(true);
    

    const downloadExcelTemp = async (template = 'blank') => {

        setLoading(true);
        try {
            const payload1 = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`
            const payload2 = `?form_type=${headerDataDetails.form_type}&quarter=${headerDataDetails.quarter}&return_type=REGULAR`;
            const result = await (template === 'filled' ? downloadFilledExcelTemplate(payload1) : downloadExcelTemplate(payload2));
            const resultData = template === 'filled' ? result.data : result;
            const csiData = new Blob([resultData], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerDataDetails.form_type;
            let download_file_name = `ExpressTDS_ ${formType}_Template.xlsx`;

            if (template === 'filled') {
                const headers = result?.headers;
                if (headers?.['k-file-name']) {
                    download_file_name = headers?.['k-file-name'];
                }
            }

            link.setAttribute('download', download_file_name);
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    const getSummary = async(data) => {
        try{
            if (data.summary_action === true) {
                setSummaryColumnSkelton(true);
                const resultSummary = await getTransactionV3Summary(data);                    
                setDeducteeSummary(resultSummary.data ? resultSummary.data : {
                    sum_paid_amtp: '',
                    sum_total_tds_amtp: '',
                    mapped_count: '',
                    deductee_count: '',
                    mapped: '',
                    section_count: '',
                    trans_count: '',
                    unmapped: '',
                });
            }
        }catch(err){
            console.error('error: ', err);
            setError(err.message);
        }finally{
            setSummaryColumnSkelton(false);
        }
    }

    const getDeducteeTransactionsList = (data) => {
        if(data?.additional_filter){
            //remove parent id from string
            data.additional_filter = data?.additional_filter.split(',').filter(value => value !== '36').join(',');
        }        
        
        setLoading(true);
        async function fetchEverything() {
            async function fetchDeducteesTransactions() {
                const result = await getDeducteesTransactionsV2(data);
                setDeducteeTransactionsList(result?.data?.data ?? []);   
                if((result?.status ?? 0) === 1){
                    getSummary(data);
                }             
                if (result?.data?.cursor?.next_page) result.data.cursor.next_page_url = result?.data?.cursor?.next_page;
                setCursor(result.data.cursor);
            }
            try {
                await Promise.all([
                    fetchDeducteesTransactions(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.message);
            } finally {
                setLoading(false);
                setSkeletonList(false);
            }
        }

        setLoading(true);
        fetchEverything();
    };
    
    const fetchPaymentCodesFunc = (params) => {
        async function fetchEverything() {
            async function fetchPaymentCodes() {
                const result = await getNatureOfPaymentCodes(params);
                const paymentCodeData = [];
                const payDataKey = {};
                result.data.forEach((paymentCode) => {
                    paymentCodeData.push({
                        label: `${paymentCode.code} - ${paymentCode.description}`,
                        value: paymentCode.nature_of_payment_id,
                        // tooltip: paymentCode.description,
                    });
                    payDataKey[paymentCode.code] = paymentCode;
                });
                setPaymentCodes(paymentCodeData);
            }
            try {
                await Promise.all([
                    fetchPaymentCodes(),
                ]);
                setError(null);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    useEffect(() => {
        fetchPaymentCodesFunc();
    }, []);

    useEffect(() => {
        const postData = getParamsHistoryUrl();        
        getDeducteeTransactionsList(postData);
        getLdcDataForReturn(params?.deductor_id, params.return_id)
        // eslint-disable-next-line
    }, [searchStr]);

    const getParamsHistoryUrl = () => {

        const postData = {
            deductor_id: params.deductor_id,
            return_id: params.return_id,
            sort_on: params.sort_on ? params.sort_on : '',
            sort_by: params.order_by ?? "",
            summary_action: true,
        };

        if (params?.show_type) {
            postData.show_type = params?.show_type;
        }

        if (params.additional_filter) {
            postData.additional_filter = params.additional_filter;
            const additional_filters = params.additional_filter.split(',');

            const shortDeduction = [];
            const higherDeduction = [];
            const lowerDeduction = [];
            const noDeduction = [];
            const onlyIssues = [];           

            if (additional_filters.length > 0) {
                postData.summary_action = true;
                additional_filters.forEach((item) => {
                    if ([32, 33].includes(parseInt(item))) {
                        setShowType(item);
                    } else if ([35, 36, 41, 42, 43, 44].includes(parseInt(item))) {
                        // setShortDeduction(item)
                        shortDeduction.push(item);
                        if ([35, 36, 41, 42, 43, 44].includes(parseInt(item))) {
                            onlyIssues.push(item);
                        }
                    } else if ([45, 46].includes(parseInt(item))) {
                        higherDeduction.push(item);
                    } else if ([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].includes(parseInt(item))) {
                        noDeduction.push(item);
                    }
                })
            }

            setSelectedShortDeduction(shortDeduction);
            setSelectedHigherDeduction(higherDeduction);
            setSelectedLowerDeduction(lowerDeduction);
            setSelectedNoDeduction(noDeduction);
            setShowOnlyIssueType(onlyIssues);
        }

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            postData.sort = JSON.parse(atob(params.sort));
        }

        if (postData.sort_on !== '') {
            postData.summary_action = false;
        }

        if (params.page) {            
            if(postData.page > 1){
                postData.summary_action = false;
            }            
        }

        if (params.filters) {
            postData.summary_action = true;

            // IN getParamsHistoryUrl
            let frs = JSON.parse(atob(params.filters));
            for (let i in frs) {
                for (let j in frs[i].filters) {
                    if (frs[i].filters[j].field === 'payment_date' || frs[i].filters[j].field === 'deduction_date') {
                        frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
                    }
                }
            }

            dataState.filter = { filters: [] };
            dataState.filter.filters = frs;
            postData.filters = frs;
        }

        

        if (params.page) {
            postData.page = parseInt(params.page);                     
        }
        if (params.action) {
            postData.action = params.action.split(",");
        }

        postData.limit = params.limit ?? limit;
        setDataState(dataState);
        setFilterPostData(postData);
        return postData;
    };

    const handleTransactionPopup = (info = false) => {
        setShowCreateForm(true);
    }

    const deleteDeducteeTransactionBtn = async (deductor_id, return_id) => {
        setShowConfirm(false);
        setLoading(true);
        try {
            const deletedIds = Array.from(selectedIds);
            await deleteDeducteeTransaction(deletedIds, deductor_id, return_id);
            setSelectedIds(new Set());
            setAllSelected(false);
            getDeducteeTransactionsList(filterPostData);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const updateDeducteeInfos = (deducteeTransactionInfo, view = false) => {
        setEditDeducteeTransaction(deducteeTransactionInfo);
        setHorizontalView(view);
        async function getDeducteeTrnsactionDetail() {
            async function getDetail() {
                const result = await getDeducteeTransactionDetail(`?deductee_transaction_id=${deducteeTransactionInfo.deductee_transaction_id}&deductor_id=${params.deductor_id}&return_id=${params.return_id}`);
                setDeducteeTrnsactnInfo((prev) => {
                    prev = result
                    return { ...prev }
                });
                setShowEditForm(true);
                if (result?.is_child || result.is_parent) setConfirmPopup(true);
            }
            try {
                await Promise.all([
                    getDetail(),
                ]);
            } catch (err) {
                console.error('error: ', err);
            }
        }
        getDeducteeTrnsactionDetail();
    };

    const updateDeducteeInfosFromLDCertPopup = (deductee_transaction_id) => {
        async function getDeducteeTrnsactionDetail() {
          async function getDetail() {
            const result = await getDeducteeTransactionDetail(`?deductee_transaction_id=${deductee_transaction_id}&deductor_id=${params.deductor_id}&return_id=${params.return_id}`);
            setDeducteeTrnsactnInfo((prev) => {
              prev = result
              return { ...prev }
            });
            setShowEditForm(true);
          }
          try {
            await Promise.all([
              getDetail(),
            ]);
          } catch (err) {
            console.error('error: ', err);
          }
        }
        getDeducteeTrnsactionDetail();
    };

    // console.log("window", new URL(window.location.href));
    function removeQueryParam(param) {
        // Create a URL object from the current URL
        const url = new URL(window.location.href);
        // Remove the query parameter
        url.searchParams.delete(param);
        window.location.href = url.href;   
    }

    const onClose = (reload, msg = '') => {
        setShowCreateForm(false);
        setShowEditForm(false);
        if (msg) {
            setSuccess(msg);
        }

        if (reload) {
            if (params.duetoldc) {
                setShowLowerDeductionPopup(false);
                setTimeout(() => {
                    removeQueryParam('duetoldc');
                }, 100);

            } else {
                setTimeout(() => {
                    history.go();
                }, 300);
            }

        }
    };

    const unlinkChallanDeducteeTransactionBtn = (deducteeTransactionId, deducteeId) => {
        setError(null);
        setSuccess(null);

        async function callAPI(postData) {
            async function unlinkDeducteeTransactionChallan(postData) {
                const result = await unLinkChallanFromDeducteeTransaction(postData);
                return result;
            }
            try {
                const result = await unlinkDeducteeTransactionChallan(postData);
                setSuccess(result.message);
                setTimeout(() => {
                    setSuccess(null);
                }, 3000);
                getDeducteeTransactionsList(filterPostData);
            } catch (err) {
                console.error('error: ', err);
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        if (window.confirm('Are you sure to unlink this challan?')) {
            setLoading(true);
            callAPI({
                deductee_transaction_id: [deducteeTransactionId],
                deductee_id: deducteeId,
                deductor_id: params.deductor_id,
                return_id: params.return_id,
                challan_id: null
            });
        }
    };


    const dataStateChange = (dataState) => {
        setDataState(dataState);
        let additional_filter = [];
        let action = '';
        if (params.additional_filter) {
            additional_filter = params.additional_filter.split(",");
        }
        if (params.action) {
            action = params.action;
        }
        createHistoryUrl(dataState, action, additional_filter);
    };

    const createHistoryUrl = (dataState = [], action = '', additional_filter = []) => {
        let filterStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;

        if (params.tab) {
            filterStr += `&tab=${params.tab}`;
        }

        if (params.action) {
            let all_status = params.action.split(",");
            let array_index = all_status.indexOf(action);
            if (array_index !== -1) {
                all_status.splice(array_index, 1);
            }
            else {
                all_status.push(action);
            }
            filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
        }
        // else if(params.action !== '') {
        //     filterStr += `&action=${action}`;
        // }

        if (additional_filter.length > 0) {
            filterStr += `&additional_filter=${additional_filter.join(",")}`;
        }

        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }       

        // if (dataState.sort && dataState.sort.length > 0) {
        //     filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
        // }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr = filterStr + `&sort_on=${dataState.sort[0].field}&order_by=${dataState.sort[0].dir}`;
        }
        

        history.push(filterStr);
    };

    const downloadErrorFile = async () => {
        setLoading(true);
        try {
            let payload = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`;
            const result = await downloadRegularReturnErrorFile(payload);
            const csiData = new Blob([result?.data], { type: 'application/xls' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS-ReturnErrorData.xlsx`;
            link.setAttribute('download', file_name);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            setError(err);
        }
        setLoading(false);
    };

    const editInfo = (props) => (
        <td className="saldtddeleditsect text-center pr-0">
            {
                (props.dataItem.challan_no)
                    ? <>
                        <span onClick={() => unlinkChallanDeducteeTransactionBtn(props.dataItem.deductee_transaction_id, props.dataItem.deductee_id)} style={{ cursor: 'pointer', paddingRight: '10px' }}><img src={mapIcon} alt="" /></span>
                        <span onClick={() => { setCurrentDeductee(props.dataIndex); updateDeducteeInfos(props.dataItem) }} >
                            <img src={editIcon} alt="" width={16} />
                        </span>
                    </>
                    :
                    <>
                        <span onClick={() => { setCurrentDeductee(props.dataIndex); updateDeducteeInfos(props.dataItem) }} style={{ paddingLeft: '20px' }} >
                            <img src={editIcon} alt="" width={16} />
                        </span>
                    </>
            }
        </td>
    )

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };


    const challanCells = (dataItem) => (
        <td className={statusColor(dataItem, "saldtddeleditsect text-center")}>
            {' '}
            <span>
                {dataItem.challan_no ? dataItem.challan_no : '-'}
            </span>

            {/* {(dataItem.challan_no) ? (
                <span onClick={() => unlinkChallanDeducteeTransactionBtn(dataItem.deductee_transaction_id, dataItem.deductee_id)} style={{ cursor: 'pointer', float: 'right' }}><img src={mapIcon} alt="" /></span>
            ) : null} */}

        </td>

    );
    const statusColor = (dataItem, className) => {
        return className;
    };

    const rowColour = (dataItem, type) => {
        if (type === "date" && moment(dataItem?.deduction_date, "DD/MM/YYYY").unix() > moment(dataItem?.payment_date, "DD/MM/YYYY").unix()) {
            return "red"
        } else if (type === "rate" && dataItem?.prescribed_rate !== null && Math.abs(parseFloat(dataItem?.tds_rate_b100) - parseFloat(dataItem?.prescribed_rate)) > 1e-6) {
            return "red"
        } else if (type === "tax_deducted" && dataItem?.prescribed_tax_amtp !== null && Math.abs(parseFloat(dataItem?.total_tds_amtp) - parseFloat(dataItem?.prescribed_tax_amtp)) > 1e-6) {
            return "red"
        }
    }

    const rowToolTip = (dataItem, type) => {
        if (type === "date" && moment(dataItem?.deduction_date, "DD/MM/YYYY").unix() > moment(dataItem?.payment_date, "DD/MM/YYYY").unix()) {
            return "Difference between payment date and deduction Date. It may attract the Late deduction Interest."
        } else if (type === "rate" && dataItem?.prescribed_rate !== null && Math.abs(parseFloat(dataItem?.tds_rate_b100) - parseFloat(dataItem?.prescribed_rate)) > 1e-6) {
            return "Rate is different as per prescribed rate."
        } else if (type === "tax_deducted" && dataItem?.prescribed_tax_amtp !== null && Math.abs(parseFloat(dataItem?.total_tds_amtp) - parseFloat(dataItem?.prescribed_tax_amtp)) > 1e-6) {
            return "Invalid Tax deducted amount. Enter Valid tax deducted amount."
        }
    }

    const changeFilter = (type, value) => {

        let selectedFilters = [];

        if (type === 'higher_deduction') {
            setSelectedHigherDeduction((prev) => {
                prev = value
                return prev
            });
            const shortDeductionStatus = [];
            if (shortDeduction) {
                shortDeductionStatus.push(shortDeduction);
            }
            const showTypeStatus = [];
            if (showType) {
                showTypeStatus.push(showType);
            }
            selectedFilters = [...value, ...selectedShortDeduction, ...selectedLowerDeduction, ...selectedNoDeduction, ...shortDeductionStatus, ...showTypeStatus];
        } else if (type === 'lower_deduction') {
            setSelectedLowerDeduction((prev) => {
                prev = value
                return prev
            });
            const shortDeductionStatus = [];
            if (shortDeduction) {
                shortDeductionStatus.push(shortDeduction);
            }
            const showTypeStatus = [];
            if (showType) {
                showTypeStatus.push(showType);
            }
            selectedFilters = [...value, ...selectedShortDeduction, ...selectedHigherDeduction, ...selectedNoDeduction, ...shortDeductionStatus, ...showTypeStatus];
        } else if (type === 'no_deduction') {
            setSelectedNoDeduction((prev) => {
                prev = value
                return prev
            });
            const shortDeductionStatus = [];
            if (shortDeduction) {
                shortDeductionStatus.push(shortDeduction);
            }
            const showTypeStatus = [];
            if (showType) {
                showTypeStatus.push(showType);
            }
            selectedFilters = [...value, ...selectedShortDeduction, ...selectedHigherDeduction, ...selectedLowerDeduction, ...shortDeductionStatus, ...showTypeStatus];
        } else if (type === 'short_deduction') {
            setSelectedShortDeduction((prev) => {
                prev = value
                return prev
            })
            const shortDeduction = [];
            if (value !== null) {
                shortDeduction.push(value)
            }
            const showTypeStatus = [];
            if (showType) {
                showTypeStatus.push(showType);
            }
            selectedFilters = [...selectedHigherDeduction, ...selectedLowerDeduction, ...selectedNoDeduction, ...shortDeduction, ...showTypeStatus];
        } else if (type === 'short_deduction_only_issue') {
            setSelectedShortDeduction((prev) => {
                prev = value
                return prev
            })
            const shortDeduction = [];
            if (value !== null) {
                shortDeduction.push(value)
            }
            const showTypeStatus = [];
            if (showType) {
                showTypeStatus.push(showType);
            }
            selectedFilters = [...selectedHigherDeduction, ...selectedLowerDeduction, ...selectedNoDeduction, ...shortDeduction, ...showTypeStatus];
        } else if (type === 'show_type') {
            setShowType((prev) => {
                prev = value
                return prev
            })
            const shortDeductionStatus = [];
            if (shortDeduction) {
                shortDeductionStatus.push(shortDeduction);
            }
            const showTypeStatus = [];
            if (value !== null) {
                showTypeStatus.push(value)
            }
            selectedFilters = [...selectedShortDeduction, ...selectedHigherDeduction, ...selectedLowerDeduction, ...selectedNoDeduction, ...shortDeductionStatus, ...showTypeStatus];
        }

        createHistoryUrl(dataState, '', selectedFilters);
    };

    const toggleSelected = (checked) => {
        setAllSelected(() => checked);
        const selected_ids = new Set();
        if (checked) {
            if (deducteeTransactionsList.length > 0) {
                deducteeTransactionsList.forEach((item) => {
                    selected_ids.add(item?.deductee_transaction_id);
                })
            }
        } else {
            selected_ids.clear();
        }
        setSelectedIds((prev) => {
            prev = selected_ids
            return prev
        })
    }

    const SingleSelect = (checked, id) => {
        const selected_ids = new Set(selectedIds);
        if (checked) {
            selected_ids.add(id);
        } else {
            selected_ids.delete(id);
        }
        setSelectedIds((prev) => {
            prev = selected_ids
            return prev
        })
    };


    const getTopSummary = async (deductorId, returnId) => {
        if (topSummary === null) {
            let url = `/api/v2/transaction/summary-count?deductor_id=${deductorId}&return_id=${returnId}`;
            try {
                const result = await gocallApi("get", url);
                setTopSummary(result?.data ?? {})

            } catch (err) {
                if (err.message) setError(err.message);
                else setError(err);
            }
        }
    }

    useEffect(() => {
        getTopSummary(params?.deductor_id, params?.return_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.deductor_id, params?.return_id])

    useEffect(() => {
        setFormType(headerDataDetails?.form_type);
    }, [headerDataDetails?.form_type])


    const getFormWiseDeductionFilterOptions = (formType, type) => {
        switch (formType) {
            case '26Q':
                switch (type) {
                    case 'SHORT_DEDUCTIONS': return SHORT_DEDUCTIONS_26Q;
                    case 'HIGHER_DEDUCTIONS': return HIGHER_DEDUCTIONS_26Q;
                    case 'LOWER_DEDUCTIONS': return LOWER_DEDUCTIONS;
                    case 'NO_DEDUCTIONS': return NO_DEDUCTIONS_26Q;
                    default: return SHORT_DEDUCTIONS_26Q;
                }
            case '27Q':
                switch (type) {
                    case 'SHORT_DEDUCTIONS': return SHORT_DEDUCTIONS_27Q;
                    case 'HIGHER_DEDUCTIONS': return HIGHER_DEDUCTIONS_27Q;
                    case 'LOWER_DEDUCTIONS': return LOWER_DEDUCTIONS;
                    case 'NO_DEDUCTIONS': return NO_DEDUCTIONS_27Q;
                    default: return SHORT_DEDUCTIONS_27Q;
                }
            case '27EQ':
                switch (type) {
                    case 'SHORT_DEDUCTIONS': return SHORT_DEDUCTIONS_27EQ;
                    case 'HIGHER_DEDUCTIONS': return HIGHER_DEDUCTIONS_27EQ;
                    case 'LOWER_DEDUCTIONS': return LOWER_DEDUCTIONS;
                    case 'NO_DEDUCTIONS': return NO_DEDUCTIONS_27EQ;
                    default: return SHORT_DEDUCTIONS_27EQ;
                }
            default:
                switch (type) {
                    case 'SHORT_DEDUCTIONS': return SHORT_DEDUCTIONS_26Q;
                    case 'HIGHER_DEDUCTIONS': return HIGHER_DEDUCTIONS_26Q;
                    case 'LOWER_DEDUCTIONS': return LOWER_DEDUCTIONS;
                    case 'NO_DEDUCTIONS': return NO_DEDUCTIONS_26Q;
                    default: return SHORT_DEDUCTIONS_26Q;
                }
        }
    }

    const getDeductionFilterTitle = (type) => {
        if (type === 'SHORT_DEDUCTIONS') {
            if (formType === '27EQ') return 'Short Collection';
            return 'Short Deductions'
        } else if (type === 'HIGHER_DEDUCTIONS') {
            if (formType === '27EQ') return 'Higher Collection';
            return 'Higher Deductions';
        } else if (type === 'LOWER_DEDUCTIONS') {
            if (formType === '27EQ') return 'Due to LDC';
            return 'Due to LDC';
        } else if (type === 'NO_DEDUCTIONS') {
            if (formType === '27EQ') return 'No Collection';
            return 'No Deductions';
        }
    }

    const getDeducteeLabel = (formType) => {
        switch (formType) {
            case '24Q': return 'Employee';
            case '27EQ': return 'Collectee';
            default: return 'Deductee';
        }
    }

    const showOnlyFilter = (flag) => {
        if (flag === true) {
            changeFilter('short_deduction_only_issue', [36, 35, 40, 41, 42, 43, 44]);
            setShowOnlyIssueType(true)
        } else {
            const additional_filter = params?.additional_filter ?? '';
            if (additional_filter) {
                const additional_filters = params.additional_filter.split(',');
                if (additional_filters.length > 0) {
                    const reset_additional_filters = [];
                    additional_filters.forEach((item) => {
                        if (![36, 35, 40, 41, 42, 43, 44].includes(parseInt(item))) {
                            reset_additional_filters.push(item)
                        }
                    });
                    createHistoryUrl(dataState, '', reset_additional_filters);
                    setSelectedShortDeduction((prev) => {
                        prev = reset_additional_filters
                        return prev
                    })
                    setShowOnlyIssueType(false)
                }
            }
        }
    }

    const getLdcDataForReturn = async (deductor_Id, returnId) => {

        const url = `/api/v1/report/health/ldc/list?deductor_id=${deductor_Id}&return_id=${returnId}`;
    
        try {
          const result = await gocallApi("get", url);
          const data = result?.data ?? {};
          setLdcSummary(data?.summary);
          if(data?.summary?.verified > 0 && params.duetoldc){
          setShowLowerDeductionPopup(true)
          }
          setLdcListData(data?.list ?? [])
        } catch (err) {
          if (err.message) setError(err.message);
          else setError(err);
        } finally {
          setShowSkeleton(false);
        }
      }


    return (
        <>
            {downloadExcelFile &&
                <DownloadFile
                    apiFunction={exportRegularReturn}
                    setDownloadLoading={setDownloadLoading}
                    setError={setError}
                    fileName={'ExpressTDS-ReturnData'}
                    setDownloadExcelFile={setDownloadExcelFile}
                    sessionStorageKey={`export-return-ref-key-${params?.deductor_id}-${params?.return_id}-${params?.financial_year}`}
                    params={`?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`}
                    handleSuccessProceed={() => {
                        setDownloadExcelFile(false);
                    }}
                    handleErrorProceed={(msg) => {
                        setDownloadExcelFile(false);
                    }}
                />
            }

            <ImportReturnFromExcelV2
                show={importReturn}
                handleClose={(refresh) => {
                    setImportReturn(false);
                }}
                deductorId={params?.deductor_id}
                returnId={params.return_id}
                importExelError={() => { setImportReturn(false) }}
                showImportReturnFromTxt={false}
                downloadExcelTemp={downloadExcelTemp}
                fetchTransactionOverView={() => null}
                setDisabledTimeline={setDisabledTimeline}
            />

            {/* {showProgressResult &&
                <ImportReturnProgress
                    show={showProgressResult}
                    handleClose={() => {
                        setShowProgressResult(false);
                    }}
                    deductorId={params.deductor_id}
                    returnId={params.return_id}
                    headerData={headerDataDetails}
                />
            } */}
            <ConfirmPopup
                show={showConfirm}
                handleProceed={() => deleteDeducteeTransactionBtn(params.deductor_id, params.return_id)}
                confirmText={`${Array.from(selectedIds)?.length} transactions are selected to delete. Please note that this process is irreversible. Click Delete button to continue.`}
                confirmButtonText="Delete"
                handleClose={() => { setShowConfirm(false) }}
            />
            {showCreateForm
                ? (
                    <AddDeducteeTransactionsV2
                        onClose={() => setShowCreateForm(false)}
                        deductorId={params.deductor_id}
                        returnId={params.return_id}
                        headerDataDetails={headerDataDetails}
                        show={showCreateForm}
                        history={history}
                        formType={formType}
                    />
                )
                : null}
                
            {showEditForm
                ? (
                    <EditDeducteeTransactionsV2
                        onClose={onClose}
                        deductorId={params.deductor_id}
                        returnId={params.return_id}
                        editDeducteeTransaction={editDeducteeTransaction}
                        deducteeTrnsactnInfo={deducteeTrnsactnInfo}
                        deducteeTransactionID={editDeducteeTransaction.deductee_transaction_id}
                        headerDataDetails={headerDataDetails}
                        componentImport={false}
                        correctionDeducteeTrnsactnInfo={{}}
                        updateDeducteeInfos={updateDeducteeInfos}
                        deducteeTransactionsList={deducteeTransactionsList}
                        currentDeductee={currentDeductee}
                        setCurrentDeductee={setCurrentDeductee}
                        horizontalView={horizontalView}
                        show={showEditForm}
                        setConfirmPopup={setConfirmPopup}
                        confirmPopup={confirmPopup}
                        setShowEditForm={setShowEditForm}
                        formType={formType}
                    />
                )
                : null}           

            {showConflictPopup ? (
                <ConflictPopup
                    show={showConflictPopup}
                    closeModal={() => { setShowConflictPopup(false); }}
                    onHide={() => {
                        setShowConflictPopup(false);
                    }}
                    deductorId={params.deductor_id}
                    returnId={params.return_id}
                    PAN_LIST={PAN_LIST}
                    paymentCodes={paymentCodes}
                />

            ) : null}

            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />

        {showLowerDeductionPopup &&
        <LowerDeductionCertPopup
          returnId={params.return_id}
          deductorId={params.deductor_id}
          onClose={onClose}
          ldcListData={ldcListData}
          showType= "RELOOKED"
          getLdcDataForReturn={getLdcDataForReturn}
          viewListAll={false} 
          handleEditTransaction={(id) => {
            updateDeducteeInfosFromLDCertPopup(id);
          }}
          showConsumptionTable={(certData) => {
            //showConsumptionTable(certData);
          }}
          showSkeleton={showSkeleton}
        />
      }

            <div className="container-fluid">
                <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`TransactionListing`} disabledTimeline={disabledTimeline} />
            </div>
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12" >
                        <div id="tabboxhide">
                            <span className="Deducteetrans_mappedfs">
                                Mapped Trans.
                                <span className={`Deducteetrans_mapped_textclrblue pl-1 ${showType === '32' ? 'active' : ''}`} onClick={() => changeFilter('show_type', '32')}>
                                    {topSummary?.mapped ?? 0}
                                </span>
                                <span className="px-2 vlinecolor">|</span>
                                Unmapped Trans.
                                <span className={`Deducteetrans_unmapped_textclrblue pl-1 ${showType === '33' ? 'active' : ''}`} onClick={() => changeFilter('show_type', '33')}>
                                    {topSummary?.unmapped ?? 0}
                                </span>
                            </span>
                            <span className={`Deducteetrans_showall_textclrblue pl-3 ${showType === null ? 'active' : ''}`} onClick={() => changeFilter('show_type', null)}>
                                Show All
                            </span>
                        </div>
                    </div>

                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <nav className="Deducteetrans_nav d-flex justify-content-center">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise active showbox"
                                    to={`${BASE_PATH}deductee-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    Transaction Wise  <br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.trans_count ?? 0})</span>
                                </Link>
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise Deducteetrans_defaulttab showbox"
                                    to={`${BASE_PATH}deductee-wise-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    {getDeducteeLabel(formType)} Wise <br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.deductee_count ?? 0})</span>
                                </Link>
                                <Link
                                    className="nav-item nav-link Deducteetrans_tab_dedwise Deducteetrans_defaulttab showbox"
                                    to={`${BASE_PATH}section-wise-transactions?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                    {`${formType === '27EQ' ? 'Collectee Code Wise' : 'Section Wise'}`}<br />
                                    <span className="Deducteetrans_dedwise_count_text">({topSummary?.section_count ?? 0})</span>
                                </Link>
                            </div>
                        </nav>
                    </div>
                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 text-right">
                        {!props.componentImport && (headerDataDetails.form_type === "24Q" && headerDataDetails.quarter === "Q4") && financialYear < 2024 ?
                            (
                                <Link className="dark_color_bg rounded_btn nextbtnbgclr mr-1" to={{ pathname: `${BASE_PATH}employee-salary`, search: `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`, state: { prevPageUrl: 'transaction' } }}>
                                    Add Salary Details
                                </Link>
                            ) : (null)
                        }
                        <button className="btn btn-default errorrecodsbtn mr-1" onClick={() => setDownloadExcelFile(true)} title='Export Report'>
                            <span className="pr-1">
                                {
                                    downloadLoading
                                        ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                                        : <img src={exportReturnIcon} width="16" alt="Export Return" />
                                }
                            </span>
                            Return
                        </button>

                        <button className="btn btn-default errorrecodsbtn" onClick={() => downloadErrorFile()} title='Download Error Report'>
                            <span className="pr-1">
                                <img src={downloadIcon} width="16" alt="Download Error Report" />
                            </span>
                            Error Records
                        </button>
                    </div>
                </div>
                <div className="row  mt-2 ">
                    <div className="col-md-12">
                        <div className="tab-content" id="nav-tabContent">

                            <div className="tab-pane fade show" id="Deducteetrans_dedwise_tab02" role="tabpanel" aria-labelledby="nav-home-tab" style={{ display: 'block' }}>

                                <div className="card p-0">

                                    <div className="row">

                                        <div className="col-md-12 pt-2">
                                            {
                                                formType !== '24Q' &&
                                                <div className="btn-group listoftransactionsboxgp pl-2 pt-2">

                                                    {/* {shortDeduction === null
                                                        ? <button style={{ width: '130px' }} className="btn btn-default nodeductions_btnbgclr" onClick={(event) => changeFilter('short_deduction', '1')}>Short Deductions</button>
                                                        : <button style={{ width: '130px' }} className="btn btn-default nodeductions_btnbgclr active" onClick={(event) => changeFilter('short_deduction', null)}>Short Deductions</button>
                                                    } */}
                                                    <CustomMultiSelect
                                                        options={getFormWiseDeductionFilterOptions(formType, 'SHORT_DEDUCTIONS')}
                                                        selected={selectedShortDeduction}
                                                        disableSearch
                                                        onSelectedChanged={(selectedValue) => {
                                                            changeFilter('short_deduction', selectedValue);
                                                        }}
                                                        valueRenderer={(selectedItems) => {
                                                            // Filter out "36" (parent_id) from the selected items and calculate the count
                                                            const count = selectedItems.includes("36") ? selectedItems.length - 1 : selectedItems.length;
                                                            return count > 0 ? `${count} selected` : getDeductionFilterTitle('SHORT_DEDUCTIONS');
                                                        }}
                                                        //valueRenderer={(selectedItems) => (selectedItems.length > 0 ? `${selectedItems.length}  selected` : getDeductionFilterTitle('SHORT_DEDUCTIONS'))}
                                                    />
                                                    <CustomMultiSelect
                                                        options={getFormWiseDeductionFilterOptions(formType, 'HIGHER_DEDUCTIONS')}
                                                        selected={selectedHigherDeduction}
                                                        disableSearch
                                                        onSelectedChanged={(selectedValue) => {
                                                            changeFilter('higher_deduction', selectedValue);
                                                        }}
                                                        valueRenderer={(selectedItems) => (selectedItems.length > 0 ? `${selectedItems.length}  selected` : getDeductionFilterTitle('HIGHER_DEDUCTIONS'))}
                                                    />

                                                    <button className={`multi-select newButtonClass ${ (ldcSummary?.verified ?? 0) === 0 ? 'disabled': ''} ${showLowerDeductionPopup ? 'active' : ''}`} 
                                                        onClick={() => ldcSummary?.verified > 0 ? setShowLowerDeductionPopup(true) : setShowLowerDeductionPopup(false)}>                                                       
                                                        {getDeductionFilterTitle('LOWER_DEDUCTIONS')}
                                                    </button> 
                                                                                                        
                                                    <CustomMultiSelect
                                                        options={getFormWiseDeductionFilterOptions(formType, 'NO_DEDUCTIONS')}
                                                        selected={selectedNoDeduction}
                                                        disableSearch
                                                        onSelectedChanged={(selectedValue) => {
                                                            changeFilter('no_deduction', selectedValue);
                                                        }}
                                                        valueRenderer={(selectedItems) => (selectedItems.length > 0 ? `${selectedItems.length}  selected` : getDeductionFilterTitle('NO_DEDUCTIONS'))}
                                                    />
                                                </div>
                                            }
                                            <div className='pt-2 mr-2' style={{ float: 'right' }}>
                                                {/* {
                                                        showOnlyIssueType 
                                                        ? <span className={`Deducteetrans_with_issue_textclrblue active`} onClick={() => showOnlyFilter(false)}>Only Show Transaction with Issues</span>
                                                        : <span className={`Deducteetrans_with_issue_textclrblue `} onClick={() => showOnlyFilter(true)}>Only Show Transaction with Issues</span>
                                                        
                                                    } */}
                                                <span className={`Deducteetrans_with_issue_textclrblue ${showOnlyIssueType ? 'active' : ''}`} onClick={() => showOnlyFilter(!showOnlyIssueType)}>Only Show Transaction with Issues</span>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            {
                                                skeletonList ? <Skeleton12ColumnGrid rowNo={7} />
                                                    :
                                                    <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                        <Tooltip anchorElement="pointer" position="top">
                                                            <Grid
                                                                className="table table-striped transactionwiselisting mb-0"
                                                                // style={{ maxHeight: '515px' }}

                                                                data={deducteeTransactionsList}
                                                                {...dataState}
                                                                onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                                filterOperators={{
                                                                    text: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                    ],
                                                                    numeric: [
                                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                                    ],
                                                                    date: [
                                                                        { text: 'grid.filterGteOperator', operator: 'gte' },
                                                                        { text: 'grid.filterLteOperator', operator: 'lte' },
                                                                        { text: 'grid.filterLtOperator', operator: 'lt' },
                                                                        { text: 'grid.filterGtOperator', operator: 'gt' },
                                                                        { text: 'grid.filterEqOperator', operator: 'eq' },
                                                                    ],
                                                                    textWithEmpty: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                    ],
                                                                    dropdown: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                                    ],
                                                                    pan: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterContainsOperator', operator: 'in' },
                                                                    ],
                                                                    challan: [
                                                                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                                        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' },
                                                                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                                                                    ],
                                                                }}
                                                                sortable={{
                                                                    allowUnsort: true,
                                                                }}
                                                            >
                                                                <Column
                                                                    width="50"
                                                                    field=""
                                                                    headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                                                    title=""
                                                                    headerCell={() => (
                                                                        <div>
                                                                            <span><input type="checkbox" checked={allSelected} onChange={(e) => toggleSelected(e.target.checked)} className="checkboxvertalaign" /> </span>
                                                                            <img alt='delete' src={delIcon} width="16" className="delcionvert"
                                                                                onClick={() => {
                                                                                    if (selectedIds.size > 0) {
                                                                                        setShowConfirm(true)
                                                                                    } else {
                                                                                        setError('Select atleast one transaction to delete!!');
                                                                                    }
                                                                                }} style={{ marginBottom: '8px', cursor: 'pointer' }} />
                                                                        </div>
                                                                    )}
                                                                    cell={(event) => (
                                                                        <td className='text-left' >
                                                                            <input type='checkbox' onChange={(e) => SingleSelect(e.target.checked, event?.dataItem?.deductee_transaction_id)} checked={selectedIds.has(event?.dataItem?.deductee_transaction_id)} value={event?.dataItem?.deductee_transaction_id} />
                                                                        </td>
                                                                    )}
                                                                />
                                                                <Column
                                                                    width="180"
                                                                    field="deductee_name"
                                                                    headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="deductee_name" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        handleClick={() => handleTransactionPopup()}
                                                                        title={`${getDeducteeLabel(formType)} Name`}
                                                                         subtextData={{ subText: deducteeSummary?.deductee_count, color: '' }}
                                                                         headClass={true}
                                                                        addTooltipPlus="Click to add new transaction"
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}

                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "deductee_link text-left")}>
                                                                            <span title={capitalizeEachWordFirstLetter(event.dataItem.deductee_name)} >
                                                                                {' '}
                                                                                <Link title={capitalizeEachWordFirstLetter(event.dataItem.deductee_name)} to={`${BASE_PATH}deductee-wise-transactions?deductor_id=${params.deductor_id}&return_id=${params.return_id}&filter_deductee_id=${event.dataItem.deductee_id}${params?.action ? `&action=${params?.action}` : ''}`}>
                                                                                    {capitalizeEachWordFirstLetter(event.dataItem.deductee_name)}
                                                                                </Link>
                                                                            </span>

                                                                        </td>
                                                                    )}
                                                                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                                    sortable={false}
                                                                />
                                                                <Column
                                                                    field="pan"
                                                                    headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                                    title="PAN"
                                                                    width="120"
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-left")} >
                                                                            {' '}
                                                                            {event.dataItem.pan ? String(event.dataItem.pan).toUpperCase() : ''}
                                                                        </td>
                                                                    )}
                                                                    columnMenu={(props) => <ColumnMenu {...props} panFilter={true} filterList={PAN_LIST} />}
                                                                    filter="pan"
                                                                />
                                                                <Column
                                                                    field="code"
                                                                    headerClassName={isColumnActive('code') ? 'active' : ''}
                                                                    title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Code' : 'Section'}
                                                                    filter="dropdown"
                                                                    width="100"
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-center")}>
                                                                            {event.dataItem.display_code}
                                                                        </td>
                                                                    )}
                                                                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={paymentCodes} />}
                                                                />
                                                                {(headerDataDetails.form_type !== "24Q") ? (
                                                                    <Column
                                                                        field="tds_rate_b100"
                                                                        headerClassName={isColumnActive('tds_rate_b100') ? 'active' : ''}
                                                                        title="Rate(%)"
                                                                        filter="numeric"
                                                                        cell={(event) => (
                                                                            <td className={statusColor(event.dataItem, "text-center")} style={{ color: `${rowColour(event.dataItem, "rate")}` }}>
                                                                                <span title={rowToolTip(event.dataItem, "rate")}>
                                                                                    {event.dataItem.tds_rate_b100 ? formatNumber(event.dataItem.tds_rate_b100) : ''}
                                                                                </span>
                                                                            </td>
                                                                        )}
                                                                        width="100"
                                                                        columnMenu={ColumnMenu}
                                                                    />
                                                                ) : null}
                                                                <Column
                                                                    field="payment_date"
                                                                    headerClassName={isColumnActive('payment_date', dataState) ? 'active' : ''}
                                                                    title={<CustomCell title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Amt Rcvd Dt' : 'Payment Dt'} field="payment_date" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    width="100"
                                                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                                                    filter="date"
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-center")} style={{ color: `${rowColour(event.dataItem, "date")}` }}>
                                                                            <span title={rowToolTip(event.dataItem, "date")} tooltipClass="my-tooltip-with-linebreak">
                                                                                {event.dataItem.payment_date ?? '-'}
                                                                            </span>
                                                                        </td>
                                                                    )}
                                                                />
                                                                <Column
                                                                    field="deduction_date"
                                                                    headerClassName={isColumnActive('deduction_date', dataState) ? 'active' : ''}
                                                                    title={<CustomCell title={(headerDataDetails && headerDataDetails.form_type === '27EQ') ? 'Collection Dt' : 'Deduction Dt'} field="deduction_date" dataStateChange={dataStateChange} dataState={dataState} />}
                                                                    filter="date"
                                                                    width="100"
                                                                    columnMenu={(props) => <ColumnMenu {...props} />}
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-center")} style={{ color: `${rowColour(event.dataItem, "date")}` }}>
                                                                            <span title={rowToolTip(event.dataItem, "date")}>
                                                                                {event.dataItem.deduction_date ?? '-'}
                                                                            </span>
                                                                        </td>
                                                                    )}
                                                                />
                                                                <Column
                                                                    width={"170"}
                                                                    field="paid_amtp"
                                                                    headerClassName={isColumnActive('paid_amtp') ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="paid_amtp" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title={headerDataDetails && headerDataDetails.form_type === '27EQ' ? 'Amt Rcvd' : 'Amt Paid'}
                                                                        subtextData={{ subText: deducteeSummary?.sum_paid_amtp ?? 0, color: deducteeSummary?.sum_paid_amtp_color }}
                                                                        showButton={false}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    filter="numeric"
                                                                    columnMenu={ColumnMenu}
                                                                    sortable={false}
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-right")}> {formatNumber(parseFloat(event.dataItem.paid_amtp).toFixed(2))}</td>
                                                                    )}
                                                                />
                                                                <Column
                                                                    width={"170"}
                                                                    field="total_tds_amtp"
                                                                    headerClassName={isColumnActive('total_tds_amtp') ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="total_tds_amtp" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title={headerDataDetails && headerDataDetails.form_type === '27EQ' ? 'Tax Collected' : 'Tax Deducted'}
                                                                        subtextData={{ subText: deducteeSummary?.sum_total_tds_amtp ?? 0, color: deducteeSummary?.sum_total_tds_amtp_color }}
                                                                        showButton={false}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    filter="numeric"
                                                                    columnMenu={ColumnMenu}
                                                                    sortable={false}
                                                                    cell={(event) => (
                                                                        <td className={statusColor(event.dataItem, "text-right")} style={{ color: `${rowColour(event.dataItem, "tax_deducted")}` }} >
                                                                            <span title={rowToolTip(event.dataItem, "tax_deducted")}>
                                                                                {formatNumber(parseFloat(event.dataItem.total_tds_amtp).toFixed(2))}
                                                                            </span>
                                                                        </td>
                                                                    )}
                                                                />
                                                                <Column
                                                                    //width={"145"}
                                                                    width={"170"}
                                                                    field={'challan_no' ? 'challan_no' : '-'}
                                                                    headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                                                                    title={<AddDeducteeTransactionsIcon field="challan_no" dataState={dataState}
                                                                        dataStateChange={dataStateChange}
                                                                        title='Mapped Challan'
                                                                        subtextData={{ subText: deducteeSummary?.mapped_challan ?? 0, color: deducteeSummary?.mapped_challan_color }}
                                                                        showButton={false}
                                                                        headClass={true}
                                                                        summaryColumnSkelton={summaryColumnSkelton}
                                                                    />}
                                                                    filter="challan"
                                                                    columnMenu={(props) => <ColumnMenu challanFilter={true} {...props} />}
                                                                    sortable={false}
                                                                    cell={(event) => challanCells(event.dataItem)}
                                                                />
                                                                <Column
                                                                    field="editIcon"
                                                                    headerClassName={`text-center`}
                                                                    sortable={false}
                                                                    title="Action"
                                                                    cell={editInfo}
                                                                    // width="87"
                                                                    width={(headerDataDetails && headerDataDetails.form_type === '24Q') ? '60' : '70'}
                                                                />
                                                            </Grid>

                                                        </Tooltip>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <Pagination cursor={cursor} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="challans_leftsidepopupbox" data-toggle="modal" data-target="#tdsreimport_popup">
                <div className="challans_AnnouncementsIcon" data-toggle="modal" data-target="#updatebooks2adatam">
                    <div className="challans_Announcements">
                        <div className="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
                            <div className="Dropdown-toggle Button">
                                <div className="challans_Announcements-bubble">&nbsp;</div>
                                <div>
                                    <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span
                    onClick={() => { setImportReturn(true); }}
                    className="mb-0 btn challans_textfs"
                    type="button"
                > Import Return </span>
            </div>


            <div className="return_flow_gifimgmainbox" onClick={() => setShowConflictPopup(true)}>
                <span className="bottomrightsidegifimgbox">
                    <img src={CM_gif} alt="Deducted " width="105" />
                </span>
            </div>
        </>
    );
}

export default TransactionWiseListing;