import React, { useEffect, useState } from 'react';
import { DEDUCTOR_CATEGORIES, DEDUCTOR_CATEGORIES_TYPES } from '../../app/constants';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { CustomCellList } from '../../components/grid/CustomCell';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { gocallApi } from '../../api/issueCertificateAPI';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import "../deductorList/DeductorAllList.css";

const ListDeductorsPopup = (props) => {
  const { setCurrentUnselectedIds, currentUnselectedIds } = props;
  const [deductorList, setDeductorList] = useState(null);
  const [deductorSummary, setDeductorSummary] = useState(0);
  const [dataState, setDataState] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filterPostData, setFilterPostData] = useState({});
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const limit = 15;
  const [cursor, setCursor] = useState({});
  const [allSelectedTabAll, setAllSelectedTabAll] = useState(true);
  //const [inactiveDeductorsCount, setInactiveDeductorsCount] = useState(true);  


  const rowRender = (trElement, props) => {
    let dataItem = props.dataItem ?? {};
    let city = dataItem.city ? dataItem.city?.toLowerCase() : "";
    if (dataItem?.deductor_category === "Z") dataItem.deductor_category = "";
    
    return (
      <>        
        <tr>
          <td><div>
            <input
              type="checkbox"
              checked={!currentUnselectedIds.includes(dataItem?.deductor_id)}
              onChange={(e) => singleSelect(e.target.checked, dataItem?.deductor_id)}
              value={dataItem?.deductor_id}
            /></div>
          </td>
          <td className={`text-left textoverflow overflowtooltipconttdsamt ${!dataItem?.deductor_status ? "deactive-text" : ""}`}>
            <span title={`${dataItem.deductor_name ?? ""}`} className="inactive_dark_color">
              {dataItem.deductor_name}
            </span>
          </td>
          <td className={`text-center ${!dataItem?.deductor_status ? "deactive-text" : ""}`}>{dataItem.tan ?? "-"}</td>
          <td className={`text-left textoverflow overflowtooltipconttdsamt text-capitalize ${!dataItem?.deductor_status ? "deactive-text" : ""}`} title={`${dataItem.city?.length >= 10 ? city : ""}`}>{city ?? "-"}</td>
          <td className={`text-left  textoverflow overflowtooltipconttdsamt ${!dataItem?.deductor_status ? "deactive-text" : ""}`} title={`${DEDUCTOR_CATEGORIES?.[dataItem?.deductor_category] ?? ""}`}>
            {dataItem?.deductor_category && <span>{DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? "-"}</span>}
          </td>
          <td className={`text-center ${!dataItem?.deductor_status ? "deactive-text" : ""}`}>{dataItem.mobile_no ?? "-"}</td>
          <td className={`text-left textoverflow overflowtooltipconttdsamt ${!dataItem?.deductor_status ? "deactive-text" : ""}`} title={`${dataItem.email ?? ""}`}>{dataItem.email ?? "-"}</td>
        </tr>          
      </>
    )
  }

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };


  const dataStateChange = (dataState) => {
    dataState.page = 1;
    setAllSelectedTabAll(true);
    setCurrentUnselectedIds([]);
    
    setDataState(dataState);
    setFilterPostData(dataState);
    getRecentDeductorAllList(dataState)
  };

  const getRecentDeductorAllList = async (filterData = dataState) => {
    filterData.page && filterData.page > 1 ? setShowMoreLoading(true) : setLoading(true);
    let request = {
      limit: limit,
      page: filterData?.page || 1,
    };

    if (filterData.filter) {
      request.filters = filterData?.filter?.filters;
    }

    if (filterData?.sort && filterData.sort.length > 0) {
      request.sort_on = filterData.sort[0].field;
      request.sort_by = filterData.sort[0].dir;
    }

    try {
      let url = `/api/v1/deductor/list-all`
      const result = await gocallApi("post", url, request)

      const activeDeductors = result?.data?.data ?? [];

      let new_DeductorList = [];
      if (deductorList?.length > 0) new_DeductorList = deductorList;
      if (result?.data?.cursor?.current_page > 1) {
        new_DeductorList = new_DeductorList.concat(activeDeductors ?? []);
      } else {
        new_DeductorList = activeDeductors ?? [];
      }

      setDeductorList(new_DeductorList);
      setCursor(result?.data?.cursor ?? {});
      setDeductorSummary(result?.data?.summary?.all_count ?? 0)

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setShowMoreLoading(false);
    }
  }

  const singleSelect = (checked, id) => {
    if (!checked) {
      setCurrentUnselectedIds((prevSelectedIds) => [...prevSelectedIds, id]); // Add ID if unchecked
    } else {
      setCurrentUnselectedIds((prevSelectedIds) => prevSelectedIds.filter((item) => item !== id)); // Remove ID if checked
    }
  };

  useEffect(() => {
    getRecentDeductorAllList(dataState);
    // eslint-disable-next-line
  }, []);

  const toggleSelected = (checked) => {

    setAllSelectedTabAll(checked);
    const selected_ids = new Set();

    if (!checked && deductorList.length > 0) {
      deductorList.forEach((item) => {
        selected_ids.add(item?.deductor_id);
      });
    } else {
      selected_ids.clear();
    }

    setCurrentUnselectedIds(() => [...selected_ids]); // Convert Set to Array

  };

  return (
    <>
      {loading && <LoadingIndicator />}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />



      <div className="container  mt-0" style={{ padding: '0px', margin: '0px' }}>

        <div className="form-group col-sm-12" style={{ padding: '0px', margin: '0px' }}>
          <span className='dark_color_text' style={{ fontWeight: '700' }}>Select Deductor(s), you want to assign to this User. You can do / change it later also</span>
        </div>
        <div className="col-sm-12" style={{ padding: '0px', margin: '0px' }}>
          <p
            className="sd_usermanage_zeroclients_ass_textfs pt-1"
            style={{ padding: 0, margin: 0 }}
          >
            {(() => {
              //const totalSelected = deductorSummary - inactiveDeductorsCount;
              const unselectedCount = currentUnselectedIds?.length || 0;
              const selectedCount = deductorSummary - unselectedCount;

              return selectedCount > 0 ? `${selectedCount} Deductors Selected` : null;
            })()}
          </p>
        </div>
        <div className="row borderdeductor mt-2" >
          <div className="col-md-12 deductorAllListcss px-0" >

            <div className=" fade show" id="tds_listof_deduct_tab01" role="tabpanel" aria-labelledby="nav-home-tab" >
              <div className="row" >
                <div className="col-md-12" >
                  <div onKeyDown={(e) => e.stopPropagation()} className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs text-center" >

                    <Tooltip anchorElement="pointer" position="right" >
                      <Grid
                        className="table table-striped listofdedtb scrollvisible invite-popup-table"
                        data={deductorList}
                        sortable
                        {...dataState}
                        onDataStateChange={(event) => dataStateChange(event.dataState)}
                        filterOperators={{
                          text: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                          ],
                          dropdown: [
                            { text: 'grid.filterEqOperator', operator: 'in' },
                          ],
                          numeric: [
                            { text: 'grid.filterGteOperator', operator: 'gte' },
                            { text: 'grid.filterLteOperator', operator: 'lte' },
                            { text: 'grid.filterLtOperator', operator: 'lt' },
                            { text: 'grid.filterGtOperator', operator: 'gt' },
                            { text: 'grid.filterEqOperator', operator: 'eq' },
                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                          ],
                          textWithEmpty: [
                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                            { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                          ],
                        }}
                        rowRender={rowRender}
                        style={{ maxHeight: '470px', overflowX: 'hidden' }}
                      >

                        <Column
                          width="25"
                          headerClassName={'header-check'}
                          field=""
                          headerCell={() => {
                            return (
                              <div>
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={allSelectedTabAll}
                                    onChange={(e) => toggleSelected(e.target.checked)}
                                    className="checkboxvertalaign"
                                  />
                                </span>
                              </div>
                            );
                          }}
                        />

                        <Column
                          width="230"
                          field="deductor_name"
                          title={<CustomCellList className="exp_ITC_cl_verflowtext" title="Deductor Name" field="deductor_name" dataState={dataState} dataStateChange={dataStateChange} />}
                          filter="text"
                          columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                          headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                        />

                        <Column
                          width="100"
                          field="tan"
                          title={<CustomCellList className="exp_ITC_cl_verflowtext" title="TAN" field="tan" dataState={dataState} dataStateChange={dataStateChange} />}
                          filter="text"
                          columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                          headerClassName={isColumnActive('tan') ? 'active' : ''}
                        />

                        <Column
                          width="120"
                          field="city"
                          title={<CustomCellList className="exp_ITC_cl_verflowtext" title="Location" field="city" dataState={dataState} dataStateChange={dataStateChange} />}
                          filter="text"
                          columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                          headerClassName={isColumnActive('city') ? 'active' : ''}
                        />
                        <Column
                          width="130"
                          field="deductor_category"
                          title={<CustomCellList className="exp_ITC_cl_verflowtext" title="Deductor Type" field="deductor_category" dataState={dataState} dataStateChange={dataStateChange} />}
                          filter="dropdown"
                          columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter filterList={DEDUCTOR_CATEGORIES_TYPES} {...props} />)}
                          headerClassName={isColumnActive('deductor_category') ? 'active' : ''}
                        />

                        <Column
                          width="110"
                          field="mobile_no"
                          title={<CustomCellList className="exp_ITC_cl_verflowtext" title="Mobile No." field="mobile_no" dataState={dataState} dataStateChange={dataStateChange} />}
                          filter="text"
                          columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                          headerClassName={isColumnActive('mobile_no') ? 'active' : ''}
                        />

                        <Column
                          width="180"
                          field="email"
                          title={<CustomCellList className="exp_ITC_cl_verflowtext" title="Email Address" field="email" dataState={dataState} dataStateChange={dataStateChange} />}
                          filter="text"
                          columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}

                          headerClassName={isColumnActive('email') ? 'active' : ''}
                        />


                      </Grid>
                    </Tooltip>


                  </div>
                  <div className='mb-2'>
                    <ShowMorePagination
                      cursor={cursor}
                      fetchData={getRecentDeductorAllList}
                      postData={filterPostData}
                      loading={showMoreLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div> </div>

      </div>
    </>
  )
}


export default ListDeductorsPopup;