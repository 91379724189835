/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import './SalaryDetails.css';
import SalaryDetails from './SalaryDetails';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import ConfirmPopup from '../../../../components/ConfirmPopup';
import ShowAlert from '../../../../components/ShowAlert';
import { selectHeaderData } from '../../../header/headerSlice';
import OtherIncome from './OtherIncome';
import OtherDetails from './OtherDetails';
import { manageSalaryData } from '../../../../utils/UtilityFunctions';
import { getEmployeeSalaryDetail, addEmployeeSalaryDetail, updateEmployeeSalaryDetail, getCorrectionEmployeeSalaryDetail, addCorrectionEmployeeSalaryDetail, updateCorrectionEmployeeSalaryDetail } from '../../../../api/EmployeeApi';
import { getAllowances, getOtherIncomeParticulars, getDeductionVIAParticulars, getDeducteeTotalTDSAmount } from '../../../../api/EmployeeApi';
//import { getCurrentFinancialYear } from '../../../../utils/UtilityFunctions';
import { resetInitialData, trimData, getInitializeData } from './salaryHelper';
import ViewComputation from './popup/ViewComputation';
import WindowWrapperFixed from '../../../../components/grid/WindowWrapperFixed';

const 


AddSalary = (props) => {

  const { salaryId, onHide, deductor_id, financialYear, getEmployeeData, filterPostData, setListSucess, componentImport, return_id, salaryList, setCurrentSalary, currentSalary, handleSalaryPopup, setShowCreateForm, } = props;

  const [showSalaryPopup, setShowSalaryPopup] = useState('salary_details');
  const [deducteeName, setDeducteeName] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [salaryDataBackup, setSalaryDataBackup] = useState(false);
  const [salaryData, setSalaryData] = useState(false);
  const [loading, setLoading,] = useState(false);
  const [computationData, setComputationData] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [showComputationPopup, setShowComputationPopup] = useState(false);
  const [allowanceList, setAllowanceList] = useState(false);
  const [otherIncomeParticulars, setOtherIncomeParticulars] = useState(false);
  const [deductionVIAParticulars, setDeductionVIAParticulars] = useState(false);
  const [actionStatus, setActionStatus] = useState('');
  const [salaryEditId, setSalaryEditId] = useState(salaryId);
  const headerDataDetails = useSelector(selectHeaderData);

  const getallowanceList = () => {
    async function fetchEverything() {
      async function fetchallowanceList() {
        const result = await getAllowances(financialYear);
        setAllowanceList(result.data);
      }
      try {
        await Promise.all([
          fetchallowanceList(),
        ]);
      } catch (err) {
        //setError(err.message);
      }
    }
    fetchEverything();
  };

  const getOtherIncomeParticularsList = () => {
    async function fetchEverything() {
      async function fetchOtherIncomeParticulars() {
        const result = await getOtherIncomeParticulars();
        setOtherIncomeParticulars(result.data);
      }
      try {
        await Promise.all([
          fetchOtherIncomeParticulars(),
        ]);
      } catch (err) {
      }
    }
    fetchEverything();
  };
  const getDeductionVIAParticularsList = () => {
    async function fetchEverything() {
      async function fetchDeductionVIAParticulars() {
        const result = await getDeductionVIAParticulars(financialYear, headerDataDetails?.deductor_category);
        setDeductionVIAParticulars(result.data);
      }
      try {
        await Promise.all([
          fetchDeductionVIAParticulars(),
        ]);
      } catch (err) {
      }
    }
    fetchEverything();
  };

  const getDeducteeTDSAmount = (deductor_id, deductee_id, financial_year) => {
    async function callAPI() {
      try {
        let result = await getDeducteeTotalTDSAmount(deductor_id, deductee_id, financial_year);
        return result;
      } catch (err) {
        return 0;
      }
    }
    return callAPI();
  };


  const getEmployeeDetail = (salary_id) => {
    async function fetchEverything() {
      async function fetchEmployeeSalaryDetail() {
        const result = componentImport ? await getCorrectionEmployeeSalaryDetail(salary_id, deductor_id, return_id) : await getEmployeeSalaryDetail(salary_id, deductor_id, return_id);
        let data = result.data;
        setActionStatus(result.data.action);
        let deductee_name = `${data.deductee_name} - ${data.pan}`;
        data.deductee_name = deductee_name;
        data.is_government_deductor = headerDataDetails.is_government_deductor;
        data.other_details.fund_name = data.other_details.fund_name ?? '';
        data = resetInitialData(data);
        setDeducteeName(deductee_name);
        setSalaryData(data);
      }
      try {
        await Promise.all([
          fetchEmployeeSalaryDetail(),
        ]);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };


  const addSalary = (data) => {
    async function callAPI() {
      try {
        let result = '';
        if (componentImport) {
          data.return_id = return_id;
        }

        let parse_data = trimData(data);

        if (data.salary_id) {
          result = componentImport ? await updateCorrectionEmployeeSalaryDetail(parse_data[0]) : await updateEmployeeSalaryDetail(parse_data[0]);
        }
        else {
          result = componentImport ? await addCorrectionEmployeeSalaryDetail(parse_data[0]) : await addEmployeeSalaryDetail(parse_data[0]);
        }

        clearCache();
        if (parse_data[1] === 'new') {
          setSalaryEditId(0);
          setSuccess(result.message);
          onHide(false);
          document.getElementById('create_new_salary').click();
        }
        else {
          onHide(false);
          setListSucess(result.message);
        }
        getEmployeeData(filterPostData);
        return true;

      } catch (err) {
        $('#save-salary-details, #save-new-salary-details, #save-other-income, #save-new-other-income, #save-other-details, #save-new-other-details').attr('disabled', false);
        setError(err.message);
        return false;
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    return callAPI();
  };



  const viewComputation = (data) => {
    setComputationData(data);
    setShowComputationPopup(true);
  };


  useEffect(() => {
    if (salaryEditId) {
      getEmployeeDetail(salaryEditId);
    }
    else {
      let salary_data = manageSalaryData('get', headerDataDetails.tan, financialYear);
      if (salary_data) {
        setDeducteeName(salary_data.deductee_name ? salary_data.deductee_name : null);
        setSalaryData(salary_data);
        setSalaryDataBackup(salary_data);
        setShowSalaryPopup('salary_details');
      }
      else {
        salary_data = getInitializeData(deductor_id, headerDataDetails.is_government_deductor, financialYear);
        setDeducteeName(null);
        setSalaryData(salary_data);
        setSalaryDataBackup(salary_data);
        setShowSalaryPopup('salary_details');
      }
    }

  }, [salaryEditId]);


  useEffect(() => {
    getallowanceList();
    getOtherIncomeParticularsList();
    getDeductionVIAParticularsList();

    $(window).keydown((e) => {
      if (e.keyCode === 27) {
        /* esc */
        e.preventDefault();

        if (!$(".computation_header button").length && !$(".allowance_header button").length && !$(".monthdetail_header button").length) {
          $(".grosssalaryheader button").trigger('click');
        }

        if ($(".monthdetail_header button").length) {
          $(".monthdetail_header button").trigger('click');
        }
        if ($(".allowance_header button").length) {
          $(".allowance_header button").trigger('click');
        }

        if ($(".computation_header button").length) {
          $(".computation_header button").trigger('click');
        }
      }

      if (e.altKey && !e.shiftKey && e.keyCode === 83) { /* alt+s */
        e.preventDefault();

        if ($("#save-salary-details").length) {
          $('#save-salary-details').trigger('click');
          $('#save-salary-details').attr('disabled', true);
        }

        if ($("#save-other-income").length) {
          $('#save-other-income').trigger('click');
          $('#save-other-income').attr('disabled', true);
        }

        if ($("#save-other-details").length) {
          $('#save-other-details').trigger('click');
          $('#save-other-details').attr('disabled', true);
        }

      }

      if (e.altKey && e.shiftKey && e.keyCode === 83) { /* alt+shift+s */
        e.preventDefault();

        if ($("#save-new-salary-details").length) {
          $('#save-new-salary-details').trigger('click');
          $('#save-new-salary-details').attr('disabled', true);
        }

        if ($("#save-new-other-income").length) {
          $('#save-new-other-income').trigger('click');
          $('#save-new-other-income').attr('disabled', true);
        }

        if ($("#save-new-other-details").length) {
          $('#save-new-other-details').trigger('click');
          $('#save-new-other-details').attr('disabled', true);
        }
      }

    });

  }, [1]);


  const changePopup = (popup) => {
    if (showSalaryPopup === 'salary_details' && popup !== 'salary_details') {
      document.getElementById('salary_details_submit').setAttribute('data-popup', popup);
      document.getElementById('salary_details_submit').click();
    }
    else if (showSalaryPopup === 'other_income' && popup !== 'other_income') {
      document.getElementById('other_income_submit').setAttribute('data-popup', popup);
      document.getElementById('other_income_submit').click();
    }
    else {
      setShowSalaryPopup(popup);
    }
  }


  const clearCache = () => {
    var timeout_id = window.setTimeout(function () { }, 0);
    while (timeout_id--) {
      window.clearTimeout(timeout_id);
    }
    manageSalaryData('remove', headerDataDetails.tan, salaryData.financial_year);
  }

  const checkConfirmPopup = () => {
    let salary_data = manageSalaryData('get', headerDataDetails.tan, financialYear);
    if (salaryEditId || btoa(JSON.stringify(salary_data)) === btoa(JSON.stringify(salaryDataBackup))) {
      return false;
    }
    return true;
  }

  const prevSalaryInfo = () => {

    let selectPage = 0;
    if (currentSalary > 0) {
      selectPage = currentSalary - 1;
    }
    if (currentSalary !== selectPage) {
      setCurrentSalary(selectPage);
      handleSalaryPopup(salaryList[selectPage]);
    }
    else {
      onHide();
    }

  }

  const nextSalaryInfo = () => {
    const selectPage = currentSalary + 1;
    if (currentSalary !== selectPage && selectPage < salaryList.length) {
      setCurrentSalary(selectPage);
      handleSalaryPopup(salaryList[selectPage]);
    }
    else {
      onHide();
    }
  };

  //   const HandleClose = (setFieldValue, values) => {
  //     let NewAddedDeductee = JSON.parse(localStorage.getItem('getNewAddedDeductee'));
  //     if (NewAddedDeductee) {
  //         let getDeducteeAllData = NewAddedDeductee[0];
  //         let getDeducteeData = NewAddedDeductee[1];
  //         let value = `${getDeducteeAllData.deductee_name} - ${getDeducteeAllData.pan} - ${getDeducteeData.salary_category_display_text}`;
  //         values.deductee_id = getDeducteeData.deductee_id;
  //         values.salary_category = getDeducteeData.salary_category;
  //         values.deductee_name = value;
  //         setFieldValue('deductee_id', getDeducteeData.deductee_id);
  //         setFieldValue('salary_category', getDeducteeData.salary_category);
  //         setFieldValue('deductee_name', value);
  //         setDeducteeName(value);

  //         deducteeTDSAmount(values, getDeducteeData.deductee_id, getDeducteeData.salary_category, setFieldValue);
  //     }
  //     setShowCreateForm(false);
  //     localStorage.removeItem('getNewAddedDeductee');
  //     setTimeout(function () {
  //         $('#deductee_name').focus();
  //     });
  // };

  let width1 = document.body.clientWidth;

  return (
    <>
      <WindowWrapperFixed
        title={<kendo-dialog-titlebar>
          <span className="topnexttle">
            {deducteeName ? <span className="pr-1" style={currentSalary > 0 ? { cursor: 'pointer', color: '#0000ff' } : { cursor: 'default', fontSize: '14px', fontWeight: '500' }} onClick={() => { return currentSalary > 0 ? prevSalaryInfo() : null }}>&lt; Previous</span> : <span className="pr-1"></span>}
            {<span className='col-md-10' style={{ fontSize: '1rem' }}>{deducteeName ?? 'Select Deductee'}</span>}
            {deducteeName ? <span style={currentSalary < (salaryList?.length - 1) ? { cursor: 'pointer', color: '#0000ff', paddingRight: '10px', textAlign: 'right' } : { cursor: 'default', paddingRight: '10px', fontSize: '14px', fontWeight: '500', color: '#000', textAlign: 'right' }} onClick={() => { return currentSalary < (salaryList?.length - 1) ? nextSalaryInfo() : null }} className="pl-3 col-md-12">Next &gt;</span> : <span className="pl-1"></span>}
          </span>

          <div className="headermaintitle">
            <span onClick={() => { changePopup('salary_details') }} className={showSalaryPopup === 'salary_details' ? "salarydbtnbox active " : 'salarydbtnbox'} style={{ cursor: 'pointer' }}>
              <span className="">Salary Details</span>
            </span>
            <span onClick={() => { changePopup('other_income') }} className={showSalaryPopup === 'other_income' ? "otherincombtnbox active" : 'otherincombtnbox'} style={{ cursor: 'pointer' }}>
              <span className=""> Other Income, Ded. & Tax </span>
            </span>
            <span onClick={() => { changePopup('other_details') }} className={showSalaryPopup === 'other_details' ? "otherdetbtnbox active" : 'otherdetbtnbox'} style={{ cursor: 'pointer' }}>
              <span className=""> Other Details</span>
            </span>
          </div>
        </kendo-dialog-titlebar>}
        onClose={() => checkConfirmPopup() ? setConfirmShow(true) : onHide()}
        initialWidth={700}
        initialTop={50}
        totalPopups={1}
        initialLeft={width1 - 715}
        isModalView={true}
        initialHeight={'auto'}
        className={"grosssalaryvpopup"}
      >

        <ShowAlert
          error={error}
          success={success}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
          headerStyle={{
            position: 'absolute', top: 0, left: 0, width: '100%', fontSize: '13.5px',
          }}
        />

        <ConfirmPopup
          show={confirmShow}
          handleClose={() => setConfirmShow(false)}
          handleProceed={() => {
            clearCache();
            setConfirmShow(false);
            onHide();
          }}
          handleProceed2={() => {
            setConfirmShow(false);
            onHide();
          }}
          confirmText="It looks like you have been editing something. If you leave before saving, your changes will be lost."
          confirmButtonText2={!salaryEditId ? "Save & Exit" : ""}
          confirmButtonText="Exit"
        />

        {loading ? <LoadingIndicator /> : null}



        {showSalaryPopup === 'salary_details' && salaryData &&
          <SalaryDetails
            salaryData={salaryData}
            viewComputation={viewComputation}
            computationData={computationData}
            setSalaryData={setSalaryData}
            deductor_id={deductor_id}
            componentImport={componentImport}
            return_id={return_id}
            setDeducteeName={setDeducteeName}
            addSalary={addSalary}
            showSalaryPopup={showSalaryPopup}
            setShowSalaryPopup={setShowSalaryPopup}
            allowanceList={allowanceList}
            setAllowanceList={setAllowanceList}
            getDeducteeTDSAmount={getDeducteeTDSAmount}
            headerDataDetails={headerDataDetails}
            actionStatus={actionStatus}
            setShowCreateForm={setShowCreateForm}
            deductionVIAParticulars={deductionVIAParticulars}
          />}

        {showSalaryPopup === 'other_income' && salaryData &&
          <OtherIncome
            otherIncomeData={salaryData}
            viewComputation={viewComputation}
            computationData={computationData}
            setSalaryData={setSalaryData}
            componentImport={componentImport}
            deductor_id={deductor_id}
            addSalary={addSalary}
            showSalaryPopup={showSalaryPopup}
            otherIncomeParticulars={otherIncomeParticulars}
            setOtherIncomeParticulars={setOtherIncomeParticulars}
            deductionVIAParticulars={deductionVIAParticulars}
            setDeductionVIAParticulars={setDeductionVIAParticulars}
            getDeducteeTDSAmount={getDeducteeTDSAmount}
            setShowSalaryPopup={setShowSalaryPopup}
            headerDataDetails={headerDataDetails}
            actionStatus={actionStatus}
            financialYear={financialYear}

          />}


        {showSalaryPopup === 'other_details' && salaryData &&
          <OtherDetails
            otherData={salaryData}
            viewComputation={viewComputation}
            computationData={computationData}
            componentImport={componentImport}
            setSalaryData={setSalaryData}
            deductor_id={deductor_id}
            addSalary={addSalary}
            showSalaryPopup={showSalaryPopup}
            setShowSalaryPopup={setShowSalaryPopup}
            headerDataDetails={headerDataDetails}
            actionStatus={actionStatus}
          />}



        {showComputationPopup &&
          <ViewComputation
            show={showComputationPopup}
            handleClose={() => setShowComputationPopup(!showComputationPopup)}
            computationData={computationData}
            allowanceList={allowanceList}
            otherIncomeParticulars={otherIncomeParticulars}
            deductionVIAParticulars={deductionVIAParticulars}
            headerDataDetails={headerDataDetails}
            financialYear={financialYear}
          />
        }

      </WindowWrapperFixed>

    </>
  );
};

export default AddSalary;